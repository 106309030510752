import { prop } from 'ramda';

import api from '@/api';
import { makeAsyncActionTypes, makeModuleAsync } from '@/utils';
import { FETCH } from '@/constants';
import { geoJson } from '@/config/endpoints';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncActionTypes(FETCH);

const module = {
  actions: {
    [FETCH]: ({ commit, state, rootGetters }) => {
      if (state.hasFetched) {
        return Promise.resolve();
      }

      const cities = rootGetters['cities/value'];
      const selectedUserGroup = rootGetters.selectedUserGroup;

      if (cities == null || selectedUserGroup == null) {
        return;
      }

      commit(REQUEST);

      const params = {
        names: cities.map(prop('name')),
      };

      return api(selectedUserGroup)
        .get(geoJson, { params })
        .then(body => commit(SUCCESS, body))
        .catch(error => commit(FAILURE, error));
    },
  },
};

export default makeModuleAsync({ module });
