<template>
  <div class="select mb-4 lm-1">
    <select
      @change="$emit('change',$event)"
      value:="activityFilterString">
      <option value="_all_" selected="selected">{{ $t("actionsPage.allActivities") }}</option>
      <option
        v-for="(wording,filterId) in activityList"
        :key="filterId"
        :value="filterId">
        {{ wording }}
      </option>
    </select>
  </div>
</template>


<script>
 export default {
     data () {
         return {
             activityFilterString: '_all_', 
             activityList : {
                 action_created: 'Actions added',
                 privacy_updated_action: 'Actions published',
                 measure_created: 'Measures Added',
                 privacy_updated: 'Measures published',
             },
         };
     },
 };
</script>


