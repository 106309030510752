export function config ($t) {
    return [
        {
            label: $t('Name'),
            field: 'name',
            inputType: 'input',
            value: '',
        }, {
            label: $t('Description'),
            field: 'description',
            inputType: 'textarea',
            value: '',
        }, {
            label: $t('internal_notes'),
            field: 'internal_notes',
            inputType: 'textarea',
            value: '',
        },
    ];
}