<template>
<Table
    :config="config"
    :data="logs"
    :filter="''"
    :filterBy="'name'"
    :sortByKey="'name'"
    :sorDirection="'ASC'"
    :updateSort="o=> o"
    :showHeader="false"
    :isRowClickable="() => false">
    <h2 class="table--no-content" slot="empty-table">{{ $t("All emails sent successfully") }}</h2>

    </Table>
</template>

<script>
import Table from '@/components/shared/Table';
import getConfig from '@/config/city-officials';

export default {
    components: { Table },
    props: ['logs'],
    computed: {
        config () {
            const config = getConfig(this.$t);
            config.push({
                key: 'error',
                label: 'error',
                cellDataGetter: log => log.error,
            });
            
            return config;
        },
    },
};
</script>
