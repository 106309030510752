import { compose, head, prop } from 'ramda';

import {
  ACTIONS,
  CARBON_SUMMED,
  CITIES,
  FETCH,
  GEO_JSON,
  GROUP_INFO,
  MEASURES,
  RESET,
  SELECT_USER_GROUP,
  USER_GROUPS,
  USERS,
} from '@/constants';

const modulesToReset = [
  ACTIONS,
  CARBON_SUMMED,
  CITIES,
  GEO_JSON,
  GROUP_INFO,
  MEASURES,
  USERS,
];

export default {
  selectUserGroup: ({ commit, dispatch, getters }, userGroup) => {
    const selectedUserGroup = getters.selectedUserGroup;
    const hasFetchedGroupInfo = getters['groupInfo/hasFetched'];

    if (userGroup === selectedUserGroup && hasFetchedGroupInfo) {
      return Promise.resolve();
    }

    modulesToReset.forEach(module => commit(`${module}/${RESET}`));

    commit(SELECT_USER_GROUP, userGroup);

    return dispatch(`${GROUP_INFO}/${FETCH}`);
  },

  selectDefaultUserGroup: ({ commit, dispatch, getters, rootGetters }) => {
    const hasFetchedGroupInfo = getters['groupInfo/hasFetched'];

    if (hasFetchedGroupInfo) {
      return Promise.resolve();
    }

    const userGroups = rootGetters[`${USER_GROUPS}/value`];

    const selectedUserGroup =
      getters.selectedUserGroup ||
      compose(
        prop('subdomain'),
        head,
      )(userGroups);

    commit(SELECT_USER_GROUP, selectedUserGroup);

    return dispatch(`${GROUP_INFO}/${FETCH}`);
  },
};
