<template>
  <ErrorComponent v-if="error" :error="error" />

  <div v-else>
    <TablePageContainer
      :config="config"
      :data="cities"
      :isLoading="isLoading"
      :title="title"
      :onRowClick="handleOnRowClick" />
  </div>
</template>

<script>
import { prop } from 'ramda';

import api from '@/api';
import ErrorComponent from '@/components/shared/Error';
import TablePageContainer from '@/components/shared/TablePageContainer';
import getConfig from '@/config/measure-detail';
import { getMeasureDetail } from '@/config/endpoints';
import { getMeasureName } from '@/utils/MeasureUtils';

export default {
  components: { ErrorComponent, TablePageContainer },

  data() {
    return {
      error: null,
      isLoading: true,
      cities: null,
      name: null,
    };
  },

  computed: {
    config() {
      return getConfig(this.$t, this.$i18n.locale);
    },

    title() {
      return this.$t('measureDetailsPage.title', { name: this.name });
    },
  },

  created() {
    this.fetchMeasureDetails();
  },

  methods: {
    fetchMeasureDetails() {
      const selectedUserGroup = this.$store.getters.selectedUserGroup;

      const id = this.$route.params.id;
      const measure = this.$store.getters.getMeasureById(id);

      if (!measure) {
        this.error = this.$t('errorMessages.unknownMeasure', {
          id,
          group: this.$store.getters['groupInfo/name'],
        });
        return;
      }
      
      const locale = this.$i18n.locale;
      this.name = getMeasureName(measure, locale);

      const endpoint = getMeasureDetail(id);
      const parseApiResponse = prop('measures');

      api(selectedUserGroup)
        .get(endpoint)
        .then(parseApiResponse)
        .then(cities => {
          this.isLoading = false;
          this.cities = cities;
        })
        .catch(error => {
          this.isLoading = false;
          this.error = error;
        });
    },

    handleOnRowClick({ city_name: name }) {
      this.$router.push({ name: 'cityDetail', params: { name } });
    },
  },
};
</script>

