<template>
  <FetchContainer class="box h-full" :entities="entities">
    <LatestYearBySourceByCity :selectedYear="selectedYear" />
  </FetchContainer>
</template>

<script>
import LatestYearBySourceByCity from './LatestYearBySourceByCity';
import FetchContainer from '@/components/shared/FetchContainer';

import { CITIES } from '@/constants';

export default {
  components: { LatestYearBySourceByCity, FetchContainer },

  props: ['selectedYear'],

  data() {
    return {
      entities: [CITIES],
    };
  },
};
</script>
