<template>
<div>
    <div class="field flex justify-between">
        <h1 class="title">{{ $t('Send message to your municipalities') }}</h1>
        <button class="button is-success is-rounded" @click="showSendModal">{{ $t("Preview & send") }}</button>
    </div>
    <div class="box">
        <Field>
            <label class="label" slot="label">{{ $t('Subject') }}</label>
            <input class="input" type="text" v-model="subject" />
        </Field>
        <Field>
            <label class="label" slot="label">{{ $t('Introduction') }}</label>
            <textarea class="textarea" v-model="intro" />
        </Field>
        
        <Field>
            <label class="label" slot="label">{{ $t("Measures") }}</label>
            <div class="box">
                <MeasuresTable :selection="measures" />
            </div>  
            
        </Field>

        <Field>
            <label class="label" slot="label">{{ $t("Actions") }}</label>
            <div class="box">
                <ActionsTable :selection="actions" />
            </div>  
            
        </Field>

        <Field>
            <label class="label" slot="label">{{ $t('Conclusion') }}</label>
            <textarea class="textarea" v-model="outro" />
        </Field>
    </div>

    <div class="field flex justify-end">
        <button class="button is-success is-rounded" @click="showSendModal">{{ $t("Preview & send") }}</button>
    </div>

    <SendMailModal v-if="showModal" @closeModal="showModal = false"
        :transactional_email_data="transactional_email_data"/>

</div>
</template>

<script>

import MeasuresTable from './MeasuresTable';
import ActionsTable from './ActionsTable';
import getConfig from '@/config/known-measures';
import SendMailModal from './SendMailModal';
import { GROUP_INFO } from '@/constants';

export default {
    components: { MeasuresTable, ActionsTable, SendMailModal },
    data: () => ({
        subject: '',
        intro: '',
        outro: '',
        measures: [],
        actions: [],

        showModal: false,
    }),

    computed: {
        config () {
            return getConfig(this.$t).slice(0,2);
        },

        transactional_email_data () {
            const user = this.$store.getters.getUserProfile;
            const groupName = this.$store.getters[`${GROUP_INFO}/name`];

            const intro = this.replaceNewlines(this.intro);
            const outro = this.replaceNewlines(this.outro);

            return {
                fromEmail: user.email,
                fromName: user.nickname,

                title: this.subject,

                measures: this.measures.map(m => ({
                    name: m.name,
                    url: `https://app.futureproofed.com/measures/pick_known_measure/${m.id}`,
                    img: m.photo_thumb 
                        ? `https://ddr90r1arm2wz.cloudfront.net/1/known_measures/${m.photo_thumb}`
                        : 'http://climateapp.eu/images/samples/thumbs/measure-sample.jpg',
                })),

                actions: this.actions.map(a => ({
                    name: a.name,
                    url: `https://app.futureproofed.com/actions/known_actions/${a.id}`,
                
                    img: a.photo_thumb
                        ? `https://ddr90r1arm2wz.cloudfront.net/1/known_actions/${a.photo_thumb}`
                        : 'http://climateapp.eu/images/samples/thumbs/action-sample.jpg',
                })),

                username: user.nickname,
                groupname: groupName,
                intro: intro,
                outro: outro,
            };
        },
    },

    methods: {

        replaceNewlines (text) {
            const nlTag = /(\r\n|\r|\n)/gm;

            return text.replace(nlTag, '<br />');
        },

        showSendModal () {
            const messages = [];
            if (this.subject === '') {
                messages.push(this.$t('Please fill in the subject line'));
            }
            if (this.intro === '') {
                messages.push(this.$t('Please fill in an introduction'));
            }
            if (this.measures.length === 0 && this.actions.length === 0) {
                messages.push(this.$t('Select at least one measure or action'));
            }

            if (messages.length) {
                const msg = messages.reduce( (acc, message) => {
                    acc += message + '\n';
                    return acc;
                }, '');
                alert(msg);
            } else {
                this.showModal = true;
            }
        },
    },
};
</script>
