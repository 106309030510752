export function config ($t) {
    return [
        {
            label: $t('name'),
            field: 'name',
            inputType: 'input',
            value: '',
        }, {
            label: $t('unit'),
            field: 'progress_unit',
            inputType: 'input',
            value: '',
        }, {
            label: $t('description_short'),
            field: 'description_short',
            inputType: 'textarea',
            value: '',
        }, {
            label: $t('description_long'),
            field: 'description_long',
            inputType: 'textarea',
            value: '',
        },
    ];
}

export function indicator_config ($t) {
    return [
        {
            label: $t('cobenefits_indicator_name'),
            field: 'name',
            inputType: 'input',
            value: '',
        }, {
            label: $t('cobenefits_indicator_value'),
            field: 'value',
            inputType: 'input',
            value: '',
        }, {
            label: $t('cobenefits_indicator_title'),
            field: 'title',
            inputType: 'input',
            value: '',
        },{
            label: $t('cobenefits_indicator_description'),
            field: 'description',
            inputType: 'textarea',
            value: '',
        },{
            label: $t('cobenefits_indicator_source_name'),
            field: 'source_name',
            inputType: 'input',
            value: '',
        },{
            label: $t('cobenefits_indicator_source_link'),
            field: 'source_link',
            inputType: 'input',
            value: '',
        },
    ];
}

export function example_config ($t) {
    return [
        {
            label: $t('cobenefits_example_title'),
            field: 'title',
            inputType: 'input',
            value: '',
        },{
            label: $t('cobenefits_example_description'),
            field: 'description',
            inputType: 'textarea',
            value: '',
        },{
            label: $t('cobenefits_example_source_name'),
            field: 'source_name',
            inputType: 'input',
            value: '',
        },{
            label: $t('cobenefits_example_source_link'),
            field: 'source_link',
            inputType: 'input',
            value: '',
        },
    ];
}

export function derived_unit_config ($t) {
    return [
        {
            label: $t('name'),
            field: 'name',
            inputType: 'input',
            value: '',
        },
    ];
}