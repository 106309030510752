import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueMeta from 'vue-meta';
import VueResource from 'vue-resource';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { toPairs } from 'ramda';

import App from '@/components/App';
import messages from '@/i18n';
import router from '@/router';
import store from '@/store';
import { feedbackOnRequest, disableOnPOST } from '@/api/interceptor';

import * as sharedComponents from '@/components/shared';
import AuthService from '@/utils/AuthService';
import IntercomService from '@/utils/IntercomService';
import { isProduction } from '@/utils';
import fullstory from '@/utils/fullstory';

import { SENTRY_DSN, SENTRY_DSN_DEV } from '@/config';
import { getCookie } from '@/utils/cookie';

// init sentry for error logging
Sentry.init({
  dsn: isProduction ? SENTRY_DSN : SENTRY_DSN_DEV,
  integrations: [new Integrations.Vue({
    Vue, 
    attachProps: true,
    logErrors: !isProduction,
  })],
});


fullstory.setup();

Vue.use(VueI18n);
Vue.use(VueMeta);
Vue.use(VueResource);

Vue.http.interceptors.push(feedbackOnRequest);
Vue.http.interceptors.push(disableOnPOST);

Vue.prototype.$auth = new AuthService();
Vue.prototype.$intercom = new IntercomService();

toPairs(sharedComponents).forEach(([key, component]) =>
  Vue.component(key, component),
);

const userLocale = getCookie('locale');
const i18n = new VueI18n({
  locale: userLocale || 'nl',
  messages,
});

new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App),
});
