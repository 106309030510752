<template>
<div class="mail-preview">
    <img src="http://app.futureproofed.com/images/fpc_logo.jpg" alt="FPC logo" class="logo">
    <br />
    <br />
    <br />

    <p v-html="transactional_email_data.intro" />
    <br />
    <h4 v-if="transactional_email_data.measures.length">{{$t("Measures")}}</h4>
    <Table
        v-if="transactional_email_data.measures.length"
        :config="getConfig('MEASURE')"
        :data="measures"
        :filter="''"
        :filterBy="'name'"
        :sortByKey="'name'"
        :sortDirection="'ASC'"
        :updateSort="() => {}"
        :isRowClickable="() => false"
        :showHeader="false" />
    <br />


    <h4 v-if="transactional_email_data.actions.length">{{$t("Actions")}}</h4>
    <Table
        v-if="transactional_email_data.actions.length"
        :config="getConfig('ACTION')"
        :data="actions"
        :filter="''"
        :filterBy="'name'"
        :sortByKey="'name'"
        :sortDirection="'ASC'"
        :updateSort="() => {}"
        :isRowClickable="() => false"
        :showHeader="false" />
    <br />
    
    <!-- outro -->
    <p v-html="transactional_email_data.outro" />
    <br />


    <!-- signed by -->
    <p>{{ transactional_email_data.username }}</p>
    <p>{{ transactional_email_data.groupname }}</p>
    <br />
    <br />

    <!-- social icons -->
    <span class="icon mail-icon">
        <i class="fab fa-facebook-f" />
    </span>
    <span class="icon mail-icon">
        <i class="fab fa-twitter" />
    </span>
    <span class="icon mail-icon">
        <i class="fab fa-linkedin-in" />
    </span>
    <span class="icon mail-icon">
        <i class="fas fa-globe-europe" />
    </span>
    <br />
    <br />
    
    <!-- adress -->
    <div class="adress">
        <p>Futureproofed<b>Cities</b></p>
        <p>{{$t("ADDRESS_FPC_STREET")}}</p>
        <p>{{$t("ADDRESS_PFC_CITY")}}</p>
        <p>{{$t("ADDRESS_FPC_COUNTRY")}}</p>
    </div>



</div>
</template>

<script>
import {
    MeasureImage,
    ActionImage,
} from '@/components/shared/table-cells';
import Table from '@/components/shared/Table';

export default {
    props: ['transactional_email_data'],
    components: { Table },

    computed: {
        actions () {
            return this.transactional_email_data.actions;
        },

        measures () {
            return this.transactional_email_data.measures;
        },
    },

    methods: {
        getConfig(type) {
            return [
                {
                    key: 'image',
                    label: '',
                    cellDataGetter: ({ img }) => img,
                    cellRenderer: type === 'ACTION' ? ActionImage : MeasureImage,
                }, {
                    key: 'name',
                    label: 'name',
                    cellDataGetter: ({ name }) => name,
                }, {
                    key: 'url',
                    label: 'url',
                    cellDataGetter: () => 'klik hier',
                    cellRenderer: 'a',
                },
            ];
        },
    },
};
</script>
