<template>
  <component :is="isPrivate ? 'WrapperPrivate' : 'WrapperPublic'" :url="detailPageUrl">
    <div class="card-image">
      <figure class="image is-4by3" :style="{ backgroundImage: `url(${imageSrc})` }" />
    </div>

    <div class="card-content">
      <div class="content">
        <p class="title is-5">{{ action.name }}</p>
        <p>{{ action.description }}</p>
      </div>
    </div>
    
    <div class="tags">
      <span class="tag">{{ action.city_name }}</span>
      <span class="tag" v-if="action.results_number">
        {{ `${action.results_number} ${action.results_unit}` }}
      </span>
    </div>
  </component>
</template>

<script>
import fallbackImages from '@/assets/type-fallback-images';
import {isNil} from 'ramda';

const WrapperPublic = {
  functional: true,
  render: (h, { children, props }) => (
    <a class="card public" href={props.url} target="_blank">
      {children}
    </a>
  ),
};

const WrapperPrivate = {
  functional: true,
  render: (h, { children }) => <div class="card private">{children}</div>,
};

const getActionImageSrc = ({ photo, type }) =>
  photo
    ? `https://ddr90r1arm2wz.cloudfront.net/${photo}`
    : fallbackImages[type || 'fallback'];

const getActionDetailPageUrl = ({ city_subdomain = '', id }) => isNil(city_subdomain) ? '':
  `https://${city_subdomain.toLowerCase()}.futureproofed.com/action/${id}`;

export default {
  props: ['action'],
  components: { WrapperPublic, WrapperPrivate },
  computed: {
    imageSrc() {
      return getActionImageSrc(this.action);
    },

    detailPageUrl() {
      return getActionDetailPageUrl(this.action);
    },

    isPrivate() {
      return this.action.privacy === 'private' && !isNil(this.action.city_subdomain);
    },
  },
};
</script>



