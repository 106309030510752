import { prop } from 'ramda';
import { Image } from '@/components/shared/table-cells';

export default function getConfig ($t) {
    return [
        {
            key: 'image',
            label: '',
            cellDataGetter: ({ photo_thumb }) => photo_thumb 
                ? `https://ddr90r1arm2wz.cloudfront.net/1/known_measures/${photo_thumb}`
                : 'https://app.futureproofed.com/images/samples/thumbs/measure-sample.jpg?v=2',
            cellRenderer: Image,
            width: '75px',
        },
        {
            key: 'name',
            label: $t('shared.name'),
            cellDataGetter: prop('name'),
        },
        {
            key: 'sector',
            label: $t('sector'),
            cellDataGetter: prop('sector'),
        },
    ];
}