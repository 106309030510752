<template>
  <div class="section flex flex-col flex-grow justify-center items-center">
    <Error v-if="error" :error="error" />

    <div class="notification" v-if="notification">{{ notification }}</div>

    <div class="content">
      <div class="card">
        <div class="card-content">
          <h3 class="font-normal">{{ $t("Log in with your email address") }}</h3>

          <form class="form" @submit="handleSubmit">
            <div class="field">
              <label class="label font-bold">{{ $t("Email address") }}</label>

              <div class="control">
                <input class="input" type="email" :placeholder="$t('Email address')" v-model="formData.username" required>
              </div>
            </div>

            <div class="field">
              <label class="label font-bold">{{ $t("Password") }}</label>

              <div class="control">
                <input class="input" type="password" :placeholder="$t('Password')" v-model="formData.password" required>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <button class="button is-primary w-full">{{ $t("Log in") }}</button>
              </div>
            </div>
          </form>

          <div class="field has-text-centered font-bold mt-4">{{ $t("or") }}</div>

          <div class="field">
            <div class="control">
              <button class="button google-login w-full" @click="loginWithGoogle">
                <span class="google-logo" />

                <span class="button-text">{{ $t("Log in with your Google account") }}</span>
              </button>
            </div>
          </div>

          <div class="field has-text-centered">
            <button class="button is-text" @click="handleResetPassword">{{ $t('forgotPassword') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      error: null,
      notification: null,
      formData: {
        username: '',
        password: '',
      },
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      Vue.prototype.$auth.loginWithEmail(
        this.formData,
        undefined,
        this.handleAuthResponse,
      );
    },

    loginWithGoogle() {
      Vue.prototype.$auth.loginWithGoogle(this.handleAuthResponse);
    },

    handleResetPassword() {
      Vue.prototype.$auth.resetPassword(
        this.formData.username,
        this.handleAuthResponse,
      );
    },

    handleAuthResponse(error, response) {
      this.error = error;
      this.notification = response;
    },
  },
};
</script>
