<template>
  <div id="scroll-container" class="actions-page" :style="{overflowY: 'auto'}">
    <Loading v-if="isLoading" />

    <Error v-else-if="error" :error="error" />
    
    <ActionsContainer v-else />
  </div>
</template>

<script>
import ActionsContainer from './ActionsContainer';

import { fetchUser } from '@/mixins';

export default {
  mixins: [fetchUser],

  components: { ActionsContainer },
};
</script>

