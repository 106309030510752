<template>
  <div>
    <NoDataDisclaimer />
  
    <div class="field flex justify-between flex-wrap">
      <h3 class="title">{{ $t('dashboardPage.perSectorTitle') }}</h3>


      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('dashboardPage.selectSingleYear')}}:</label>
        </div>
        <div class="field-body">
          <div class="field select">
            <select v-if="years.length > 0" v-model="selectedYear">
              <option
                v-for="year in years"
                :key="year"
                :value="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    
    <LatestYearBySourceByCity class="hide-on-mobile" :selectedYear="selectedYear" v-if="selectedYear" />  
    <LatestYearBySource :selectedYear="selectedYear" v-if="selectedYear" />
    

    <div class="field flex justify-between flex-wrap pt-8">
      <h3 class="title">{{ $t('dashboardPage.sumTitle') }}</h3>


      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('dashboardPage.selectYearFrom')}}:</label>
        </div>
        <div class="field-body">
          <div class="field select">
            <select v-if="years.length > 0" v-model="selectedFromYear">
              <option
                v-for="year in years"
                :key="year"
                :value="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="field-label is-normal pl-4">
          <label class="label">{{ $t('dashboardPage.selectYearTo')}}:</label>
        </div>
        <div class="field-body">
          <div class="field select">
            <select v-if="years.length > 0" v-model="selectedToYear">
              <option
                v-for="year in years"
                :key="year"
                :value="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <CarbonSummed :from-year="selectedFromYear" :to-year="selectedToYear" />
    <ProjectedSavings class="hide-on-mobile" :from-year="selectedFromYear" :to-year="selectedToYear" />
    
  </div>
</template>

<script>
import {
  CarbonSummed,
  LatestYearBySource,
  LatestYearBySourceByCity,
  ProjectedSavings,
} from './charts';

import NoDataDisclaimer from './NoDataDisclaimer';

export default {
  data() {
    return {
      selectedYear: new Date().getFullYear(),
      selectedFromYear: 2011,
      selectedToYear: 2030,
    };
  },
  
  components: {
    CarbonSummed,
    LatestYearBySource,
    LatestYearBySourceByCity,
    ProjectedSavings,
    NoDataDisclaimer,
  },

  computed: {
    years() {
      let years = [];

      for (var i = 2050; i >= 1990; i--) {
        years.push(i);
      }

      return years;
    },

    topTitle() {
      return 'title';
    },
  },
};
</script>
