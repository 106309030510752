<template>
  <div>
    <div class="field flex justify-between flex-wrap">
      <h3 class="title">
        <a @click="goBack" class="back-button fas fa-arrow-left" :title="$t('Back')" />
        {{ title }}
      </h3>
      <button
        v-if="mode === 'update'"
        class="button is-rounded is-success"
        @click="updateMeasure"
      >{{ $t("Update measure") }}</button>
      <button
        v-else
        class="button is-rounded is-success"
        @click="createKnownMeasure"
      >{{ $t("Create new measure") }}</button>
    </div>
    <Loading v-if="isLoading" />
    <div v-else class="box" @input="isDirty = true">
      <!-- header with photo upload and title -->
      <div class="box-header" :style="{ backgroundImage: `url(${computedImageSource})` }">
        <div class="box-header__content is-flex-tablet">
          <ValidatedInput
            :style="{ flex: '1'}"
            class="box-header__input"
            :required="true"
            :placeholder="$t('Click here to set a title')"
            v-model="name"
            @invalidate="setValid"
          />
        </div>
      </div>

      <br />

      <!-- short description -->
      <Field>
        <label class="label" slot="label">{{ $t("description_short") }}</label>
        <ValidatedTextArea
          :required="true"
          :placeholder="$t('description_short_placeholder')"
          v-model="description_short"
          @invalidate="setValid"
        />
      </Field>

      <!-- long description/background -->
      <Field>
        <label class="label" slot="label">{{ $t("description_long") }}</label>
        <ValidatedTextArea
          :required="true"
          :placeholder="$t('description_long_placeholder')"
          v-model="description_long"
          @invalidate="setValid"
        />
      </Field>

      <hr />

      <!-- sectors -->
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">{{ $t("sectors") }}</label>
        </div>
        <sector-selector v-if="selectableSectors.mitigation" :sectors="selectableSectors.mitigation" v-model="sectors"></sector-selector>
      </div>
      <div class="field is-horizontal">
        <div class="field-label">
        </div>
        <div class="field-body">
          <!-- adaptation sector -->
          <div class="field has-addons">
            <div class="control">
              <a class="button is-static">{{ $t("adaptation_sector") }}:</a>
            </div>
            <div class="control is-expanded">
              <Select
                v-model="adaptation_sector"
                :options="selectableSectors.adaptation"
                :isFullWidth="true"
                :labelGetter="translateOption"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- scope -->
      <Field>
        <label class="label" slot="label">{{ $t("scope") }}</label>
        <Select
          v-model="scope"
          :options="scopes"
          :labelGetter="(op) => op.label"
          :isFullWidth="true"
        />
      </Field>

      <!-- category -->
      <Field>
        <label class="label" slot="label">{{ $t("category") }}</label>
        <Select
          v-model="category"
          :options="categories"
          :isFullWidth="true"
          :labelGetter="translateOption"
        />
      </Field>
      <hr />

      <!-- co benefits -->
      <Field>
        <label class="label" slot="label">{{ $t('co benefits') }}</label>
        <CoBenefitsWidget v-model="co_benefits" :known_measure_id="known_measure_id" @update="loadWidgets"/>
      </Field>

      <hr />

      <!-- risks -->
      <Field>
        <label class="label" slot="label">{{ $t('risks.label') }}</label>
        <TagPikker v-model="risks" :possible_tags="adaptation_risks" translation_prefix="risks." />
      </Field>
      <hr />

      <!-- suggestions -->
      <Field>
        <label class="label" slot="label">{{ $t('suggested_to.label') }}</label>
        <TagPikker
          v-model="suggested_to"
          :possible_tags="city_classifications"
          translation_prefix="suggested_to."
        />
      </Field>
      <hr />

      <!-- Kpi unit -->
      <Field>
        <label class="label" slot="label">{{ $t("kpi_unit") }}</label>
        <input
          type="text"
          class="input"
          v-model="progress_unit"
          :placeholder="$t('kpi_unit_placeholder')"
        />
      </Field>

      <!-- Kpi target year 1 -->
      <Field>
        <label class="label" slot="label">{{ $t("kpi_target_year_1") }}</label>
        <input type="text" class="input" v-model="kpi_target_year_1" />
      </Field>

      <!-- Kpi total ref percentage -->
      <Field>
        <label class="label" slot="label">{{ $t("kpi_total_reference_percentage") }}</label>
        <input type="text" class="input" v-model="kpi_total_reference_percentage" />
      </Field>

      <!-- Kpi total reference -->
      <Field>
        <label class="label" slot="label">{{ $t("kpi_total_reference") }}</label>
        <Select
          v-model="kpi_total_reference"
          :options="kpi_total_references"
          :isFullWidth="true"
          :labelGetter="translateOption"
        />
      </Field>
      
      <hr />
      
      <Field v-if="progress_unit">
          <label class="label" slot="label">{{ $t("derived_units") }}</label>
          <DerivedUnitsWidget v-model="derived_units" 
            :derived_units_sia="derived_units_sia"
            :known_measure_id="known_measure_id"
            :progress_unit="progress_unit" @update="loadWidgets"/>
      </Field>

      <hr />

      <!-- Renewable energy production kWh -->
      <Field>
        <label class="label" slot="label">{{ $t("renewable_energy_production_kwh") }}</label>
        <input type="text" class="input" v-model="renewable_energy_production_kwh" />
      </Field>

      <!-- Kwh reduction -->
      <Field>
        <label class="label" slot="label">{{ $t("kwh_reduction") }}</label>
        <input type="text" class="input" v-model="kwh_reduction" />
      </Field>

      <!-- Emission factor fuel -->
      <Field>
        <label class="label" slot="label">{{ $t("emission_factor_fuel") }}</label>
        <input type="text" class="input" v-model="emission_factor_fuel" />
      </Field>

      <!-- CO2 reduction -->
      <Field>
        <label class="label" slot="label">{{ $t("co2_reduction") }}</label>
        <input type="number" class="input" v-model="carbon_reduction" />
        <p class="text-xs">{{$t('MEASURE_DETAIL_SIA_INFO')}}</p>
      </Field>

      <hr />

      <!-- initial investment -->
      <Field>
        <label class="label" slot="label">{{ $t("initial_investment") }}</label>
        <input type="text" class="input" v-model="initial_investment" />
        <p class="text-xs">{{$t('MEASURE_DETAIL_SIA_INFO')}}</p>
      </Field>

      <!-- investor name -->
      <Field>
        <label class="label" slot="label">{{ $t("investor_name") }}</label>
        <Select
          v-model="investor_name"
          :options="investor_saver_names"
          :isFullWidth="true"
          :labelGetter="translateOption"
        />
      </Field>

      <!-- yearly savings -->
      <Field>
        <label class="label" slot="label">{{ $t("yearly_savings") }}</label>
        <input type="text" class="input" v-model="yearly_savings" />
        <p class="text-xs">{{$t('MEASURE_DETAIL_SIA_INFO')}}</p>
      </Field>

      <!-- saver name -->
      <Field>
        <label class="label" slot="label">{{ $t("saver_name") }}</label>
        <Select
          v-model="saver_name"
          :options="investor_saver_names"
          :isFullWidth="true"
          :labelGetter="translateOption"
        />
      </Field>

      <hr />

      <!-- x factor implementation -->
      <Field>
        <label class="label" slot="label">
          <Tooltip :isWide="true" :tooltip="$t('x-factor-tooltip')">
            {{ $t("x_factor_implementation") }}
            <span class="fas fa-question-circle" />
          </Tooltip>
        </label>
        <ValidatedInput
          v-model="x_factor_implementation"
          :placeholder="$t('x-factor-placeholder')"
          :validationRules="[{
                        validate: (val) => Number(val) >=0 && Number(val) <=5,
                        message: $t('x-factor-placeholder')
                    }]"
          @invalidate="setValid"
        />
      </Field>
    </div>

    <div class="field flex justify-end">
      <button
        v-if="mode === 'update'"
        class="button is-rounded is-success"
        @click="updateMeasure"
      >{{ $t("Update measure") }}</button>
      <button
        v-else
        class="button is-rounded is-success"
        @click="createKnownMeasure"
      >{{ $t("Create new measure") }}</button>
    </div>
  </div>
</template>

<script>
import { pick } from 'ramda';
import {
  createKnownMeasure,
  addCoBenefits,
  addRisks,
  addSuggestTo,
} from '@/api/known-measure';

import { 
  fetchSectors,
} from '@/api/sectors';

import {
  scopes,
  categories,
  kpi_total_references,
  investor_saver_names,
  sectors,
  fields,
  adaptation_risks,
  city_classifications,
} from '@/config/known-measure-config';
import knownMeasureMixin from '@/mixins/known-measure';
import CoBenefitsWidget from './CoBenefitsWidget';
import TagPikker from './TagPikker';
import DerivedUnitsWidget from './DerivedUnitsWidget';
import SectorSelector from '../../shared/form/SectorSelector.vue';

export default {
  mixins: [knownMeasureMixin],
  components: { TagPikker, CoBenefitsWidget, DerivedUnitsWidget, SectorSelector },
  data() {
    return {
      sectors: null,
      selectableSectors: {},
      scopes,
      categories,
      kpi_total_references,
      investor_saver_names,
      adaptation_risks,
      city_classifications,

      isValid: true,
      isDirty: false,
    };
  },

  computed: {
    mode() {
      return this.id !== '' ? 'update' : 'new';
    },

    title() {
      return this.mode === 'update'
        ? this.$t('knownMeasuresFormPage.pageTitle--update')
        : this.$t('knownMeasuresFormPage.pageTitle--create');
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      await this.getSectors();
    },
    async getSectors() {
      try {
        const response = await fetchSectors(this.groupName);
        this.selectableSectors = sectors;
        this.selectableSectors.mitigation = [ ...response.sectors ];
      } catch (error) {
        console.log(error);
      }
    },
    createKnownMeasure() {
      const params = pick(fields, this);
      params.sectors = this.sectors && this.sectors.length !== 0 ? JSON.stringify(this.sectors) : [];
      params.sector = this.sectors && this.sectors.length !== 0 ? JSON.stringify(this.sectors) : [];
      createKnownMeasure(params, this.groupName).then((result) => {
        this.id = result.id;
        const co_benefit_ids = this.co_benefits.map((b) => b.id).join(',');
        const risk_types = this.risks.join(',');
        const city_classifications = this.suggested_to.join(',');
        Promise.all([
          addCoBenefits(this.id, co_benefit_ids, this.groupName),
          addRisks(this.id, risk_types, this.groupName),
          addSuggestTo(this.id, city_classifications, this.groupName),
        ]).then(() => {
          this.$router.push({
            name: 'knownMeasureDetail',
            params: { id: this.id },
          });
        });
      });
    },

    updateMeasure() {
      if (this.isValid) {
        const params = pick(fields, this);
        const co_benefit_ids = this.co_benefits.map((b) => b.id).join(',');
        const co_benefit_ratings = this.co_benefits.map((b) => b.rating).join(',');
        const risk_types = this.risks.join(',');
        const city_classifications = this.suggested_to.join(',');
        
        params.sectors = this.sectors && this.sectors.length !== 0 ? JSON.stringify(this.sectors) : [];
        params.sector = this.sectors && this.sectors.length !== 0 ? JSON.stringify(this.sectors) : [];
        params.co2_reduction = params.carbon_reduction; // DEPRECATED field, but maintain it just in case
        Promise.all([
          this.updateKnownMeasure(params),
          addCoBenefits(this.id, co_benefit_ids, co_benefit_ratings, this.groupName),
          addRisks(this.id, risk_types, this.groupName),
          addSuggestTo(this.id, city_classifications, this.groupName),
        ]).then(() => {
          this.$router.push({
            name: 'knownMeasureDetail',
            params: { id: this.id },
          });
        });
      }
    },

    setValid(isValid) {
      this.isValid = isValid;
    },

    translateOption(option) {
      return option.value === '' ? ' - ' : this.$t(option.value);
    },

    goBack() {
      if (this.isDirty) {
        const confirmed = window.confirm(
          this.$t(
            'Unsaved changes will be lost, are you sure you want to leave this page?',
          ),
        );
        if (confirmed) {
          this.$router.go(-1);
        }
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>