<template>
    <TablePageContainer
        :config="config"
        :data="known_actions"
        :isLoading="isLoading"
        :sortState="sortState"
        :title="title"
        :updateSort="updateSort"
        :onRowClick="handleOnRowClick"
        :filterBy="'name'"
    >

    <router-link slot="button" 
        class="button is-rounded is-success mr-4"
        to="/own-plan/known-action-form">
        {{ $t("+ Add action") }}
    </router-link>

    </TablePageContainer>
</template>

<script>
import { pick } from 'ramda';
import TablePageContainer from '@/components/shared/TablePageContainer';

import getConfig from '@/config/known-actions';
import { fetchKnownActionsList } from '@/api/known-action';
import { reverseDirection } from '@/utils';

import {
    GROUP_INFO,
} from '@/constants';


export default {
    components: { TablePageContainer },
    
    created () {
        this.load();
    },

    data: () => ({
        known_actions: [],
        isLoading: true,
        sortDirection: 'ASC',
        sortByKey: 'name',
    }),

    computed: {
        config () {
            return getConfig(this.$t, this.$i18n.locale);
        },

        sortState () {
            return pick(['sortDirection', 'sortByKey'], this);
        },

        title () {
            const groupName = this.$store.getters[`${GROUP_INFO}/name`];
            return this.$t('knownActionsPage.pageTitle', { groupName });
        },
    },

    methods: {
        load () {
            const selectedUserGroup = this.$store.getters.selectedUserGroup;
            fetchKnownActionsList(selectedUserGroup)
                .then(data => {
                    this.known_actions = data;
                    this.isLoading = false;
                });
        },
        
        updateSort (key) {
            this.sortDirection =
                this.sortByKey === key
                    ? reverseDirection(this.sortDirection)
                    : this.sortDirection;
            this.sortByKey = key;
        },

        handleOnRowClick (action) {
            this.$router.push({ name: 'knownActionDetail', params: { id: action.id } });
        },
    },
};
</script>
