<template>
<div>
    <div class="tags">
        <div class="tag is-medium cursor-pointer" v-for="{ id, name, conversion_factor } in derived_units" :key="id" @click="openEditor(id)">
            {{name}} ({{conversion_factor.toLocaleString($i18n.locale, {maximumFractionDigits: 10})}} {{progress_unit}}) 
            <i class="fa fa-link pl-2" v-if="isInSia(id)"></i>
            <button v-if="!readOnly && !isInSia(id)" class="delete" @click.stop="removeDerivedUnit(id)" />
        </div>

        <div class="tag is-medium is-success" v-if="!readOnly" @click="showModal = true">
            <i class="fa fa-plus" />
        </div>
        

        <Modal v-if="showModal" @closeModal="closeModal">
            <div class="box">
                <div class="content">
                    <h4>{{ $t('Add derived unit') + (selected_unit ?' (id: ' + selected_unit +')' : "") }}
                        <i class="fa fa-link pl-2" v-if="isInSia(selected_unit)"></i>
                    </h4>
                    <hr />
                    <FieldVertical>
                        <label class="label" slot="label">{{ $t('kpi_unit') }}</label>
                        <input type="text" class="input" v-model="name" :disabled="readOnly"/>
                    </FieldVertical>
                    <br />
                    <FieldVertical>
                        <label class="label" slot="label">{{ $t('conversion') }}</label>
                        1 {{name || '['+$t('kpi_unit').toLowerCase()+']'}} = <input type="text" class="input w-50" v-model="conversion_factor" :disabled="readOnly"/> {{progress_unit}}
                        <div v-if="isInSia(selected_unit)" class='text-sm'>
                            {{$t("UNIT_DETAIL_SIA_INFO")}}
                        </div>
                    </FieldVertical>
                    <br />
                    <button class="button is-success is-rounded" :disabled="!name" @click="selected_unit ? editDerivedUnit() : addDerivedUnit()">{{ $t('Save') }}</button>
                </div>
            </div>
        </Modal>
    </div>
</div>
</template>

<script>
import { 
    addDerivedUnit,
    editDerivedUnit,
    removeDerivedUnit,
} from '@/api/known-measure';
import Rating from '@/components/pages/KnownMeasureForm/Rating';
import IndicatorForm from '@/components/pages/KnownMeasureForm/IndicatorForm';
import Collapsable from '@/components/shared/Collapsable';
import ExampleForm from '@/components/pages/KnownMeasureForm/ExampleForm';

export default {
    components: {ExampleForm, IndicatorForm, Rating, Collapsable},
    props: ['value', 'readOnly', 'known_measure_id', 'progress_unit', 'derived_units_sia'],

    data: () => ({
        derived_units: [],
        selected_unit: null,
        isLoading: true,
        showModal: false,
        name: '',
        conversion_factor: 1,
    }),

    computed: {
        groupName () {
            return this.$store.getters.selectedUserGroup;
        },
    },

    mounted() {
        this.derived_units = this.value;
    },

    watch: {
        value(){
            this.derived_units = this.value;
        },
    },

    methods: {
        async removeDerivedUnit (unit_id) {
            this.derived_units = this.derived_units.filter(u => u.id != unit_id);
            await removeDerivedUnit(this.known_measure_id, unit_id, this.groupName);
            this.update();
        },

        async addDerivedUnit() {
            addDerivedUnit(this.known_measure_id, this.name, this.conversion_factor, this.groupName);
            this.update();
        },

        async editDerivedUnit() {
            let i = this.derived_units.findIndex(u => u.id === this.selected_unit);
            this.derived_units[i] = {id:this.selected_unit, name:this.name, conversion_factor:this.conversion_factor};
            await editDerivedUnit(this.known_measure_id, this.selected_unit, this.name, this.conversion_factor, this.groupName);
            this.update();
        },
        update(){
            this.$emit('update');
            this.closeModal();
        },
        openEditor(unit_id){
            if(this.readOnly) return;
            const unit = this.value.find(u => u.id === unit_id);
            this.selected_unit = unit.id;
            this.name = unit.name || '';
            this.conversion_factor = unit.conversion_factor || 1;
            this.showModal = true;
        },
        closeModal(){
            this.selected_unit = null;
            this.name = '';
            this.conversion_factor=1;
            this.showModal = false;
        },
        isInSia(id){
            return this.derived_units_sia.includes(id);
        },
    },
};
</script>
