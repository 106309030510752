import { prop } from 'ramda';
import  ActivityChartRow  from '@/components/pages/Activity/ActivityChartRow';
const propOrZero = attrib =>  (rec =>  prop(attrib,rec) || 0);
const spaceToNewline = str =>  str.replace(/\s/g,'\n');
export default ($t) => [
    $t,
 { key : 'name',
   label : spaceToNewline($t('shared.name')),
   cellDataGetter : prop('city_name'),
   sortDataGetter : prop('city_name'),
 },
 { key : 'action_created',
   label : spaceToNewline($t('Actions added')),
   cellDataGetter : propOrZero('action_created'),
 },
 { key : 'privact_updated_action',
   label :   spaceToNewline($t('Actions published')),
   cellDataGetter : propOrZero('privacy_updated_action'),
 },
 { key : 'measures_created',
   label : spaceToNewline($t('Measures added')),
   cellDataGetter : propOrZero('measure_created'),
 },
 { key : 'measures_published',
   label : spaceToNewline($t('Measures published')),
   cellDataGetter : propOrZero('privacy_updated'),
 },
 { key : 'other',
   label : spaceToNewline($t('Other')),
   cellDataGetter : propOrZero('other_event'),
 },
 { key : 'graph',
   label : $t('Events'),
   cellDataGetter : prop('city_chart'),
   cellRenderer : ActivityChartRow,
 },
    
];
