import nl from './nl.json';
import en from './en.json';
import fr from './fr.json';
import es from './es.json';
import da from './da.json';
import de from './de.json';
import no from './no.json';
import sv from './sv.json';

export default { nl, en, fr, es, da, de, no, sv };
