import Vue from 'vue';

import { prop } from 'ramda';

export default (selectedUserGroup = null) => ({
  get: (endpoint, options = {}) =>
    Vue.http
      .get(endpoint, {
        headers: {
          cityGroupSubdomain: selectedUserGroup,
          cityGroupAuthToken: localStorage.getItem('id_token'),
        },
        ...options,
      })
      .then(prop('body'))
      .catch(error => {
        if (error.status === 401) {
          return Vue.prototype.$auth.logOut();
        }
        console.log(error);
        throw new Error(error);
      }),

  post: (endpoint, body = {}, options = {}) =>
    Vue.http
      .post(endpoint, body, {
        headers: {
          cityGroupSubdomain: selectedUserGroup,
          cityGroupAuthToken: localStorage.getItem('id_token'),
        },
        ...options,
      })
      .then(prop('body')),
});
