<template>
    <TablePageContainer
        :config="config"
        :data="known_measures"
        :isLoading="isLoading"
        :sortState="sortState"
        :title="title"
        :updateSort="updateSort"
        :onRowClick="handleOnRowClick"
    >
    <template slot="button">
        <router-link class="button is-rounded is-success mr-4" to="/own-plan/known-measure-form">{{ $t("+ Add Measure") }}</router-link>
    </template>
    </TablePageContainer>
</template>

<script>
import TablePageContainer from '@/components/shared/TablePageContainer';

import sortTableMixin from '@/mixins/sort-table';
import getConfig from '@/config/known-measures';
import { fetchKnownMeasuresList } from '@/api/known-measure';

import {
    GROUP_INFO,
} from '@/constants';

export default {
    mixins: [ sortTableMixin ],
    components: { TablePageContainer },

    created () {
        this.load();
    },

    data: () => ({
        known_measures: [],
        isLoading: true,
    }),

    computed: {
        config () {
            let is_FP = this.$store.getters.selectedUserGroup == 'belgium';
            return getConfig(this.$t, this.$i18n.locale, is_FP);
        },

        title () {
            const groupName = this.$store.getters[`${GROUP_INFO}/name`];
            return this.$t('knownMeasuresListPage.pageTitle', { groupName});
        },
    },

    methods: {
        load () {
            const selectedUserGroup = this.$store.getters.selectedUserGroup;
            fetchKnownMeasuresList(selectedUserGroup)
                .then(data => {
                    this.known_measures = data;
                    this.isLoading = false;
                });
        },

        handleOnRowClick (measure) {
            this.$router.push({ name: 'knownMeasureDetail', params: { id: measure.id } });
        },
    },
};
</script>
