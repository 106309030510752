<template>
    <div>
        <h4 class="title is-4">{{ $t('manageCitiesPage.inviteCities') }}</h4>
        <h5 class="subtitle">{{ $t('manageCitiesPage.displayCityLinkSubtitle',{city:city.name}) }}</h5>
        <div class="notification is-primary" v-if="copied">
            {{$t('manageCitiesPage.COPIED_LINK')}}
        </div>
        <div class="field has-addons">
            <div class="control  is-expanded">
                <input class="input" :value="city.link"  ref="link" readonly/>
            </div>
            <div class="control">
                <a class="button" @click="copyLink">
                    <i class="fas fa-clone" ></i>
                </a>
            </div>
        </div>
        <div class=" field flex justify-end">
            <button class="button is-danger" @click="close">
                {{ $t('manageCitiesPage.close') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
  props:{
      city: Object,
  },

  data:() => ({
      copied: false,
  }),

  methods: {
    close(){
        this.$emit('closeModal');
    },

    copyLink(){
         /* Get the text field */
        let copyText = this.$refs.link;

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand('copy');

        this.copied = true;
    },
  },
};
</script>
