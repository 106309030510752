<template>
  <PageContainer :isLoading="isLoading" :error="error">
    <FetchContainer :entities="entities">
      <MeasureDetail />
    </FetchContainer>
  </PageContainer>
</template>

<script>
import MeasureDetail from './MeasureDetail';

import { fetchUser } from '@/mixins';
import { MEASURES } from '@/constants';

export default {
  mixins: [fetchUser],

  components: { MeasureDetail },

  data() {
    return {
      entities: [MEASURES],
    };
  },
};
</script>