<template>
    <PageContainer :isLoading="isLoading" :error="error">
        <KnownMeasuresList />
    </PageContainer>
</template>

<script>
import KnownMeasuresList from './KnownMeasuresList';

import { fetchUser } from '@/mixins';

export default {
    mixins: [fetchUser],

    components: { KnownMeasuresList },
};
</script>
