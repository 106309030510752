<template>
<div class="relative flex justify-center" :style="{ height: '20px' }">
    <progress
        class="progress is-medium is-primary absolute"
        :style="{ left: 0 }"
        :value="value || 0" :max="max"
        :title="(value / max)" />
</div>
</template>

<script>
export default {
    props: ['value', 'max'],

    
};
</script>

<style lang="scss">
.progress-overlay {
  background: repeating-linear-gradient(
    90deg,
    #fab500,
    #fab500 5px,
    white 0,
    white 8px
  );
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
}
</style>
