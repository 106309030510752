<template>
  <div>
    <Modal v-if="isLoading">
      <div class="box">
        <Loading />
      </div>
    </Modal>
    <div v-if="show">
      <button class="button mr-4" v-on:click="handleClick()">
          <i :class="this.localIcon"/>
          <span>{{ this.localTitle }}</span>
      </button>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      localIcon: '',
      localTitle: '',
    };
  },
  props: {
    data: {
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    icon: {
      required: false,
      default: 'icon add-icon',
    },
  },
  mounted() {
    this.localIcon = this.icon;
    this.localTitle = this.title;
  },
  methods: {
    handleClick() {
      this.isLoading = true;

      this.onClick(this, () => {
        const originalTitle = this.title;
        const originalIconClass = this.icon;

        this.isLoading = false;
        this.localTitle = this.$t('Success');
        this.localIcon = 'fa fa-check pr-2 is-success';

        setTimeout(() => {
          this.localTitle = originalTitle;
          this.localIcon = originalIconClass;
        }, 5000);
      });
    },
  },
};
</script>
