<template>
    <div v-if="exceptions && exceptions.length && !closed" class="notification is-danger text-sm content">
        <button class="delete" @click="closed=true"></button>
        <span>{{$t("CITIES_WITHOUT_DATA_HIDDEN_DISCLAIMER")}}</span>
        <ul>
            <li v-for="exception in exceptions" :key="exception.city">
                {{$t(exception.exception,{city:exception.city})}} 
            </li>
        </ul>
    </div>
</template>

<script>
import { CARBON_SUMMED, VALUE } from '@/constants';
export default {
    data: () => ({
        closed: false,
    }),
    computed: {
        summed_emission_data() {
            return this.$store.getters[`${CARBON_SUMMED}/${VALUE}`];
        },

        exceptions() {
            if(!this.summed_emission_data || !this.summed_emission_data.exceptions){
                return false;
            }

            return this.summed_emission_data.exceptions;
        },
    },

};
</script>