import { __, compose,  path, propEq, concat } from 'ramda';
import { findInArrayByProp, findInArrayByPropEnforcing } from '@/utils';

const BASELINE_YEAR = 2011;

const getEmission = path(['totals', 'tonnes_co2_emitted']);

export const getPlannedData = path(['emissions', 'ideal_plan']);

export const getPastData = path(['emissions', 'past_data']);

export const getPredictedData = path(['emissions', 'predictions']);

export const getMergedData = city => {
  if(Object.keys(city).length) {
    return concat(getPastData(city),getPredictedData(city));
  }

  return null;
};

export const getPastDataFromYear = year =>
  compose(
    findInArrayByPropEnforcing(__, 'year', year),
    getPastData,
  );

export const getMergedDataFromYearGetterFunction = function(year){
  return function(city){
    // this function find a value in the merged data of the city, where prop 'year' is the requested year and throws an error if no such value exists
    if(Object.keys(city).length) {
      return findInArrayByProp(getMergedData(city), 'year', year);
    }

    return null;
  };
};

export const getPredictedDataFromYear = year =>
  compose(
    findInArrayByPropEnforcing(__, 'year', year),
    getPredictedData,
  );

export const getPredictedEmissionsInYear = year =>
  compose(
    getEmission,
    getPredictedDataFromYear(year),
  );

export const getPopulationByYear = year =>
  path(['emissions', 'population', year]);

// todo: rename
export const getEmissionsByYearByCity = year =>
  compose(
    getEmission,
    getMergedDataFromYearGetterFunction(year),
  );

export const getEmissionsByYearBySource = year => source =>
  compose(
    path([source, 'tonnes_co2_emitted']),
    getMergedDataFromYearGetterFunction(year),
  );

export const getBaselineEmission = baselineYear => getEmissionsByYearByCity(baselineYear ? baselineYear : BASELINE_YEAR);

export const getLastAvailableEmissionsPerPerson = year => city => {
  const emissions = getEmissionsByYearByCity(year)(city);

  const population = getPopulationByYear(year)(city);

  return emissions / population;
};

export const hasPastDataInYear = function(year){
  return function(city){
    return getPastData(city).find(propEq('year', year));
  };
};

const hasMergedDataInYear = function(year){
  return function(city){
    if(Object.keys(city).length) {
      return getMergedData(city).find(propEq('year', year));
    }

    return null;
  };
};

export const isValid = hasMergedDataInYear;

export const getChangeInEmissions = lastAvailableYear => city => {
  const lastAvailableEmissions = getEmissionsByYearByCity(lastAvailableYear)(
    city,
  );
  const baselineEmission = getBaselineEmission(city);

  return ((lastAvailableEmissions - baselineEmission) / baselineEmission) * 100;
};

export const getLastAvailableEmissionsPerKm2 = lastAvailableYear => city => {
  const lastAvailableEmissions = getEmissionsByYearByCity(lastAvailableYear)(
    city,
  );
  const surfaceArea = city.surface_area;

  return surfaceArea ? lastAvailableEmissions / surfaceArea : null;
};
