import { values } from 'ramda';

import { findInArrayByProp } from '@/utils';

export default {
  getCityByName: ({
    entities: {
      cities: { value: cities },
    },
  }) => name => findInArrayByProp(cities, 'name', name),

  getMeasureById: ({
    entities: {
      measures: { value: measures },
    },
  }) => id => findInArrayByProp(measures, 'known_measure_id', Number(id)),

  isAuthenticated: ({ auth: { expiresAt = 0 } }) =>
    new Date().getTime() < Number(expiresAt),

  isFetching: ({ entities }) =>
    values(entities).reduce((acc, { isFetching }) => acc || isFetching, false),
};
