<template>
  <Modal @closeModal="$emit('closeModal')">
    <div class="box">
      <div class="content">
        <h4>{{ $t("upload photo") }}</h4>
      </div>
      <hr />

      <input type="file" class="is-hidden" @change="readFile" id="foto_upload_id" />
      <slide-transition @afterEnter="setupCroppie">
        <div key="step1" v-if="currentStep === 'step1'">
          <button
            class="button is-rounded is-success"
            @click="triggerFileUpload"
          >{{ $t("upload photo") }}</button>
        </div>

        <div key="step2" v-if="currentStep === 'step2'">
          <div class="uploadPreview" id="uploadPreview"></div>
          <button class="button is-rounded is-success" @click="uploadPhoto">SAVE</button>
        </div>
      </slide-transition>
    </div>
  </Modal>
</template>

<script>
import Croppie from 'croppie';
import { uploadKnownMeasurePhoto } from '@/api/known-measure';
import { SlideTransition } from '@/components/shared/transitions';

export default {
  props: ['known_measure_id'],
  components: { SlideTransition },

  data: () => ({
    showModal: false,
    preview: null,
    currentStep: 'step1',
    file: null,
    original_image_base64: null,
  }),

  methods: {
    setupCroppie() {
      const $el = document.getElementById('uploadPreview');
      const preview = new Croppie($el, {
        viewport: { width: 300, height: 70, type: 'square' },
        boundary: { width: 300, height: 300 },
        showZoomer: false,
        enableExif: true,
      });

      this.preview = preview;
      const reader = new FileReader();
      reader.onload = (e) => {
        // save hi-res non-cropped image
        this.original_image_base64 = e.target.result;

        preview
          .bind({
            url: e.target.result,
          })
          .then(() => {
            preview.setZoom(0);
          });
      };

      reader.readAsDataURL(this.file);
    },

    triggerFileUpload() {
      document.getElementById('foto_upload_id').click();
    },

    readFile($event) {
      const file = $event.target.files[0];
      if (file.size > 12000000) {
        window.alert(this.$t('File is too large'));
      } else {
        this.file = file;
        this.currentStep = 'step2';
      }
    },

    uploadPhoto() {
      const groupName = this.$store.getters.selectedUserGroup;
      this.preview
        .result({
          type: 'base64',
          format: 'jpeg',
          size: 'original',
        })
        .then((img_base64) =>
          uploadKnownMeasurePhoto(
            img_base64,
            this.original_image_base64,
            this.known_measure_id,
            groupName,
          ),
        )
        .then(() => this.$router.go());
    },
  },
};
</script>

