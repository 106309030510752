import api from '@/api';
import merge from 'lodash.merge';
import { makeAsyncActionTypes } from '@/utils';
import {
  ERROR,
  FETCH,
  HAS_FETCHED,
  IS_FETCHING,
  RESET,
  VALUE,
} from '@/constants';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncActionTypes(FETCH);

const initialState = () => ({
  value: undefined,
  isFetching: false,
  hasFetched: false,
  error: null,
});

/**
 * Make store module async.
 * Create or add to an existing module to perform async calls to an api.
 * 
 * @param {String}    endpoint          The URI against which a GET call is made
 * @param {Object}    module            The module to extend
 * @param {Boolean}   namespaced        Modifier to say this module needs to be namespaced, default true
 * @param {Function}  parseApiResponse  Function that serves as a getter for the return body
 * @param {Boolean}   requiresUserGroup Modifier that informs that the api requires User group (API related)
 */
export default ({
  endpoint,
  module = {},
  namespaced = true,
  parseApiResponse = (prop) => (prop),
  requiresUserGroup = true,
}) =>
  merge({
    namespaced,

    state: initialState,

    mutations: {
      [REQUEST]: state => (state.isFetching = true),
      [SUCCESS]: (state, payload) => {
        state.value = payload;
        state.isFetching = false;
        state.hasFetched = true;
      },
      [FAILURE]: (state, error) => {
        state.error = error;
        state.isFetching = false;
        state.hasFetched = false;
      },
      [RESET]: state => {
        const s = initialState();
        Object.keys(s).forEach(key => {
          state[key] = s[key];
        });
      },
    },

    getters: {
      [ERROR]: ({ error }) => error,
      [HAS_FETCHED]: ({ hasFetched }) => hasFetched,
      [IS_FETCHING]: ({ isFetching }) => isFetching,
      [VALUE]: ({ value }) => value,
    },

    actions: {
      [FETCH]: ({ commit, state, rootGetters }) => {
        if (state.hasFetched || state.isFetching) {
          return Promise.resolve();
        }

        const selectedUserGroup = rootGetters.selectedUserGroup;

        if (requiresUserGroup && !selectedUserGroup) {
          return;
        }

        commit(REQUEST);

        return api(selectedUserGroup)
          .get(endpoint)
          .then(parseApiResponse)
          .then(body => commit(SUCCESS, body))
          .catch(error => commit(FAILURE, error));
      },
    },
  }, module);
