import { prop } from 'ramda';
import api from './index';

import {
    create_known_measure,
    get_known_measure,
    update_known_measure,
    delete_known_measure,
    upload_known_measure_photo,
    known_measures,
    set_known_measure_co_benefits,
    set_known_measure_risks,
    set_known_measure_suggested_to,
    add_known_measure_derived_unit,
    edit_known_measure_derived_unit,
    delete_known_measure_derived_unit
} from '@/config/endpoints';

export function fetchKnownMeasuresList(userGroup) {
    const parseApiResponse = prop('data');
    return api(userGroup)
        .get(known_measures)
        .then(parseApiResponse);
}

export function createKnownMeasure(params, userGroup) {
    return api(userGroup)
        .post(create_known_measure, params, { emulateJSON: true });
}

export function updateKnownMeasure(params, userGroup) {
    return api(userGroup)
        .post(update_known_measure, params, { emulateJSON: true });
}

export function deleteKnownMeasure(known_measure_id, userGroup) {
    return api(userGroup)
        .post(delete_known_measure, { known_measure_id }, { emulateJSON: true });
}

export function fetchKnownMeasureDetails(id, userGroup) {
    const endpoint = get_known_measure(id);
    return api(userGroup)
        .get(endpoint);
}

export function uploadKnownMeasurePhoto(image_base64, original_image_base64, known_measure_id, userGroup) {
    return api(userGroup)
        .post(upload_known_measure_photo, {
            image_base64,
            original_image_base64,
            known_measure_id,
        }, { emulateJSON: true });
}

export function addCoBenefits(known_measure_id, co_benefit_ids, co_benefit_ratings, userGroup) {
    return api(userGroup)
        .post(set_known_measure_co_benefits, {
            known_measure_id,
            co_benefit_ids,
            co_benefit_ratings,
        }, { emulateJSON: true });
}

export function addRisks(known_measure_id, risk_types, userGroup) {
    return api(userGroup)
        .post(set_known_measure_risks, {
            known_measure_id,
            risk_types,
        }, { emulateJSON: true });
}

export function addSuggestTo(known_measure_id, city_classifications, userGroup) {
    return api(userGroup)
        .post(set_known_measure_suggested_to, {
            known_measure_id,
            city_classifications,
        }, { emulateJSON: true });
}

export function addDerivedUnit(known_measure_id, name, conversion_factor, userGroup) {
    return api(userGroup)
        .post(add_known_measure_derived_unit, {
            known_measure_id,
            name,
            conversion_factor,
        }, { emulateJSON: true });
    }

export function editDerivedUnit(known_measure_id, id, name, conversion_factor, userGroup) {
    return api(userGroup)
        .post(edit_known_measure_derived_unit, {
            known_measure_id,
            id,
            name,
            conversion_factor,
        }, { emulateJSON: true });
}

export function removeDerivedUnit(known_measure_id, id, userGroup) {
    return api(userGroup)
        .post(delete_known_measure_derived_unit, {
            known_measure_id,
            id,
        }, { emulateJSON: true });
}