import { always, cond, map, prop, T } from 'ramda';

import { fractionToPercentage } from '@/utils';

import {
  getChangeInEmissions,
  getProjectedSavings,
  getEmissionsByYearByCity,
  getLastAvailableEmissionsPerPerson,
  getLastAvailableEmissionsPerKm2,
  getNumberOfMeasures,
  getNumberOfActions,
} from '@/utils/v2/city';

import {
  brownScale,
  greenScale,
  greenToRed,
  noMatchColor,
} from '@/config/colors';

const isInRange = (min, max) => value => value >= min && value <= max;

const breakpointsToGetColor = (breakpoints, colors) =>
  cond([
    ...breakpoints.map((breakpoint, index) => [
      isInRange(
        breakpoint,
        breakpoints[index + 1] != null ? breakpoints[index + 1] : Infinity,
      ),
      always(colors[index]),
    ]),
    [T, always(noMatchColor)],
  ]);

const serializeConfig = ({ colors = greenToRed, breakpoints, ...config }) => ({
  ...config,
  breakpoints,
  getColor: breakpointsToGetColor(breakpoints, colors),
});

const timesAThousand = val => val * 1000;

const stringifyPercentage = (data, locale) => {
  const percentage = fractionToPercentage(data / 100, locale);
  return data > 0 ? `+${percentage}` : percentage;
};

const mapViewsConfig = {
  changeInEmissions: {
    breakpoints: [-100, -16, -8, 0, 8, 16],
    label: 'titleChangeInEmissions',
    getData: getChangeInEmissions,
    version: 'v2',
    stringifyData: stringifyPercentage,
  },

  projectedSavings: {
    breakpoints: [-Infinity, -30, -20, -10, 0],
    label: 'titleChartPredictedSavings',
    getData: getProjectedSavings,
    version: 'v2',
    stringifyData: stringifyPercentage,
  },

  lastAvailableEmissionsTotal: {
    colors: brownScale,
    breakpoints: [0, 100, 200].map(timesAThousand),
    label: 'titleTotalEmissions',
    getData: getEmissionsByYearByCity,
    version: 'v2',
    stringifyData: (data, locale) => data.toLocaleString(locale),
    unit: 'units.tons',
  },

  lastAvailableEmissionsPerPerson: {
    colors: brownScale,
    breakpoints: [0, 5, 10],
    label: 'titleEmissionsPerPerson',
    getData: getLastAvailableEmissionsPerPerson,
    version: 'v2',
    stringifyData: (data, locale) =>
      data.toLocaleString(locale, {
        maximumFractionDigits: 2,
      }),
    unit: 'units.tonsPerPerson',
  },

  lastAvailableEmissionsPerKm2: {
    colors: brownScale,
    breakpoints: [0, 1500, 3000],
    label: 'titleEmissionsPerSquareKm',
    getData: getLastAvailableEmissionsPerKm2,
    version: 'v2',
    stringifyData: (data, locale) =>
      data.toLocaleString(locale, {
        maximumFractionDigits: 0,
      }),
    unit: 'units.tonsPerKm2',
  },

  numberOfMeasures: {
    colors: greenScale,
    breakpoints: [1, 5, 20],
    label: 'numberOfMeasures',
    getData: getNumberOfMeasures,
    version: 'v2',
    unit: 'units.measures',
  },

  numberOfActions: {
    colors: greenScale,
    breakpoints: [1, 5, 20],
    label: 'numberOfActions',
    getData: getNumberOfActions,
    unit: 'units.actions',
  },
};

export default map(serializeConfig, mapViewsConfig);
