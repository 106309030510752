<template>
<div>
    <Loading v-if="isLoading" />
    <template v-else>

        <!-- title and buttons -->
        <div class="field flex justify-between flex-wrap">
            <h3 class="title">
                <router-link class="back-button fas fa-arrow-left"
                    :title="$t('Back to measurePage')"
                    to="/own-plan/known-measures-list"
                />
                {{ $t("KnownMeasuresDetailPage.pageTitle") }}
            </h3>

            <div>           
                <button class="button is-rounded" :class="available ? 'is-danger': 'is-success'" @click="publishMeasure">
                    <span class="icon is-small"><i :class="available ? 'fas fa-ban': 'fas fa-cloud-upload-alt'"></i></span>
                    <span>{{ available ? $t("Unpublish measure") : $t("Publish measure") }}</span>
                </button>

                <Dropdown class="is-right">
                    <button slot="trigger" class="button">{{ $t("Actions") }}</button>

                    
                    <router-link :to="{ name: 'knownMeasureTranslations', params: { id }}" 
                        class="dropdown-item">
                        {{ $t("Manage translations") }}
                    </router-link>
                    <a class="dropdown-item is-warning" @click.stop="showDeleteModal = true">
                        {{ $t("Delete measure") }}
                    </a>
                </Dropdown>
            </div>
        </div>

        <div class="box">

            <!-- box heading -->
            <div class="box-header"
                :style="{ backgroundImage: `url(${computedImageSource})` }">
                <div class="box-header__content--top flex justify-end">
                    <router-link :to="{ name: 'knownMeasureForm', params: { id }}" 
                        class="button is-rounded is-semi-transparent">
                        <span class="icon is-small"><i class="fas fa-pen"></i></span>
                        <span>{{ $t("Edit") }}</span>
                    </router-link>  
                </div>
                <div class="box-header__content">
                    <div class="hide-on-mobile">
                        <template v-if="sectors.length > 0">
                            <span v-for="sector in sectors" :key="sector.sector_id" class="tag is-semi-transparent">{{ $t("sector") }}: {{ $t(sector.sector) }}</span>
                        </template>
                        
                        <span v-if="adaptation_sector" class="tag is-semi-transparent">{{ $t("adaptation_sector") }}: {{ $t(adaptation_sector) }}</span>
                        <span class="tag is-semi-transparent"
                            :class="available ? 'is-success': ''">
                            <span class="icon">
                                <i class="fas" :class="available ? 'fa-eye': 'fa-eye-slash'" />
                            </span>
                            {{ available ? $t('Published') : $t('Not published')}}
                        </span>
                    </div>
                    <div class="flex justify-between">
                        <h1 class="title mb-0">{{ name }}</h1>
                        <button class="button is-rounded is-semi-transparent" @click="showUploadModal  = true" >
                            <span>{{ $t("upload photo") }}</span>
                            <span class="icon"><i class="fas fa-camera"/></span>
                        </button>
                        <UploadPhotoModal v-if="showUploadModal"
                            @closeModal="showUploadModal = false"
                            :known_measure_id="id" />
                    </div>
                </div>
            </div>

            <br />
            <Field>
                <label class="label" slot="label">{{ $t("description_short") }}</label>
                <div style="white-space: pre-line">{{ description_short }}</div>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("description_long") }}</label>
                <div style="white-space: pre-line">{{ description_long }}</div>
            </Field>

            <hr />
            <Field>
                <label class="label" slot="label">{{ $t("scope") }}</label>
                <p>{{ scope }}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("category") }}</label>
                <p>{{ category }}</p>
            </Field>
            <hr>

            <Field>
                <label class="label" slot="label">{{ $t("co-benefits") }}</label>
                <CoBenefitsWidget v-model="co_benefits" :known_measure_id="known_measure_id" @update="load"/>
            </Field>

            <!-- risks -->
            <Field>
                <label class="label" slot="label">{{ $t('risks.label') }}</label>
                <TagPikker v-model="risks" :readOnly="true" translation_prefix="risks." />
            </Field>

            <hr/>

            <!-- suggestions -->
            <Field>
                <label class="label" slot="label">{{ $t('suggested_to.label') }}</label>
                <TagPikker v-model="suggested_to" :readOnly="true" translation_prefix="suggested_to." />
            </Field>

            <hr>

            <Field>
                <label class="label" slot="label">{{ $t("kpi_unit") }}</label>
                <p>{{ progress_unit }}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("kpi_target_year_1") }}</label>
                <p>{{ kpi_target_year_1 }}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("kpi_total_reference_percentage") }}</label>
                <p>{{ kpi_total_reference_percentage }}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("kpi_total_reference") }}</label>
                <p>{{ kpi_total_reference }}</p>
            </Field>

            <hr />
            
            <Field v-if="progress_unit">
                <label class="label" slot="label">{{ $t("derived_units") }}</label>
                <DerivedUnitsWidget v-model="derived_units" 
                    :derived_units_sia="derived_units_sia"
                    :known_measure_id="known_measure_id"
                    :progress_unit="progress_unit" :readOnly="true"/>
            </Field>

            <hr />
            
            <Field>
                <label class="label" slot="label">{{ $t("renewable_energy_production_kwh") }}</label>
                <p>{{ renewable_energy_production_kwh }}</p>
            </Field>


            <Field>
                <label class="label" slot="label">{{ $t("kwh_reduction") }}</label>
                <p>{{ kwh_reduction }}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("emission_factor_fuel") }}</label>
                <p>{{ emission_factor_fuel }}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("co2_reduction") }}</label>
                <p>{{ carbon_reduction }}</p>
                <p v-if="in_sia" class="text-xs">{{$t('MEASURE_DETAIL_SIA_INFO')}}</p>
            </Field>

            <hr />
            <Field>
                <label class="label" slot="label">{{ $t("initial_investment") }}</label>
                <p>{{ initial_investment }}</p>
                <p v-if="in_sia" class="text-xs">{{$t('MEASURE_DETAIL_SIA_INFO')}}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("investor_name") }}</label>
                <p>{{ investor_name }}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("yearly_savings") }}</label>
                <p>{{ yearly_savings }}</p>
                <p v-if="in_sia" class="text-xs">{{$t('MEASURE_DETAIL_SIA_INFO')}}</p>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("saver_name") }}</label>
                <p>{{ saver_name }}</p>
            </Field>
            
            <hr />
            <Field>
                <label class="label" slot="label">
                    <Tooltip :isWide="true" :tooltip="$t('x-factor-tooltip')">{{ $t("x_factor_implementation") }} <span class="fas fa-question-circle"/></Tooltip>
                </label>
                <p>{{ x_factor_implementation }}</p>
            </Field>
        </div>
        
        
        <Modal v-if="showDeleteModal" @closeModal="showDeleteModal = false">
            <div class="box">
                <div class="content">
                    <h4>{{ $t("Are you sure you want to delete this measure?", {name}) }}</h4>
                </div>
                <hr />
                <div class="content">
                    <button class="button is-rounded" @click="showDeleteModal = false">{{ $t("No, keep measure") }}</button>
                    <button class="button is-rounded is-danger" @click="deleteMeasure">{{ $t("Yes, delete measure") }}</button>
                </div>
            </div>
        </Modal>
        
    </template>
</div>
</template>

<script>
import { pick } from 'ramda';
import { deleteKnownMeasure } from '@/api/known-measure';

import { fields } from '@/config/known-measure-config';
import knownMeasureMixin from '@/mixins/known-measure';
import UploadPhotoModal from './UploadPhotoModal';
import CoBenefitsWidget from '../KnownMeasureForm/CoBenefitsWidget';
import TagPikker from '../KnownMeasureForm/TagPikker';
import DerivedUnitsWidget from '../KnownMeasureForm/DerivedUnitsWidget';

export default {
    components: {TagPikker, UploadPhotoModal, CoBenefitsWidget, DerivedUnitsWidget},
    mixins: [knownMeasureMixin],
    data () {
        return {
            showDeleteModal: false,
            showUploadModal: false,
        };
    },

    methods: {
        deleteMeasure () {
            deleteKnownMeasure(this.id, this.groupName)
                .then( () => {
                    this.showDeleteModal = false;
                    this.$router.push('/own-plan/known-measures-list');
                });
        },
        publishMeasure () {
            const params = pick(fields, this);
            if (params.photo) {
                params.available = params.available ? 0 : 1;
                params.sectors = this.sectors && this.sectors.length !== 0 ? JSON.stringify(this.sectors) : [];
                params.sector = this.sectors && this.sectors.length !== 0 ? JSON.stringify(this.sectors) : [];
                this.updateKnownMeasure(params)
                    .then( () => this.available = this.available ? 0 : 1 );
            } else {
                window.alert(this.$t('Please upload a photo before publishing this measure'));
            }
        },
    },
};
</script>
