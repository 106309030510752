import { fermobColors, fermobColorsLight } from '@/config/colors';

export { default as mapViews } from './map-views';
export { default as navItems } from './nav-items';

export const AUTH0_CLIENT_ID = 'zxrvFgyGMUQzF3aVbERjxASrQxgSkDRU';
export const AUTH0_DOMAIN = 'auth.futureproofed.com';
export const CALLBACK_PATH = '/callback';
export const CLAIM_INVITE_PATH = '/claim-invite';
export const LOGIN_PATH = '/login';
export const SENTRY_DSN =
  'https://900e6af57bbf4e59a1da0f0562d63a20@sentry.io/1715530'; 
export const SENTRY_DSN_DEV =
  'https://44082f631a094404b7de8fbd0cf140f6@sentry.io/1792437';

export const BASELINE_YEAR = 2011;
export const PREDICTION_YEAR = 2030;

export const availableLanguages = ['nl', 'fr', 'en', 'es', 'da', 'de', 'no', 'sv'];

// todo: can we hardcode this?
export const sources = [
  'mobility',
  'households',
  'government',
  'businesses',
  'agriculture',
  'industry',
  'others',
];

export const colorBySource = sources.reduce(
  (acc, source, index) => ({
    ...acc,
    [source]: fermobColors[index],
  }),
  {},
);

export const colorBySourceLight = sources.reduce(
  (acc, source, index) => ({
    ...acc,
    [source]: fermobColorsLight[index],
  }),
  {},
);
