<template>
  <PageContainer isFullscreen="true" :isLoading="isLoading" :error="error">
    <MapContainer />
  </PageContainer>
</template>

<script>
import PageContainer from '@/components/shared/PageContainer';
import MapContainer from './MapContainer';

import { fetchUser } from '@/mixins';

export default {
  mixins: [fetchUser],

  components: { MapContainer, PageContainer },
};
</script>

<style>
@media screen and (max-width: 768px) {
  .leaflet-control-container {
    display: none;
  }
}
</style>
