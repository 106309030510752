<template>
  <div class="cards-section">    
    <div class="flex justify-center items-center flex-wrap">
      <div class="flex justify-center items-center flex-wrap">
        <div class="tag is-primary mr-4 mb-4">
          Showing {{ filteredActions.length }} of {{ actions.length }} results
        </div>

        <SearchField
          class="mr-4 mb-4"
          @change="this.handleNameFilterChange"
          :placeholder="$t('ACTIONS_FILTER_BY_NAME')" />
      </div>

      <div class="flex justify-center items-center flex-wrap">
        <div class="select mr-4 mb-4">
          <select v-if="sectors.length > 0" v-model="filters.sector">
            <option :value="null">{{ $t("actionsPage.allSectors") }}</option>
            <option v-for="{ count, name } in sectors" :key="name" :value="name" :disabled="count === 0">{{ $t(name) }}</option>
          </select>
        </div>

        <div class="select mr-4 mb-4">
          <select v-if="types.length > 0" v-model="filters.type">
            <option :value="null">{{ $t("actionsPage.allTypes") }}</option>
            <option v-for="{ count, name } in types" :key="name" :value="name" :disabled="count === 0">{{ $t(name) }}</option>
          </select>
        </div>

        <div class="select mb-4">
          <select v-if="cities.length > 0" v-model="filters.city">
            <option :value="null">{{ $t("actionsPage.allCities") }}</option>
            <option
              v-for="{ name} in cities"
              :key="name"
              :value="name">
              {{ name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="flex justify-center flex-wrap">
      <div class="mr-4 mb-4 font-bold">{{ $t('Only show')}}:</div>

      <div class="field mr-4 mb-4">
        <input type="checkbox" id="actionable" class="switch" v-model="filters.actionable" />
        <label for="actionable">{{ $t("actionsPage.actionable") }}</label>
      </div>

      <div class="field mr-4 mb-4">
        <input type="checkbox" id="onlyPublic" class="switch" v-model="filters.onlyPublic" />
        <label for="onlyPublic">{{ $t("actionsPage.onlyPublic") }}</label>
      </div>

      <div class="field mr-4 mb-4">
        <input type="checkbox" id="onlyPrivate" class="switch" v-model="filters.onlyPrivate" />
        <label for="onlyPrivate">{{ $t("actionsPage.onlyPrivate") }}</label>
      </div>
    </div>

    <div class="section">
      <transition-group class="cards-list" name="card-transition" tag="div">
        <ActionCard v-for="action in displayedActions" :key="action.id" :action="action" />
      </transition-group>

      <div class="notification is-primary" v-if="filteredActions.length === 0">
        {{ $t('actionsPage.noResults') }}
      </div>
    </div>
  </div>
</template>

<script>
import { allPass } from 'ramda';
import throttle from 'lodash.throttle';
import ActionCard from './ActionCard';

import { filters } from '@/config/actions';

export default {
  name: 'CardsSection',

  components: { ActionCard },

  props: ['actions', 'sectors', 'types'],

  data: () => ({
    filters: filters.reduce(
      (acc, { name, initialValue }) => ({ ...acc, [name]: initialValue }),
      {},
    ),
    limit: 20,
  }),

  computed: {
    cities() {
      return this.$store.getters['groupInfo/cities'];
    },

    displayedActions() {
      return this.filteredActions.slice(0, this.limit);
    },

    filteredActions() {
      return this.actions.filter(
        allPass(
          filters.map(({ name, predicate }) => predicate(this.filters[name])),
        ),
      );
    },

    bodyContainer() {
      return document.querySelector('#body-container');
    },

    scrollContainer() {
      return document.querySelector('#scroll-container');
    },
  },

  mounted() {
    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.handleScroll);
    }
  },

  beforeDestroy() {
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener('scroll', this.handleScroll);
    }
  },

  methods: {
    handleNameFilterChange: throttle(function(data) {
      this.filters.name = data;
    }, 200),

    handleScroll: throttle(function() {
      const pixelsToEnd =
        this.bodyContainer.clientHeight -
        (this.scrollContainer.scrollTop + this.scrollContainer.clientHeight);

      if (pixelsToEnd < 1000 && this.limit < this.actions.length) {
        this.limit += 20;
      }
    }, 200),
  },
};
</script>

