import { prop } from 'ramda';
import api from './index';

import {
    known_actions,
    update_known_action,
    create_known_action,
    delete_known_action,
    upload_known_action_photo,
    get_known_action,
} from '@/config/endpoints';

export function fetchKnownActionsList(userGroup) {
    const parseApiResponse = prop('data');
    return api(userGroup)
        .get(known_actions)
        .then(parseApiResponse);
}

export function createKnownAction(params, userGroup) {
    return api(userGroup)
        .post(create_known_action, params, { emulateJSON: true });
}

export function updateKnownAction(params, userGroup) {
    return api(userGroup)
        .post(update_known_action, params, { emulateJSON: true });
}

export function deleteKnownAction(known_action_id, userGroup) {
    return api(userGroup)
        .post(delete_known_action, { known_action_id }, { emulateJSON: true });
}

export function fetchKnownActionDetails(id, userGroup) {
    const endpoint = get_known_action(id);
    const parseApiResponse = prop('data');
    return api(userGroup)
        .get(endpoint)
        .then(parseApiResponse);
}

export function uploadKnownActionPhoto(image_base64, original_image_base64, known_action_id, userGroup) {
    return api(userGroup)
        .post(upload_known_action_photo, {
            image_base64,
            original_image_base64,
            known_action_id,
        }, { emulateJSON: true });
}