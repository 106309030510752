<template>
  <canvas ref="chart" />
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'Chart',
  props: [
    'data',
    'hasSettings',
    'options',
    'perPerson',
    'plugins',
    'type',
  ],

  watch: {
    data (newData) {
      if (!this.hasSettings) {
        return;
      }
      this.chart.data = newData;
      this.chart.update();
    },

    options(newOptions) {
      if (!this.hasSettings) {
        return;
      }

      this.chart.options = newOptions;
      this.chart.update();
    },

    perPerson(perPerson) {
      this.chart.options.title.text = perPerson
        ? `${this.chart.options.title.text} ${this.$t('shared.perPerson')}`
        : this.options.title.text;
    },
  },

  mounted() {
    const ctx = this.$refs.chart;

    const { data, options, plugins, type } = this;

    this.chart = new Chart(ctx, {
      type,
      data,
      options,
      plugins,
    });
  },
};
</script>
