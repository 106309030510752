<template>
<div class="known-actions-page">
    <Loading v-if="isLoading" />
    <template v-else>
        <!-- title and buttons -->
        <div class="field flex justify-between flex-wrap">
            <h3 class="title">
                <router-link class="back-button fas fa-arrow-left"
                    :title="$t('Back')"
                    to="/own-plan/known-actions-list"
                />
                {{ $t("KnownActionsDetailPage.pageTitle") }}
            </h3>

            <div>           
                <button class="button is-rounded" :class="available ? 'is-danger': 'is-success'" @click="publishAction">
                    <span class="icon is-small"><i :class="available ? 'fas fa-ban': 'fas fa-cloud-upload-alt'"></i></span>
                    <span>{{ available ? $t("Unpublish action") : $t("Publish action") }}</span>
                </button>

                <Dropdown class="is-right">
                    <button slot="trigger" class="button">{{ $t("Actions") }}</button>

                    
                    <router-link :to="{ name: 'knownActionTranslations', params: { id }}" 
                        class="dropdown-item">
                        {{ $t("Manage translations") }}
                    </router-link>
                    <a class="dropdown-item is-warning" @click.stop="showDeleteModal = true">
                        {{ $t("Delete action") }}
                    </a>
                </Dropdown>
            </div>
        </div>

        <div class="box">
            <!-- edit button -->
            <div class="known-action__edit-button">
                <router-link :to="{ name: 'knownActionForm', params: { id }}" 
                        class="button is-rounded is-semi-transparent">
                    <span class="icon is-small"><i class="fas fa-pen"></i></span>
                    <span>{{ $t("Edit") }}</span>
                </router-link>
            </div>
            <Field>
                <!-- action photo upload -->
                <a slot="label" @click="showUploadModal = true">
                    <figure class="action-img" :style="{ backgroundImage: `url(${computedImageSource})` }" >
                        <figcaption class="action-img__overlay">
                            <div class="icon is-medium">
                                <i class="fas fa-2x fa-camera"/>
                            </div>
                            <p class="caption">{{ $t('upload photo') }}</p>
                        </figcaption>
                    </figure>
                </a>
                
                <!-- title and sector tags -->
                <h1 class="title mt-4">{{ name }}</h1>
                <span v-if="related_mitigation_sector" class="tag is-semi-transparent">{{ $t("sector") }}: {{ $t(related_mitigation_sector) }}</span>
                <span v-if="related_adaptation_sector" class="tag is-semi-transparent">{{ $t("adaptation_sector") }}: {{ $t(related_adaptation_sector) }}</span>
                <span class="tag is-semi-transparent"
                    :class="available ? 'is-success': ''">
                    <span class="icon">
                        <i class="fas" :class="available ? 'fa-eye': 'fa-eye-slash'" />
                    </span>
                    {{ available ? $t('Published') : $t('Not published')}}
                </span>
            </Field>
            <hr />

            <Field>
                <label class="label" slot="label">{{ $t("Description") }}</label>
                <div style="white-space: pre-line">{{ description }}</div>
            </Field>

            <Field>
                <label class="label" slot="label">{{ $t("type") }}</label>
                <p>{{ type }}</p>
            </Field>

            <Field>
            <label class="label" slot="label">
                <Tooltip :isWide="true" :tooltip="$t('actionable-tooltip')">{{ $t("actionable") }} <span class="fas fa-question-circle"/></Tooltip>
            </label>
            <p>{{ actionable ? $t('Yes'): $t('No') }}</p>
        </Field>

            <Field>
                <label class="label" slot="label">{{ $t("internal_notes") }}</label>
                <div style="white-space: pre-line">{{ internal_notes }}</div>
            </Field>
        </div>

        <h1 class="title">{{ $t('Related Measures') }}</h1>
        <RelatedMeasures 
            :related_measures="related_known_measures" 
            :readonly="true" />

        <Modal v-if="showDeleteModal" @closeModal="showDeleteModal = false">
            <div class="box">
                <div class="content">
                    <h4>{{ $t("Are you sure you want to delete this action?", { name: name }) }}</h4>
                    <hr />
                    <div class="content">
                        <button class="button is-rounded" @click="showDeleteModal = false">{{ $t("No, keep action") }}</button>
                        <button class="button is-rounded is-danger" @click="deleteAction">{{ $t("Yes, delete action") }}</button>
                    </div>
                </div>
            </div>
        </Modal>

        <UploadPhotoModal v-if="showUploadModal"
            @closeModal="showUploadModal = false"
            :known_action_id="id" />

    </template>
</div>
</template>

<script>
import { pick } from 'ramda';
import { deleteKnownAction } from '@/api/known-action';

import { fields } from '@/config/known-action-config';
import knownActionMixin from '@/mixins/known-action';
import UploadPhotoModal from './UploadPhotoModal';
import RelatedMeasures from '../KnownActionForm/RelatedMeasures';

export default {
    components: { UploadPhotoModal, RelatedMeasures },
    mixins: [knownActionMixin],
    data () {
        return {
            showDeleteModal: false,
            showUploadModal: false,
        };
    },

    methods: {
        deleteAction () {
            deleteKnownAction(this.id, this.groupName)
                .then( () => {
                    this.showDeleteModal = false;
                    this.$router.push('/own-plan/known-actions-list');
                });
        },

        publishAction () {
            const params = pick(fields, this);
            if (params.photo) {
                params.available = params.available ? 0 : 1;
                this.updateKnownAction(params)
                    .then( () => this.available = this.available ? 0 : 1);
            } else {
                window.alert(this.$t('Please upload a photo before publishing this actions'));
            }
        },
    },
};
</script>
