<template>
  <div id="map" :style="{ height: `${height}px` }" />
</template>

<script>
import Vue from 'vue';
import L from 'leaflet';

import config from '@/config/map-views';

import { CITIES } from '@/constants';

import { ChangeInEmissions, LastTotalEmissions } from './popups';

const INITIAL_CENTER = [50.4795318, 2.2235539]; // Belgium
const PROVIDER_URL =
  'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}';
const PROVIDER_ATTRIBUTION = 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ';

const popupConfig = {
  changeInEmissions: ChangeInEmissions,
  lastAvailableEmissionsTotal: LastTotalEmissions,
};

export default {
  props: ['data', 'height', 'mapView'],

  watch: {
    height() {
      this.fitMapToGeoJson();
    },

    data() {
      this.updateMap();
    },

    mapView() {
      this.updateMap();
    },
  },

  mounted() {
    setTimeout(this.initMap, 0);
  },

  methods: {
    initMap() {
      const el = document.getElementById('map');

      if (!el || !this.data) {
        return;
      }

      this.map = L.map(el, {
        center: INITIAL_CENTER,
      });

      L.tileLayer(PROVIDER_URL, {
        maxZoom: 18,
        attribution: PROVIDER_ATTRIBUTION,
        ext: 'png',
      }).addTo(this.map);

      this.updateMap();
    },

    updateMap() {
      
      if (!this.map) {
        return;
      }

      if (this.geoJsonLayer) {
        this.map.removeLayer(this.geoJsonLayer);
      }

      this.map.closePopup();

      const { getColor, stringifyData = String, unit } = config[this.mapView];

      const style = ({ properties: { data } }) => ({
        color: getColor(data),
      });

      const getTooltip = ({ properties: { data, name } }) => {
        const stringifiedData = stringifyData(data, this.$i18n.locale); let stringifiedDataWithUnit;

        if(isNaN(data)){
          stringifiedDataWithUnit = this.$t('shared.noDataAvailable');
        } else {
          stringifiedDataWithUnit = unit
            ? this.$tc(unit, data, { number: stringifiedData })
            : stringifiedData;
        }
          
        return `<strong>${this.titleCase(name)}</strong>: ${stringifiedDataWithUnit}`;
      };
      
      const onEachFeature = (feature, layer) => {
        layer
          .on('click', this.handleOnFeatureClick)
          .bindTooltip(getTooltip(feature));
      };
      
      if (this.data.length) {
        this.geoJsonLayer = L.geoJson(this.data, { style, onEachFeature }).addTo(
          this.map,
        );
      }

      if (this.geoJsonLayer) {
        this.bounds = this.geoJsonLayer.getBounds();
      }

      this.fitMapToGeoJson();
    },

    fitMapToGeoJson() {
      if (this.map && this.bounds) {
        this.map.fitBounds(this.bounds);
      }
    },

    handleOnFeatureClick({ latlng, sourceTarget }) {
      if (Object.keys(popupConfig).indexOf(this.mapView) === -1) {
        return;
      }

      const tooltip = sourceTarget._tooltip;

      sourceTarget.unbindTooltip();

      const cityName = sourceTarget.feature.properties.name;

      const city = this.$store.getters.getCityByName(cityName);
      const lastAvailableYear = this.$store.getters[
        `${CITIES}/lastAvailableYear`
      ];

      const popupContent = new Vue({
        render: h =>
          h(popupConfig[this.mapView], {
            props: {
              city,
              lastAvailableYear,
              locale: this.$i18n.locale,
              $t: this.$t,
            },
          }),
      }).$mount().$el;

      const popup = L.popup({ closeButton: false })
        .setLatLng(latlng)
        .setContent(popupContent);

      this.map
        .openPopup(popup)
        .once('popupclose', () => sourceTarget.bindTooltip(tooltip));
    },

    titleCase(str) {
      const splitStr = str.toLowerCase().split(' ');

      for (let i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      
      return splitStr.join(' '); 
    },
  },
};
</script>

<style lang="sass">
  @import '~leaflet/dist/leaflet.css'
</style>

