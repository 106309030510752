<template>
    <div>
        <div class="field flex justify-between flex-wrap">
            <h3 class="title">
                <a @click="goBack"
                   class="back-button fas fa-arrow-left"
                   :title="$t('Back')"
                />
                {{ $t("KnownActionsTranslationsPage.pageTitle", { name }) }}
            </h3>
            <div>
                <Select :options="languages" v-model="lang_selector" :labelGetter="item => $t(item.label)" @input="onChangeLang" />
                <button class="button is-rounded is-success" @click="updateTranslations" :disabled="isLoading">
                    {{ $t("Save") }}
                </button>
            </div>
        </div>
        
        <Loading v-if="isLoading" />
        <translations-form
                v-else
                :lang="language"
                :object="known_action"
                :form-config="action_config"
                object_type="known_action"
                :groupName="groupName"
                @setDirty="is_dirty = $event"
                ref="known_action_translations"
        />
    </div>
</template>

<script>
import {
    languages,
} from '@/config/known-measure-config';
import knownActionMixin from '@/mixins/known-action';
import {config} from './formConfig';
import Toast from '@/components/shared/Toast';
import TranslationsForm from '@/components/pages/KnownMeasureTranslations/TranslationsForm';


export default {
    mixins: [knownActionMixin],

    components: {TranslationsForm, Toast },
    data () {
        return {
            action_config: config(this.$t),
            lang_selector: 'en',
            language: 'en',
            languages,
            is_dirty: false,
        };
    },

    computed:{
        known_action(){
            return this.action_config.reduce( (a, c) => ({...a, [c.field]: this[c.field]}),{id: this.known_action_id});
        },
    },

    methods: {
        goBack () {
            if (this.is_dirty) {
                const confirmed = window.confirm(this.$t('Unsaved changes will be lost, are you sure you want to leave this page?'));
                if (confirmed) {
                    this.$router.go(-1);
                }
            } else {
                this.$router.go(-1);
            }
        },

        onChangeLang () {
            if (this.is_dirty && !window.confirm(this.$t('Unsaved changes will be lost, are you sure you want to leave this page?'))) {
                this.lang_selector = this.language;
            } else {
                this.language = this.lang_selector;
            }
        },

        updateTranslations(){
            this.$refs.known_action_translations.updateTranslations();
        },
    },
};
</script>
