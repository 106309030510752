<template>
  <div class="map-section">
    <div id="map" ref="map" />
  </div>
</template>

<script>
import Vue from 'vue';
import L from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet.markercluster';
import '@/utils/leaflet.custom-button';
import '@/utils/leaflet.fix-icon-bug';

import ActionCard from './ActionCard';
import ActionTooltip from './ActionTooltip';

const INITIAL_CENTER = [50.4795318, 2.2235539]; // Belgium
const INITIAL_ZOOM = 9;
const ZOOM_CONTROL_POSITION = 'topright';
const PROVIDER_URL =
        'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}';
const PROVIDER_ATTRIBUTION = 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ';

const getTooltipContent = action =>
  new Vue({
    render: h => h(ActionTooltip, { attrs: { action } }),
  }).$mount().$el;

export default {
  props: ['actions'],

  mounted() {
    setTimeout(this.initMap, 0);
  },

  methods: {
    initMap() {
      const cx = this.$refs.map;

      if (cx == null) {
        return;
      }

      this.map = L.map(cx, {
        center: INITIAL_CENTER,
        scrollWheelZoom: false,
        zoom: INITIAL_ZOOM,
      });

      L.tileLayer(PROVIDER_URL, {
        maxZoom: 18,
        attribution: PROVIDER_ATTRIBUTION,
        keepBuffer: 4,
      }).addTo(this.map);

      this.map.zoomControl.setPosition(ZOOM_CONTROL_POSITION);

      this.map.addControl(
        new L.Control.Fullscreen({
          position: ZOOM_CONTROL_POSITION,
        }),
      );

      L.control
        .addCustomButton({
          class: 'home',
          title: this.$t('actionsPage.backToHome'),
          onClick: this.setInitialState,
        })
        .addTo(this.map);

      const makeMarkerFromAction = action =>
        L.marker([action.location_lat, action.location_lon])
          .on('click', marker => this.handleOnMarkerClick(marker, action))
          .bindTooltip(getTooltipContent(action), { sticky: true });

      this.markers = L.markerClusterGroup().addLayers(
        this.actions.map(makeMarkerFromAction),
      );

      this.initialBounds = new L.LatLngBounds(
        this.actions.map(({ location_lat, location_lon }) => [
          location_lat,
          location_lon,
        ]),
      );

      this.setInitialState();
    },

    handleOnMarkerClick({ latlng, sourceTarget }, action) {
      const tooltip = sourceTarget._tooltip;

      sourceTarget.unbindTooltip();

      const popupContent = new Vue({
        render: h => h(ActionCard, { attrs: { action } }),
      }).$mount().$el;

      const popup = L.popup({ closeButton: false })
        .setLatLng(latlng)
        .setContent(popupContent);

      this.map
        .openPopup(popup)
        .once('popupclose', () => sourceTarget.bindTooltip(tooltip));
    },

    setInitialState() {
      this.map
        .closePopup()
        .fitBounds(this.initialBounds)
        .addLayer(this.markers);
    },
  },
};
</script>

<style lang="sass">
  @import '~leaflet/dist/leaflet.css'
  @import '~leaflet-fullscreen/dist/leaflet.fullscreen.css'
  @import '~leaflet.markercluster/dist/MarkerCluster.css'
  @import '~leaflet.markercluster/dist/MarkerCluster.Default.css'
</style>
