export default [
    {   
        id: 'overview',
        label: 'Overview Municipalities',
        subnav: [
            {
                to: '/',
                label: 'Dashboard',
            },
            {
                to: '/map',
                label: 'Map',
            },
            {
                to: '/municipalities',
                label: 'Municipalities',
            },
            {
                to: '/measures',
                label: 'Measures',
            },
            {
                to: '/actions',
                label: 'Actions',
            },
            {
                to: '/activity',
                label: 'Activity',
            },

        ],
    },
    {
        id: 'library',
        label: 'Library',
        subCategory: '/own-plan/',
        subnav: [
            {
                to: '/own-plan/known-measures-list',
                label: 'Measures',
            },
            {
                to: '/own-plan/known-actions-list',
                label: 'Actions',
            },
            {
                to: '/own-plan/mails',
                label: 'Communication',
            },
        ],
    },
];
