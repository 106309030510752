import { pick } from 'ramda';
import { reverseDirection } from '@/utils';

export default {
    data: () => ({
        sortDirection: 'ASC',
        sortByKey: 'name',
    }),

    computed: {
        sortState () {
            return pick(['sortDirection', 'sortByKey'], this);
        },
    },

    methods: {
        updateSort (key) {
            this.sortDirection =
                this.sortByKey === key
                    ? reverseDirection(this.sortDirection)
                    : this.sortDirection;
            this.sortByKey = key;
        },
    },
};