 <template>
<vFlatPickr
  :value="value"
  @on-change="changeValue"
  :config="config"
  />
</template>

<script>
import vFlatPickr from 'vue-flatpickr-component';


export default {

    
    components: { vFlatPickr },


    data() {
        return {
            config : { dateFormat : 'd/m/Y' }, 
        };
    },


    methods: {
        changeValue(newVal) {
            this.$emit('onChange', newVal);
        },
    },
        
    
    props: {
        'value' : { default: new Date().getTime()},
    },


};

</script>
