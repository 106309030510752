import api from './index';
import { prop } from 'ramda';

import {
    send_mails,
    send_sample_mail,
    city_officials,
} from '@/config/endpoints';

export function sendMail (params, userGroup) {
    return api(userGroup)
        .post(send_mails, params, { emulateJSON: true });
}

export function sendSampleMail (params, userGroup) {
    return api(userGroup)
        .post(send_sample_mail, params, { emulateJSON: true });
}

export function fetchCityOfficials (userGroup) {
    const parseApiResponse = prop('global_user_list');
    return api(userGroup)
        .get(city_officials)
        .then(parseApiResponse);
}
