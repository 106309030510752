<template>
  <Loading v-if="isLoading" />

  <Error v-else-if="error" :error="error" />
  
  <div id="body-container" v-else>
    <Map :actions="actionsWithinGroupBounds" />

    <PageContainer>
      <Actions
        :actions="actions"
        :sectors="sectors"
        :types="types" />
    </PageContainer>
  </div>
</template>

<script>
import Actions from './Actions';
import Map from './Map';

import { ACTIONS, FETCH } from '@/constants';

export default {
  components: { Actions, Map },

  computed: {
    actionsWithinGroupBounds() {
      return this.$store.getters['actions/actionsWithinGroupBounds'];
    },

    actions() {
      return this.$store.getters['actions/uniqueActions'];
    },

    error() {
      return this.$store.getters['actions/error'];
    },

    isLoading() {
      return this.$store.getters['actions/isFetching'];
    },

    sectors() {
      return this.$store.getters['actions/sectors'];
    },

    types() {
      return this.$store.getters['actions/types'];
    },
  },

  created() {
    this.$store.dispatch(`${ACTIONS}/${FETCH}`);
  },
};
</script>

