export default {
    props: [
        'value',
        'required',
        'validationRules',
        'placeholder',
    ],

    data () {
        return {
            isValid: true,
            isDirty: false,
            message: '',
        };
    },

    methods: {
        handleInput($event) {
            this.$emit('input', $event.target.value);
            this.isDirty = true;
            this.validate($event.target.value);
        },

        validate (value) {
            if (!this.isDirty) {
                return;
            }
            if (this.required && value =='') {
                this.isValid = false;
                this.message = this.$t('required');
                this.$emit('invalidate', false);
                return;
            }

            if (this.validationRules) {
                for (let i = 0; i < this.validationRules.length; i++) {
                    const rule = this.validationRules[i];
                    const valid = rule.validate(value);
                    if (!valid) {
                        this.isValid = false;
                        this.message = rule.message;
                        this.$emit('invalidate', false);
                        return;
                    }
                }
            }

            this.isValid = true;
            this.message = '';
            this.$emit('invalidate', true);
        },
    },
};