<template>
    <PageContainer :isLoading="isLoading" :error="error">
        <Mails />
    </PageContainer>
</template>

<script>
import Mails from './Mails';

import { fetchUser } from '@/mixins';

export default {
    props: ['id'],
    mixins: [fetchUser],

    components: { Mails },
};
</script>
