<template>
    <Modal @closeModal="$emit('closeModal')">
        <div class="box">
            <div class="content">
                <h4>{{ $t("Choose a measure related to this action") }}</h4>
                <Loading v-if="isLoading" />
                <Table 
                    v-else-if="known_measures.length"
                    :data="known_measures"
                    :config="config"
                    :isRowClickable="() => true"
                    :onRowClick="handleOnRowClick"
                    :sortByKey="sortByKey"
                    :sortDirection="sortDirection"
                    :updateSort="updateSort"
                    :filterBy="'name'"
                    :filter="''"
                />
                <template v-else>
                    <hr/>
                    <p>{{ $t('No measures available') }}</p>
                </template>
            </div>
        </div>
    </Modal>
</template>

<script>
import { pick } from 'ramda';
import Table from '@/components/shared/Table';
import { fetchKnownMeasuresList } from '@/api/known-measure';
import getConfig from '@/config/related-measures';
import { reverseDirection } from '@/utils';

export default {
    components: { Table },
    props: ['related_measures'],
    data: () => ({
        known_measures: [],
        isLoading: true,

        sortDirection: 'ASC',
        sortByKey: 'name',
    }),

    created () {
        this.load();
    },

    computed: {
        config () {
            return getConfig(this.$t);
        },

        sortState () {
            return pick(['sortDirection', 'sortByKey'], this);
        },
    },

    methods: {
        load () {
            const selectedUserGroup = this.$store.getters.selectedUserGroup;
            fetchKnownMeasuresList(selectedUserGroup)
                .then(data => {
                    const related_measures_ids = this.related_measures.map(ms => ms.related_known_measure);
                    this.known_measures = data.filter(ms => !related_measures_ids.includes(ms.id));
                    this.isLoading = false;
                });
        },

        updateSort (key) {
            this.sortDirection =
                this.sortByKey === key
                    ? reverseDirection(this.sortDirection)
                    : this.sortDirection;
            this.sortByKey = key;
        },

        handleOnRowClick (measure) {
            this.$emit('closeModal', measure.id);
        },
    },
};
</script>
