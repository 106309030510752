<template>
  <div>
    <Loading v-if="isLoading || !hasFetched" />

    <div class="notification" v-else-if="error">ERROR: {{ error.statusText }}</div>

    <slot v-else />
  </div>
</template>

<script>
import { FETCH, ERROR, IS_FETCHING, HAS_FETCHED } from '@/constants';

export default {
  props: ['entities'],

  created() {
    this.entities.forEach(entity => this.$store.dispatch(`${entity}/${FETCH}`));
  },

  computed: {
    error() {
      return this.entities.reduce(
        (acc, entity) => acc || this.$store.getters[`${entity}/${ERROR}`],
        false,
      );
    },

    hasFetched() {
      return this.entities.reduce(
        (acc, entity) => acc || this.$store.getters[`${entity}/${HAS_FETCHED}`],
        false,
      );
    },

    isLoading() {
      return this.entities.reduce(
        (acc, entity) => acc || this.$store.getters[`${entity}/${IS_FETCHING}`],
        false,
      );
    },
  },
};
</script>
