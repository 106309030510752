import investment from './Money_1.jpeg';
import announcement from './Announcement_1.jpeg';
import collaboration from './Collaboration_1.jpeg';
import groupPurchase from './Group_purchase_1.jpeg';
import financialIncentives from './Money_2.jpeg';
import infoSession from './Info_1.jpeg';
import newWebsite from './Website_2.jpeg';
import eventImg from './Event_1.jpeg';
import fallback from './Fallback.jpg';

export default {
  Investment: investment,
  Investments: investment,
  Anouncement: announcement,
  Announcement: announcement,
  'Group purchase': groupPurchase,
  'Financial incentives': financialIncentives,
  'Info session': infoSession,
  'New website': newWebsite,
  Event: eventImg,
  Collaboration: collaboration,
  fallback,
};
