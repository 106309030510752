<template>
    <div v-if="visible" class="toast">
        <div class="toast-box">
            <h1><slot /></h1>
        </div>
    </div>
</template>

<script>
export default {
    props: ['visible'],
    watch: {
        visible (newValue) {
            if (newValue) {
                setTimeout(function() {
                    this.$emit('close');
                }.bind(this), 2000);
            }
        },
    },
};
</script>
