<template>
  <div class="box flex-grow p-0 overflow-hidden" ref="wrapper">
    <Loading v-if="isLoading" />

    <div v-else-if="!hasData" class="flex h-full flex-col justify-center items-center">
      <h1 class="is-danger is-size-5">{{ $t('errorMessages.noMapData') }}</h1>
    </div>

    <div class="relative" v-else>
      <div
        class="absolute flex flex-col items-end"
        :style="{ top: '1rem', right: '1rem', maxWidth: 'calc(100% - 2rem)' }">
        <Dropdown :style="{ zIndex: 1001 }">
          <template slot="trigger">
            <button class="button max-w-full">
              <div class="max-w-full truncate">
                {{ mapViewsDropdownLabel }}
              </div>
            </button>
          </template>

          <a class="dropdown-item" v-for="({ key, label }) in mapViewsDropdownItems" :key="key" @click="selectMapView(key)">
            {{ label }}
          </a>
        </Dropdown>

        <div class="mt-4 hide-on-mobile" :style="{ zIndex: 1000 }">
          <Legend :config="legendConfig" :map-view="mapView"/>
        </div>        
      </div>

      <Map :height="height" :data="data" :map-view="mapView"/>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle';

import Loading from '@/components/shared/Loading';
import Legend from './Legend';
import Map from './Map';

import { PREDICTION_YEAR, BASELINE_YEAR, mapViews } from '@/config';
import { CITIES, GEO_JSON, FETCH } from '@/constants';

export default {
  components: { Loading, Legend, Map },

  data() {
    return {
      height: 0,
      mapView: null,
    };
  },

  computed: {
    isLoading() {
      return this.$store.getters.isFetching;
    },

    geoJsonItems() {
      return this.$store.getters['geoJson/value'];
    },

    cities() {
      return this.$store.getters['cities/value'];
    },

    lastAvailableYear() {
      return this.$store.getters['cities/lastAvailableYear'];
    },

    data() {
      if (!this.geoJsonItems || !this.cities) {
        return null;
      }

      const { getData } = mapViews[this.mapView];

      const res = this.geoJsonItems
        .map(item => {
          const city = this.cities.filter(city => {
            return city.name.toLowerCase() === item.properties.name.toLowerCase();
          })[0];

          return {
            ... item,
            properties: {
              ... item.properties,
              data: getData(this.lastAvailableYear, city),
            },
          };
        });

      return res;
    },

    hasData() {
      return this.data !== null && this.data.length > 0;
    },

    legendConfig() {
      return mapViews[this.mapView];
    },

    mapViewsDropdownItems() {
      let res = [];

      for (let prop in mapViews) {
        if(mapViews[prop]) {
          res[res.length] = {
            'key': prop,
            'label': this.translateLabel(mapViews[prop].label),
          };
        }
      }

      return res;
    },

    mapViewsDropdownLabel() {
      if(!this.mapView) return '';

      const { label } = mapViews[this.mapView];
      return this.translateLabel(label);
    },
  },

  created() {
    this.$store
      .dispatch(`${CITIES}/${FETCH}`)
      .then(() => this.$store.dispatch(`${GEO_JSON}/${FETCH}`));

    this.mapView = Object.keys(mapViews)[0];

    window.addEventListener('resize', throttle(this.updateHeight, 100));
  },

  mounted() {
    this.updateHeight();
  },

  beforeDestroy() {
    window.removeEventListener('resize', throttle(this.updateHeight, 100));
  },

  methods: {
    updateHeight() {
      const { wrapper } = this.$refs;

      if (wrapper == null) {
        return;
      }

      const { height } = wrapper.getBoundingClientRect();

      this.height = height;
    },

    selectMapView(mapView) {
      console.log(mapView);
      this.mapView = mapView;
    },

    translateLabel(label) {
      return this.$t(label, {
        lastAvailableYear: this.lastAvailableYear,
        baselineYear: BASELINE_YEAR,
        predictionYear: PREDICTION_YEAR,
      });
    },
  },
};
</script>

