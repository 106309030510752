    <template>

    <div>
    <div class="field flex justify-between flex-wrap">
      <h3 class="title">{{ title  }}</h3>

      <div class="flex">

        <Period :start="startTimestamp"
                :end="endTimestamp"
                @confirm="periodChange" />

        <div class="select mb-4 lm-1">
          <select v-if="dataAvaible &&  cities.length > 0" v-model="citySelect">
            <option value="_all_" selected="selected">{{ $t("actionsPage.allCities") }}</option>
            <option
              v-for="city in cities"
              :key="city"
              :value="city">
              {{ city }}
            </option>
          </select>
        </div>

        <ActivityFilter @change="handleActivityFilterChange" />

      </div>
    </div>

    <div v-if="dataAvaible">
      <div class="box">
        <div  :style="{height : '230px'}">
          <Loading v-if="isChartLoading" />
          <ActivityChart
            v-else
            :timeUnit="chartData.timeUnit"
            :title="chartData.title"
            :chartData="chartData.data"
            :shouldUpdate="shouldUpdate"
            />
        </div>
      </div>
      
      <div class="box relative" style="overflow-x: auto;">
        <Loading v-if="isTableLoading" />
        <Table
          v-else
          :data="tableData"
          headerClasses="pre-wrap text-center"
          :config="config"
          rowId="city_name"
          :sortDirection="sortDirection"
          :sortByKey="sortByKey"
          :updateSort="updateSort"
          />
        
        
      </div>
    </div>
    <div v-if="!dataAvaible">
      {{ $t("There is no activity for the given period") }}
</div>

  </div>
</template>

    <script>

import Datepicker from '@/components/shared/Datepicker';
import Table from '@/components/shared/Table';
import ActivityChart from './ActivityChart';
import sortTableMixin from '@/mixins/sort-table';
import Period from  '@/components/shared/Period';
import ActivityFilter from './ActivityFilter.vue'; 
import { responseToByDay, byDayToMainChartData, byCityAndByDayToTableData  } from './ActivityHelpers.js';
import { fetchActivity, fetchActivityOverTime } from '@/api/activity';
import activityConfig  from '@/config/activity';
import { nowMinusXmonths } from '@/utils/date';
import {
    FETCH,  CITIES,   GROUP_INFO,
} from '@/constants';

export default {
    mixins: [ sortTableMixin ],
    components: { Table, Period, ActivityFilter, ActivityChart, Datepicker },

    created () {    
        this.load();
    },

    data () {
        return {
            dataAvaible: false,
            byCityData : [],
            byDayData: {},
            shouldUpdate : false,
            isTableLoading : true,
            isChartLoading : true,
            startTimestamp : new Date(nowMinusXmonths(1)).getTime(),
            endTimestamp : new Date().getTime(),
            activityFilterString: '_all_',
            cityFilterString : '_all_',
        };
    },

    methods: {

        handleActivityFilterChange(e) {
            const filterString =  e.target.value || '';
            this.activityFilterString = filterString; 
            this.loadActivityOverTime(filterString);
        },

        loadActivityOverTime(activityFilterString) {
            
            this.isChartLoading =  true;
            // if no activityFilter is given, use the last stored in the state (data)
            let  activity  =  activityFilterString || this.activityFilterString;
            
            if (activity == '_all_') {
                activity = ''; 
            }

            const start = this.startTimestamp;
            const end = this.endOfEndDay;
            const groupName = this.$store.getters.selectedUserGroup;

            return fetchActivityOverTime(groupName,{ params: { start, end , activity  }})
                .then(timeResp => {
                    if (timeResp.length==0)  {
                        this.dataAvaible = false;
                        this.cityFilterString = '_all_';
                        return; 
                    }
                    this.dataAvaible = true;
                    this.shouldUpdate = true;
                    this.isChartLoading =  false;
                    this.byDayData = responseToByDay(timeResp,this.activityFilter);
                });
        },

        

        load() {
         this.$store.dispatch(`${CITIES}/${FETCH}`);
            const start = this.startTimestamp;
            const end = this.endOfEndDay;
            const groupName = this.$store.getters.selectedUserGroup;
            this.isTableLoading = true;
            this.isChartLoading = true;

            this.loadActivityOverTime().then(() =>
                                             fetchActivity(groupName,
                                                           { params: { start, end }})
                                             .then(cityResponse => {
                                                 if (cityResponse) {
                                                     this.byCityData = cityResponse;
                                                     this.shouldUpdate = false;
                                                     this.isTableLoading = false;
                                                 } 
                                             })); 
        },

        periodChange(period)  {
            this.startTimestamp = period[0];
            this.endTimestamp = period[1];
            this.load();
            this.shouldUpdate = false;
        },
    },

    watch: {
        groupName(newVal,oldVal) {
            const shouldRefresh = (oldVal && newVal!=oldVal);
            if (shouldRefresh) {
                this.load();
            }
        },
    },
    
    computed: {
        allCityNames()  { return this.$store.getters[`${CITIES}/cities`].map(x=>x.name); },
        chartData() {
            return byDayToMainChartData(this.byDayData, this.cityFilterString, this.startTimestamp, this.endOfEndDay, this.$t);
        },
        
        tableData() {
            return byCityAndByDayToTableData(this.byCityData, this.byDayData,  this.cityFilterString,
                                             this.startTimestamp, this.endOfEndDay, this.allCityNames);
        },

        endOfEndDay() {
            const dayLengthInMs = 3.6e6*24;
            return this.endTimestamp + dayLengthInMs -1;
        },

        config () {
            return activityConfig (this.$t);
        },

        groupName() {
            const groupName = this.$store.getters[`${GROUP_INFO}/name`];
            return groupName;
        },
        
        
        title() {
            const groupName = this.groupName;
            return this.$t('Activity in group', { groupName });
        },

        
        cities() {
            return this.allCityNames; 
        },

        citySelect:{
            set: function(cityString) {
                this.shouldUpdate= true;
                this.cityFilterString = cityString;

            },
            get: function() {
                return this.cityFilterString; 
            },
        },
},

} ;
</script>
