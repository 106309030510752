<template>
  <PageContainer :isLoading="isLoading" :error="error">
    <Cities />
  </PageContainer>
</template>

<script>
import Cities from './Cities';

import { fetchUser } from '@/mixins';

export default {
  mixins: [fetchUser],

  components: { Cities },
};
</script>
