<template>
  <div>
    <div class="content">
      <h4>{{ title }}</h4>
    </div>

    <hr />

    <div class="field">
      <input
        type="checkbox"
        id="togglePerPerson"
        class="switch"
        v-model="perPersonVal" />
      <label for="togglePerPerson">{{ $t('dashboardPage.showDataPerPerson') }}</label>
    </div>

    <div v-if="cities.length > 0">
      <hr />

      <div class="columns flex-wrap mt-0">
        <div class="field column w-full flex-none mb-0">
          <input
            type="checkbox"
            id="showAllCities"
            class="switch"
            :checked="citiesToHideVal.length === 0"
            @change="handleToggleAllCities" />
          <label for="showAllCities">{{ $t('actionsPage.allCities') }}</label>
        </div>

        <div class="field column is-half mb-0" v-for="city in sortedCities" :key="city.name">
          <input
            type="checkbox"
            :id="city.name"
            class="switch"
            :checked="citiesToHideVal.indexOf(city.name) === -1"
            @change="handleToggleCity(city.name)" />
          <label :for="city.name">{{ city.name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prop, sortBy } from 'ramda';

export default {
  props: ['citiesToHide', 'cities', 'perPerson', 'title'],

  data() {
    return {
      perPersonVal: this.perPerson,
      citiesToHideVal: this.citiesToHide,
    };
  },

  computed: {
    sortedCities() {
      return sortBy(prop('name'), this.cities);
    },
  },

  watch: {
    perPersonVal(newVal) {
      this.$emit('togglePerPerson', newVal);
    },

    citiesToHideVal(newVal) {
      this.$emit('newCitiesToHide', newVal);
    },
  },

  methods: {
    handleToggleAllCities() {
      if (this.citiesToHideVal.length) {
        this.citiesToHideVal = [];
      } else {
        this.citiesToHideVal = this.cities.map(prop('subdomain'));
      }
    },

    handleToggleCity(name) {
      this.citiesToHideVal.includes(name)
        ? this.showCity(name)
        : this.hideCity(name);
    },

    hideCity(name) {
      this.citiesToHideVal.push(name);
    },

    showCity(name) {
      const index = this.citiesToHideVal.indexOf(name);
      this.citiesToHideVal.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@media screen and (min-width: 769px) {
  .modal-content {
    margin: 0;
  }
}
</style>

