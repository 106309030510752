<template>
    <div class="box" @input="isDirty = true">
        <div class="columns" v-for="({ label, field, inputType }, index) in config" :key="field">
            <div class="column">
                <FieldVertical>
                    <label class="label" slot="label">{{ label }}</label>
                    <div style="white-space: pre-line; word-break: break-word;">{{ object[field] }}</div>
                </FieldVertical>
            </div>
            <div class="column">
                <FieldVertical>
                    <label class="label" slot="label">{{ label }}</label>
                    <input v-if="inputType == 'input'"
                           class="input"
                           type="text"
                           v-model="config[index].value" />
                    <textarea v-if="inputType == 'textarea'"
                              class="textarea"
                              v-model="config[index].value"/>
                </FieldVertical>
            </div>
        </div>
    </div>
</template>

<script>
    import { pathOr, path } from 'ramda';
    import { addTranslation, fetchTranslations, deleteTranslation } from '@/api/translations';
    import BulkProcess from '@/utils/BulkProcess';

    export default {
        props:{
            object: Object,
            object_type: String,
            formConfig: Array,
            lang: String,
            groupName: String,
        },
        data () {
            return {
                isDirty: false,
                translations: {},
                config: this.formConfig,
            };
        },

        created () {
            this.fetchTranslations();
        },
        
        watch:{
            lang(){
                this.populateForm();
            },
            isDirty(){
                this.$emit('setDirty', this.isDirty);
            },
        },

        methods: {
            fetchTranslations () {
                fetchTranslations({
                    object_id: this.object.id,
                    object_type:this.object_type,
                }, this.groupName)
                    .then(data => this.translations = data)
                    .then(this.populateForm);
            },

            updateTranslations () {
                const list = this.config.reduce((acc, { field, value }) => {
                    acc.push({ field, value, object_type: this.object_type, object_id: this.object.id, lang: this.lang });
                    return acc;
                }, []);

                const action = (params) => {
                    if (params.value == '') {
                        const id = path([this.lang, params.field, 'id'], this.translations);
                        const pars = Object.assign(params, { translation_id: id });
                        return deleteTranslation(pars, this.groupName);
                    } else {
                        return addTranslation(params, this.groupName);
                    }
                };
                const complete = () => {
                    this.fetchTranslations();
                };

                BulkProcess({
                    delay: 1,
                    list,
                    action,
                    complete,
                });
            },

            populateForm () {
                this.config.forEach( (conf, index) => {
                    const getValue = pathOr('', [this.lang, conf.field, 'value']);
                    this.config[index]['value'] = getValue(this.translations);
                });
                this.isDirty = false;
            },
        },
    };
</script>