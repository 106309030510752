<template>
  <div>
    <TablePageContainer
      :data="cities"
      :config="config"
      filterBy="name"
      :title="$t('manageCities')">
      <template slot="button">
        <button class="button is-primary mr-4" @click="showModal = true">
          <i class="icon add-icon-white" />
          <span>{{ $t('manageCitiesPage.inviteCities') }}</span>
        </button>
      </template>
    </TablePageContainer>

    <Modal v-if="showModal" @closeModal="closeModal">
      <div class="box">
          <InviteCities v-if="!invitedCity" @invite="city => invitedCity = city"/>
          <DisplayCityLink v-else @closeModal="closeModal" :city="invitedCity"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import TablePageContainer from '@/components/shared/TablePageContainer';
import InviteCities from './InviteCities';
import DisplayCityLink from './DisplayCityLink';

import getConfig from '@/config/manage-cities-table';

export default {
  components: {  TablePageContainer, InviteCities, DisplayCityLink },

  data() {
    return {
      showModal: false,
      invitedCity: null,
    };
  },

  methods:{
    closeModal(){
        this.showModal = false;
        this.invitedCity = null;
    },

  },

  computed: {
    config() {
      return getConfig(this.$t);
    },

    cities() {
      return this.$store.getters['groupInfo/cities'];
    },
  },
};
</script>
