import {
    fetchKnownMeasureDetails,
    updateKnownMeasure,
} from '@/api/known-measure';
import { fields } from '@/config/known-measure-config';

export default {
    props: ['known_measure_id'],
    data: () => ({
        isLoading: true,
        co_benefits: [],
        risks: [],
        suggested_to: [],
        derived_units: [],
        derived_units_sia: [],
        ...fields.reduce( (acc, field) => {
            acc[field] = '';
            return acc;
        }, {}),
    }),

    created () {
        this.load();
    },

    computed: {
        groupName () {
            return this.$store.getters.selectedUserGroup;
        },

        computedImageSource () {
            return  this.photo 
                ? `https://ddr90r1arm2wz.cloudfront.net/1/known_measures/${this.photo}`
                : 'https://app.futureproofed.com/images/samples/measure-sample.jpg';
        },
    },

    methods: {
        load () {
            if (this.known_measure_id) {
                fetchKnownMeasureDetails(this.known_measure_id, this.groupName)
                    .then(result => {
                        Object.keys(result.data).forEach(field => this[field] = result.data[field]);
                        this.risks = result.risks.map(risk => risk.type);
                        this.co_benefits = result.co_benefits;
                        this.suggested_to = result.suggested_to.map( s => s.city_classification);
                        this.derived_units = result.derived_units;
                        this.derived_units_sia = result.derived_units_sia;
                        this.isLoading = false;
                        // Creating asks for `sector` and when we fetch the created measure we get that information under `sectors` key for some reason.
                        // So in this component we no longer user `sectors` because of conflicts and just use this to make it sort of standardised
                        this.sectors = result.data.sectors;
                    });
            } else {
                this.isLoading = false;
            }
        },

        loadWidgets () {
            if (this.known_measure_id) {
                fetchKnownMeasureDetails(this.known_measure_id, this.groupName)
                    .then(result => {
                        this.co_benefits = result.co_benefits;
                        this.derived_units = result.derived_units;
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
            }
        },

        updateKnownMeasure (params) {
            params.known_measure_id = params.id;
            if (params.initial_investment) {
                params.initial_investment = params.initial_investment.replace(/,/g, '.');
            }
            if (params.yearly_savings) {
                params.yearly_savings = params.yearly_savings.replace(/,/g, '.');
            }
            return updateKnownMeasure(params, this.groupName);
        },
    },
};