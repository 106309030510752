import store from '@/store';

import { isProduction } from '@/utils';
import { getCookie} from '@/utils/cookie';

const APP_ID = 'k6p0hk71';


export default class IntercomService {
  constructor() {
    this.service = window.Intercom;
  }

  boot() {   
    if (!isProduction) {
      return;
    }

    this.service('boot', {
      app_id: APP_ID,
    });
  }

  update() {
    if (!isProduction) {
      return;
    }

    const userProfile = store.getters.getUserProfile;
    const userLocale = getCookie('locale');
    
    if (!userProfile) {
      return;
    }

    const { nickname, email} = userProfile;

    this.service('update', {
      name: nickname,
      email,
      fpc_language: userLocale,
      user_id: `group_app_${email}`,
      group_app_account: true,
    });
  }

  shutdown() {
    this.service('shutdown');
  }
}
