import { compose, path } from 'ramda';
import { makeModuleAsync } from '@/utils';
import { groupInfo as endpoint } from '@/config/endpoints';

const getters = {
  cities: path(['value', 'cities']),
  name: path(['value', 'group', 'name']),
  group: path(['value', 'group']),
  isUserAdmin: compose(
    Boolean,
    path(['value', 'admin']),
  ),
};

const module = {
  getters,
};

export default makeModuleAsync({ endpoint, module });
