<template>
  <Dropdown class="mr-4 is-right" v-if="isAuthenticated">
    <template slot="trigger">
      <div
        class="profile-image bg-light-grey rounded-full cursor-pointer"
        :style="{ backgroundImage: `url(${userProfileImageUrl})` }" />
    </template>

    <div class="dropdown-item dropdown-category-header">
      {{ $t("shared.Group") }}
    </div>

    <template v-if="availableUserGroups && availableUserGroups.length < 4">
      <a
        class="dropdown-item"
        v-for="{ name, subdomain } in availableUserGroups"
        :key="subdomain"
        :selected="subdomain === selectedUserGroup"
        @click="handleChangeAccount(subdomain)">
        {{ name }}
      </a>
    </template>
    <template v-else>
      <a class="dropdown-item" @click="$emit('changeRegion')">{{ $t('Change group') }}</a>
    </template>

    <hr class="dropdown-divider" />

    <div class="dropdown-item dropdown-category-header">
      {{ $t("shared.Language") }}
    </div>

    <a
      class="dropdown-item"
      v-for="lang in availableLanguages"
      :key="lang"
      :selected="lang === currentLocale"
      @click="handleChangeLocale(lang)">
      {{ $t(`languages.${lang}`) }}
    </a>

    <hr class="dropdown-divider" />

    <div class="dropdown-item dropdown-category-header">
      {{ $t("shared.Account") }}
    </div>

    <a class="dropdown-item" @click.stop="handlePasswordChange">
      {{ $t("changePassword") }}
    </a>
    <Toast :visible="showToast" @close="showToast = false">{{ $t('passwordChangeMessage') }}</Toast>

    <router-link class="dropdown-item" to="/manage-users" v-if="isUserAdmin">
      {{ $t('manageUsers') }}
    </router-link>

    <router-link class="dropdown-item" to="/manage-cities" v-if="isUserAdmin && isFutureProofedEmployee">
      {{ $t('manageCities') }}
    </router-link>

    <a class="dropdown-item" @click="handleLogOut">{{ $t("Log Out") }}</a>
    <hr class="dropdown-divider" />
    <a class="dropdown-item" @click.stop href="http://help.futureproofed.com/" target="__BLANK">{{ $t("Get help") }}</a>
    <a class="dropdown-item" @click.stop href="http://blog.futureproofed.com/" target="__BLANK">{{ $t("Blog") }}</a>
  </Dropdown>
</template>

<script>
import Vue from 'vue';
import Toast from '@/components/shared/Toast';

import { availableLanguages } from '@/config';
import { setCookie } from '@/utils/cookie';

export default {
  props: ['navItems'],
  components: { Toast },

  data() {
    return {
      availableLanguages,
      showToast: false,
    };
  },

  computed: {
    currentLocale () {
      return this.$i18n.locale;
    },

    availableUserGroups() {
      return this.$store.getters['userGroups/value'];
    },

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },

    selectedUserGroup() {
      return this.$store.getters.selectedUserGroup;
    },

    userProfileImageUrl() {
      const { userProfile } = this.$store.state.auth;

      return userProfile ? userProfile.picture : null;
    },

    isFutureProofedEmployee(){
      const { email } = this.$store.getters.getUserProfile;

      return email.length > 18 && email.slice(-18) == '@futureproofed.com';
    },

    isUserAdmin() {
      return this.$store.getters['groupInfo/isUserAdmin'];
    },
  },

  methods: {
    handleChangeAccount(userGroup) {
      this.$store.dispatch('selectUserGroup', userGroup);
    },

    handleChangeLocale(locale) {
      setCookie('locale', locale);
      this.$i18n.locale = locale;
      window.location.reload();
    },

    handleLogOut() {
      Vue.prototype.$auth.logOut();
    },

    show_toast () {
      this.showToast = true;
    },

    handlePasswordChange() {
      const { email } = this.$store.getters.getUserProfile;
      Vue.prototype.$auth.resetPassword(email, this.show_toast);
    },
  },
};
</script>

