import { PREDICTION_YEAR } from '@/config';

export const getChangeInEmissions = (year, city) => {
  const baselineEmission = city?.emissions.baseline_emission ?? 0;
  const lastAvailableEmissions = mergedEmissionsData(year, city)?.totals?.tonnes_co2_emitted ?? 0;

  return ((lastAvailableEmissions - baselineEmission) / baselineEmission) * 100;
};

export const getProjectedSavings = (year, city) => {
  const baselineEmission = city.emissions.baseline_emission;
  const predictedEmissions = mergedEmissionsData(PREDICTION_YEAR, city)?.totals?.tonnes_co2_emitted;

  return ((predictedEmissions - baselineEmission) / baselineEmission) * 100;
};

export const getEmissionsByYearByCity = (year, city) => {
  return mergedEmissionsData(year, city)?.totals?.tonnes_co2_emitted;
};

export const getLastAvailableEmissionsPerPerson = (year, city) => {
  const emissions = mergedEmissionsData(year, city)?.totals?.tonnes_co2_emitted;
  const population = populationByYear(year, city);
  return emissions / population;
};

export const getEmissionsByYearBySource = (year, city, source) => {
  return mergedEmissionsData(year, city)[source]?.tonnes_co2_emitted;
};

export const getLastAvailableEmissionsPerKm2 = (year, city) => {
  const lastAvailableEmissions = mergedEmissionsData(year, city)?.totals?.tonnes_co2_emitted;
  const surfaceArea = city.surface_area;
  return surfaceArea ? lastAvailableEmissions / surfaceArea : null;
};

export const getNumberOfMeasures = (year, city) => {
  return city.count_measures;
};

export const getNumberOfActions = (year, city) => {
  return city.count_actions;
};

export const populationByYear = (year, city) => {
  return city?.emissions?.population ? city?.emissions?.population[year] : null;
};

export const hasPastDataInYear = (year, city) => {
  return city.emissions.past_data.filter(prediction => {
    return prediction.year === year;
  }).length > 0;
};

export const mergedEmissionsData = (year, city) => {
  const predictions = city?.emissions?.predictions?.filter(prediction => {
    return prediction.year === year;
  })[0];

  const pastData = city?.emissions?.past_data?.filter(prediction => {
    return prediction.year === year;
  })[0];

  return {
    ...predictions, 
    ...pastData,
  };
};
