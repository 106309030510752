<template>
  <ChartContainer>
    <template slot="chart">
      <Chart
        :data="chartData"
        :hasSettings="true"
        :options="options"
        :perPerson="showDataPerPerson"
        type="bar" />
    </template>

    <ModalContent
      slot="modal-content"
      :cities="data"
      :citiesToHide="citiesToHide"
      :perPerson="showDataPerPerson"
      :title="title"
      @newCitiesToHide="citiesToHide = $event"
      @togglePerPerson="showDataPerPerson = $event" />
  </ChartContainer>
</template>

<script>
import 'chartjs-plugin-deferred';
import Chart from '@/components/pages/Dashboard/charts/Chart';
import ChartContainer from '@/components/pages/Dashboard/charts/ChartContainer';
import ModalContent from '@/components/pages/Dashboard/charts/ModalContent';

import getNiceMaxMin from '@/utils/nice-num';

import {
  getEmissionsByYearByCity,
  populationByYear,
} from '@/utils/v2/city';

import { fractionToPercentage, parseNumber, stringifyNumber } from '@/utils';
import { CITIES } from '@/constants';
import { BLUE, GREEN, ORANGE } from '@/config/colors';

const NUMBER_OF_TICKS = 10;

export default {
  components: { Chart, ChartContainer, ModalContent },

  data() {
    return {
      showDataPerPerson: false,
      citiesToHide: [],
    };
  },

  props: {
    fromYear: {
      required: true,
    },
    toYear: {
      required: true,
    },
  },

  computed: {
    data() {
      return this.$store.getters[`${CITIES}/validCities`];
    },

    citiesToDisplay() {
      return this.data.filter(
        ({ name }) => this.citiesToHide.indexOf(name) === -1,
      );
    },

    dataWithName() {
      return this.citiesToDisplay
        .map(city => this.parseCity(city))
        .sort((a, b) => a.absolute - b.absolute);
    },

    labels() {
      return this.dataWithName.map(prop => prop.city);
    },

    dataAbsolute() {
      return this.dataWithName.map(prop => prop.absolute);
    },

    dataPercentage() {
      return this.dataWithName.map(prop => prop.percentage);
    },

    datasets() {
      return [
        {
          data: this.dataPercentage,
          yAxisID: 'percentage',
          type: 'line',
          showLine: false,
          label: this.$t('shared.percentage'),
          borderWidth: 0,
          backgroundColor: BLUE,
          parseTooltip: value => fractionToPercentage(value, this.$i18n.locale),
        },
        {
          backgroundColor: this.dataAbsolute.map(
            value => (value > 0 ? ORANGE : GREEN),
          ),
          data: this.dataAbsolute,
          yAxisID: 'absolute',
          label: this.$t('shared.absolute'),
          borderWidth: 0,
          parseTooltip: value =>
            this.$t('tons', {
              number: parseNumber(value)(this.$i18n.locale),
            }),
        },
      ];
    },

    chartData () {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },

    title() {
      return this.$t('titleChartPredictedSavings', {
        baselineYear: this.fromYear,
        predictionYear: this.toYear,
      });
    },

    options() {
      const { niceMin: niceMinAbs, niceMax: niceMaxAbs } = getNiceMaxMin(
        this.dataAbsolute,
        NUMBER_OF_TICKS,
      );

      const { niceMin: niceMinPerc, niceMax: niceMaxPerc } = getNiceMaxMin(
        this.dataPercentage,
        NUMBER_OF_TICKS,
      );

      const ratio = niceMaxAbs === 0 ? 0 : niceMinAbs / niceMaxAbs;

      const getMinMaxPerc = ({ min, max, ratio }) => {
        if (ratio === 0) {
          return { min, max: 0 };
        }

        return max * ratio < min
          ? {
              min: max * ratio,
              max,
            }
          : {
              min,
              max: min / ratio,
            };
      };

      const { min: minPerc, max: maxPerc } = getMinMaxPerc({
        min: niceMinPerc,
        max: niceMaxPerc,
        ratio,
      });

      return {
        
        plugins: {
          deferred: {
            enabled: true,
            delay: 200,
          },

          title: {
            display: true,
            text: this.title,
          },

          tooltip: {
            callbacks: {
              label: ({dataset, formattedValue}) => {
                return dataset.parseTooltip(formattedValue);
              },
            },
          },

          // The syntax changed between chartjs 2 and 3
          // Someone would need to figure it out at some point, but not prio right now
          /*legend: {
            onHover: e => {
              e.target.style.cursor = 'pointer';
            },
          },*/

        },

        // The syntax changed between chartjs 2 and 3
        // Someone would need to figure it out at some point, but not prio right now
        /*onHover: function(e) {
            console.log(e);
            const point = this.getElementAtEvent(e);
            e.target.style.cursor = point.length ? 'pointer' : 'default';
        },*/

        scales: {
          x: {
              stacked: true,
              ticks: {
                autoSkip: false,
              },
          },
          absolute: {
              id: 'absolute',
              axis: 'y',
              position: 'left',
              ticks: {
                callback: value =>
                  this.$t('tons', {
                    number: stringifyNumber(value, this.$i18n.locale),
                  }),
              },
              max: niceMaxAbs,
              min: niceMinAbs,
            },
          percentage: {
              grid: { display: false },
              id: 'percentage',
              axis: 'y',
              position: 'right',
              ticks: {
                callback: value => fractionToPercentage(value, this.$i18n.locale),
              },
              max: maxPerc,
              min: minPerc,
          },
        },
      };
    },
  },

  methods: {
    parseCity(city) {
      const baselineEmissionData = 
        city.emissions.past_data.filter(c => c.year === this.fromYear)[0]?.totals?.tonnes_co2_emitted 
        || null;

      if(!baselineEmissionData) {
        return {
          city: city.name,
          absolute: 0,
          percentage: 0,
        };
      }

      const baselineEmission =
        baselineEmissionData /
        (this.showDataPerPerson ? populationByYear(this.fromYear, city) : 1);

      const predictedEmissions =
        getEmissionsByYearByCity(this.toYear, city) /
        (this.showDataPerPerson
          ? populationByYear(this.toYear, city)
          : 1);

      return {
        city: city.name,
        absolute: predictedEmissions - baselineEmission,
        percentage: predictedEmissions ? (predictedEmissions - baselineEmission) / baselineEmission : null,
      };
    },
  },
};
</script>
