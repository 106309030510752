<template>
  <div :class="`page-container overflow-y-auto flex-grow ${isFullscreen ? 'flex-fullscreen' : ''}`">
    <div :class="`section ${isFullscreen ? 'flex-fullscreen' : ''}`">
      <div :class="`container w-full ${isFullscreen ? 'flex-fullscreen' : ''}`">
        <Loading v-if="isLoading" />

        <Error v-else-if="error" />

        <slot v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Error from '@/components/shared/Error';
import Loading from '@/components/shared/Loading';

export default {
  props: ['error', 'isFullscreen', 'isLoading'],

  components: { Error, Loading },
};
</script>

<style lang="scss">
.page-container {
  -webkit-overflow-scrolling: touch;
}
</style>
