import { prop } from 'ramda';
import  api from './index';

import {
    activity,
    activityOverTime,
} from '@/config/endpoints'; 


export function fetchActivity(userGroup,params) {
    const parseApiResponse = prop('activity');
    return api(userGroup)
        .get(activity,params)
        .then(parseApiResponse);    
}


export function fetchActivityOverTime(userGroup,params) {
    const parseApiResponse = prop('activity');
    return api(userGroup)
        .get(activityOverTime,params)
        .then(parseApiResponse);    
}
