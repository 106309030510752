<template>
  <div class="search-field control has-icons-right">
    <input
      type="text"
      class="input"
      v-model="value"
      :placeholder="placeholder || $t('typeToFilter')"
      @input="$emit('change', value)" />
    <span :class="`icon is-small is-right ${value ? 'close' : 'search'}-icon`" @click="clear" />
  </div>
</template>

<script>
export default {
  props: ['placeholder'],

  data() {
    return {
      value: '',
    };
  },

  methods: {
    clear() {
      this.$emit('change', '');
      this.value = '';
    },
  },
};
</script>
