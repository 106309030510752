<template>
    <PageContainer :isLoading="isLoading" :error="error">
        <KnownActionTranslations :known_action_id="id"/>
    </PageContainer>
</template>

<script>
import KnownActionTranslations from './KnownActionTranslations';

import { fetchUser } from '@/mixins';

export default {
    mixins: [fetchUser],
    props: ['id'],

    components: { KnownActionTranslations },
};
</script>
