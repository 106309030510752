<template>
  <PageContainer :isLoading="isLoading">
      <ManageCitiesContainer />
  </PageContainer>
</template>

<script>
import ManageCitiesContainer from './ManageCitiesContainer';
import {
    GROUP_INFO,
    FETCH,
    IS_FETCHING,
} from '@/constants';

export default {
    components: { ManageCitiesContainer },

    created () {
        if(!this.isFutureProofedEmployee){
            this.$router.push('/');
        }
        this.$store.dispatch(`${GROUP_INFO}/${FETCH}`);
    },

    computed: {
        isLoading () {
            return this.$store.getters[`${GROUP_INFO}/${IS_FETCHING}`];
        },

        isFutureProofedEmployee(){
            const { email } = this.$store.getters.getUserProfile;

            return email.length > 18 && email.slice(-18) == '@futureproofed.com';
        },
    },
};
</script>