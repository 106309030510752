import { compose, prop } from 'ramda';

import { MeasureName, Progress } from '@/components/shared/table-cells';

import { toLocaleString } from '@/utils';
import { getMeasureName } from '@/utils/MeasureUtils';

export default ($t, locale, currency) => [
  {
    key: 'name',
    label: $t('shared.name'),
    cellDataGetter: ms => ({
      name: getMeasureName(ms, locale),
      sector_name: $t(ms.campaign_name),
    }),
    cellRenderer: MeasureName,
    sortDataGetter: prop('name'),
  },
  {
    key: 'progress',
    label: $t('shared.progress'),
    subLabel: $t('shared.progressSubLabel'),
    cellDataGetter: ({ progress, target, progress_unit }) => ({
      value: target ? (Number(progress) / target) * 100 : 0,
      label: `${Number(progress).toLocaleString(
        locale,
      )}/${Number(target).toLocaleString(locale)} ${progress_unit}`,
    }),
    cellRenderer: Progress,
    sortDataGetter: ({ progress, target }) =>
      target ? (Number(progress) / target) * 100 : 0,
  },
  {
    key: 'reduction',
    label: $t('shared.reduction'),
    subLabel: $t('measuresPage.reductionSubLabel'),
    cellDataGetter: compose(
      value => value.toLocaleString(locale, { maximumFractionDigits: 0 }),
      prop('total_carbon_reduction'),
    ),
    sortDataGetter: prop('total_carbon_reduction'),
  },
  {
    key: 'savings',
    label: $t('cityDetailsPage.savings'),
    subLabel: $t('cityDetailsPage.savingsSubLabel'),
    cellDataGetter: compose(
      toLocaleString(locale, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
      prop('total_yearly_savings'),
    ),
    sortDataGetter: compose(
      prop('total_yearly_savings'),
    ),
  },
];
