<template>
  <TablePageContainer
    :config="config"
    :data="data"
    :isLoading="isLoading"
    :sortState="sortState"
    :title="title"
    :updateSort="updateSort"
    :onRowClick="handleOnRowClick" />
</template>

<script>
import TablePageContainer from '@/components/shared/TablePageContainer';

import getConfig from '@/config/measures';
import {
  FETCH,
  GROUP_INFO,
  IS_FETCHING,
  MEASURES,
  UPDATE_SORT,
  VALUE,
} from '@/constants';

export default {
  components: { TablePageContainer },

  created() {
    this.$store.dispatch(`${MEASURES}/${FETCH}`);
  },

  computed: {
    isLoading() {
      return this.$store.getters[`${MEASURES}/${IS_FETCHING}`];
    },

    config() {
      return getConfig(this.$t, this.$i18n.locale);
    },

    data() {
      return this.$store.getters[`${MEASURES}/${VALUE}`];
    },

    title() {
      const groupName = this.$store.getters[`${GROUP_INFO}/name`];

      return this.$t('measuresPage.pageTitle', { groupName });
    },

    sortState() {
      return this.$store.getters[`${MEASURES}/sortState`];
    },
  },

  methods: {
    handleOnRowClick({ known_measure_id: id }) {
      this.$router.push({ name: 'measureDetail', params: { id } });
    },

    updateSort(key) {
      this.$store.commit(`measures/${UPDATE_SORT}`, key);
    },
  },
};
</script>

