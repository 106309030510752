<template>
  <FetchContainer class="box h-full" :entities="entities">
    <ProjectedSavings :from-year="fromYear" :to-year="toYear"/>
  </FetchContainer>
</template>

<script>
import ProjectedSavings from './ProjectedSavings';
import FetchContainer from '@/components/shared/FetchContainer';

import { CITIES } from '@/constants';

export default {
  components: { ProjectedSavings, FetchContainer },

  data() {
    return {
      entities: [CITIES],
    };
  },

  props: {
    fromYear: {
      required: true,
    },
    toYear: {
      required: true,
    },
  },
};
</script>
