import { render, staticRenderFns } from "./RegionSwitcherPopup.vue?vue&type=template&id=7c15e39c&"
import script from "./RegionSwitcherPopup.vue?vue&type=script&lang=js&"
export * from "./RegionSwitcherPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports