import { prop } from 'ramda';

import {
    Image,
    AvailableStatus,
    SiaStatus
} from '@/components/shared/table-cells';

import { getMeasureName } from '@/utils/MeasureUtils';

export default function getConfig($t, locale, is_FP) {
    let config = [
        {
            key: 'image',
            label: '',
            cellDataGetter: ({ photo_thumb }) => photo_thumb 
                ? `https://ddr90r1arm2wz.cloudfront.net/1/known_measures/${photo_thumb}`
                : 'https://app.futureproofed.com/images/samples/thumbs/measure-sample.jpg?v=2',
            cellRenderer: Image,
            width: '75px',
        },
        {
            key: 'name',
            label: $t('shared.name'),
            cellDataGetter: ms => getMeasureName(ms, locale),
        },
        {
            key: 'sector',
            label: $t('sector'),
            cellDataGetter: m => {
                let text = '';
                m.sectors.forEach((element, i) => {
                    text += $t(element.sector)
                    if (i < m.sectors.length - 1) text += ', ';
                });
                return text;
            },
        },
        {
            key: 'adaptation_sector',
            label: $t('adaptation_sector'),
            cellDataGetter: m => $t(m.adaptation_sector) || '',
        },
        {
            key: 'available',
            label: $t('published'),
            cellDataGetter: prop('available'),
            cellRenderer: AvailableStatus,
            width: '40px',
        }
    ];

    if(is_FP){
        config.push(
            {
                key: 'in_sia',
                label: 'Sia',
                cellDataGetter: prop('in_sia'),
                cellRenderer: SiaStatus,
                width: '40px',
            }
        );
    }

    return config;
}