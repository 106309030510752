<template>
    <PageContainer>
      <div>
          <Activity />
      </div>
  </PageContainer>
</template>

<script>
import Activity from './Activity';
import { fetchUser } from '@/mixins';

export default {
  mixins: [fetchUser],
  components: { Activity },
};
</script>
