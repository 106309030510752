
// functions from here on are pure, (no need for 'this',nor changing it)

import { uniq, sum, range  } from 'ramda';
import { partition } from '@/utils';
import { tsToIsoDate,MILLISECONDS_IN_DAY, TIME_UNITS } from '@/utils/date';


const RELEVANT_EVENTS = ['measure_created','action_created','privacy_updated','privacy_updated_action'];

// given the object 'daysByRef', a date and a city, lookup if the keys for both date
// and for nested key city exist, otherwise, add them to the object, with initial values.
// daysByRef is passed by ref and changed inline
export const initializeDateAndCityKeys = (daysByRef, dateFormatted, cityName) =>  {

    const initValue = { event_nb : 0 }; 

    // this date not yet in collection ? create it as a key
    if (!daysByRef[dateFormatted]){
        daysByRef[dateFormatted] =  { [cityName] : initValue };
    }

    // date in collection, but not yet this city?  create it as a key
    if (!daysByRef[dateFormatted][cityName]) {
        daysByRef[dateFormatted][cityName] = initValue;
    }
};

// Server response => format needed for page :
// Loop over responses and group by day and  city and count events and  event_type,
// Example one input record : { city_name: "Brugge" date_formatted : "2019-12-90", event_type: "target_uptdated", event_nb: 5 }
// Example grouped output :
// { "2017-09-01" : { "Brugge" : { "event_nb" : 4 },
//                    "Antwerpen" : { "event_nb" : 2 }}, ... .}
export const responseToByDay = (resp) => {
    
    if (resp.length ==  0){
        return;
    }

    const timesRelevantEvents =  resp;
    const days = {};
    timesRelevantEvents.forEach( 
        dayRecord => {
            const city = dayRecord.city_name;
            const date = dayRecord.date_formatted;

            // if the combination date/city  is not existing, initialize it
            // and add it to the 'days' collection. 
            initializeDateAndCityKeys(days,date,city);

            days[date][city].event_nb += dayRecord.event_nb;
        });
    return days;
};

export const chartTitle = (unit,$t) => {
    if (unit == TIME_UNITS.DAY){
        return $t('Overview of events per day');
    }
    return $t('Overview of events per week');
};


// takes the data grouped by day and formats it for the main chart
export const byDayToMainChartData  = (byDay, cityFilter, start, end, $t) =>  {

    const data = byDayToFilteredOnCity(byDay, cityFilter || '_all_');
    const chartData = byDayToChartData(start, end, data);

    return {
        timeUnit : chartData.timeUnit,
        data  : {
            labels : chartData.labels,

            datasets : [
                { data :  chartData.chartData,
                  backgroundColor: '#FAB500',
                },
            ],
        },
        title : chartTitle(chartData.timeUnit,$t),
    };
};


// Sums the "event_nb" props over a day
export  const  byDayToEventsSummed =  (byDay) => {
    const sums = {}; 
    Object.keys(byDay)
        .forEach(day => {
                     const event_numbers = Object.keys(byDay[day]).map(city => byDay[day][city].event_nb);
                     sums[day] = sum(event_numbers);
                 });
    return sums;
};

// takes a byDay data format, and returns the same format, but only for the given city, example conversion for brugge : 
// { "2017-09-01" : {"Brugge" : { "event_nb" : 4 }, "Antwerpen" : { "event_nb" : 2 }}, ... .}
// { "2017-09-01" : { "Brugge" : { "event_nb" : 4 } }, ... }
export const  byDayToFilteredOnCity = (byDay,city) => {
    if (city=='_all_') {
        return byDay;
    }
    const filtered = {};
    Object.keys(byDay).forEach(day => {
        const data = byDay[day]; 
        if (data[city]) {
            filtered[day] = { [city] : data[city] };
        }
    });
    return filtered; 
};



// takes the data grouped by date and grouped by city to construct data needed for the table
// both are needed since table data also shows a period chart on each row
export const byCityAndByDayToTableData = (byCityData, byDayData,  cityFilterString, start, end, allCityNames) =>  {
    if (byCityData.length==0) {
        return []; 
    }

    const cityWithDataNames = uniq(byCityData.map(c => c.city_name));
    const cityWithoutDataNames = allCityNames.filter(c => !cityWithDataNames.includes(c));
    const emptyByCityData = cityWithoutDataNames.map(c => ({ city_name: c,
                                                             event_type: 'measure_created',
                                                             event_nb:  0,
                                                             other: 0,
                                                           }));
    const citiesCombined =  emptyByCityData.concat(byCityData); 
    const citiesRelevantEvents = citiesCombined
          .filter(x => (x.city_name==cityFilterString || cityFilterString == '_all_'));

    const cityRows =  {};
    
    citiesRelevantEvents.forEach(
        city => {
            const cityName = city.city_name;
            if (!cityRows[city.city_name]) {
                cityRows[city.city_name] = { other_event : 0 } ;                     
            }
            cityRows[cityName][city.event_type] = city.event_nb;

            if (!RELEVANT_EVENTS.includes(city.event_type)) {

                cityRows[cityName].other_event   += city.event_nb;
            }

            cityRows[cityName].city_name = cityName;
            // calculating the chart-numbers for this city 
            const byDayForCity = byDayToFilteredOnCity(byDayData,cityName);
            const timeChart = byDayToChartData(start, end ,byDayForCity);
            cityRows[cityName].city_chart = JSON.stringify(timeChart.chartData);
        });
    return Object.values(cityRows);
};


export const makeLabels = (start, size, timeUnit) => {
    const multiplyer = timeUnit == TIME_UNITS.WEEK ? 7 : 1;
    return range(0,size).map (x => new Date(start + multiplyer * x * MILLISECONDS_IN_DAY ));
};


// takes data grouped by day and returns the data needed to build a chart from it
export  const byDayToChartData = (start, end, byDay, partitionSize = 0) =>  {
    let timeUnit = TIME_UNITS.DAY;
    const weekSize = 7;
    const sizeTreshold = 62;
    const numberdays = (end-start) / MILLISECONDS_IN_DAY; 
    const timestamps = [start];
    for (let d=1; d<numberdays; d++) {
        timestamps.push(start +  d  * MILLISECONDS_IN_DAY); 
    }
    
    const dates =  timestamps.map(x => tsToIsoDate(x));
    const lookupNbEventsOnDay = byDayToEventsSummed(byDay);
    let chartData  = dates.map(date => (lookupNbEventsOnDay[date] || 0));

    if (chartData.length > sizeTreshold || partitionSize)  {
        timeUnit = TIME_UNITS.WEEK;
        const psize = partitionSize  || weekSize;
        const parts = partition(psize,chartData);
        chartData = parts.map(x => x.reduce((a,b)=>a+b));

    }
    const labels = makeLabels(start, chartData.length, timeUnit);
    return { chartData, labels, timeUnit };

};




