// based on https://github.com/chartjs/Chart.js/src/scales/scale.linearbase.js
// which in turn is based on http://stackoverflow.com/questions/8506881/nice-label-algorithm-for-charts-with-minimum-ticks

const niceNum = (range, round) => {
  const exponent = Math.floor(Math.log(range) / Math.LN10);
  const fraction = range / Math.pow(10, exponent);
  let niceFraction;

  if (round) {
    if (fraction < 1.5) {
      niceFraction = 1;
    } else if (fraction < 3) {
      niceFraction = 2;
    } else if (fraction < 7) {
      niceFraction = 5;
    } else {
      niceFraction = 10;
    }
  } else if (fraction <= 1.0) {
    niceFraction = 1;
  } else if (fraction <= 2) {
    niceFraction = 2;
  } else if (fraction <= 5) {
    niceFraction = 5;
  } else {
    niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
};

export default (values, numTicks) => {
  if (values.length === 0) {
    return { niceMin: 0, niceMax: 0 };
  }

  const niceValues = values.length === 1 ? [...values, 0] : values;
  const [min, max] = [Math.min(...niceValues), Math.max(...niceValues)];
  const range = niceNum(max - min, false);
  const spacing = niceNum(range / numTicks, true);
  const niceMin = Math.floor(min / spacing) * spacing;
  const niceMax = Math.ceil(max / spacing) * spacing;

  return { niceMin, niceMax };
};
