<template>
    <div>
        <textarea
            class="textarea" 
            :class="!isValid ? 'is-danger': ''" 
            @input="handleInput" :value="value"
            @blur="handleInput"
            :placeholder="placeholder"/>
        <p v-if="!isValid" class="help is-danger">{{ message }}</p>
    </div>
</template>

<script>
import validatedFieldMixin from '@/mixins/validated-field';
export default {
    mixins: [validatedFieldMixin],
};
</script>

