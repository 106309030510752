import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import modules from './modules';
import getters from './getters';

import { SELECT_USER_GROUP } from '@/constants';
import { isProduction } from '@/utils';

Vue.use(Vuex);

const plugins = [];
if (!isProduction && process.env.LOG === true) {
  plugins.push(createLogger());
}

const store = new Vuex.Store({
  getters,
  modules,
  plugins,
});

store.subscribe(({ type, payload }) => {
  switch (type) {
    case SELECT_USER_GROUP: {
      localStorage.setItem('selectedUserGroup', payload);
      break;
    }

    case 'userGroups/FETCH/SUCCESS': {
      const { selectedUserGroup } = store.getters;

      const hasAccessToSelectedUserGroup = payload.reduce(
        (acc, { subdomain }) => acc || subdomain === selectedUserGroup,
        false,
      );

      if (!hasAccessToSelectedUserGroup) {
        localStorage.removeItem('selectedUserGroup');
        store.commit(SELECT_USER_GROUP, null);
      }

      break;
    }
  }
});

export default store;
