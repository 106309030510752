import removeCity from '../components/pages/ManageCities/RemoveCityButton';

export default $t => [
  {
    key: 'name',
    label: $t('shared.name'),
    cellDataGetter: city => city.name,
    sortDataGetter: city => city.name,
  },
  {
    key: 'delete',
    label: '',
    cellDataGetter: city => city.name,
    cellRenderer: removeCity,
    width: '30px',
  },
];