<template>
  <ChartContainer>
    <template slot="chart">
      <Chart :data="chartData" :hasSettings="true" :labels="labels" :options="options" type="pie" />
    </template>
  </ChartContainer>
</template>

<script>
import Chart from '@/components/pages/Dashboard/charts/Chart';
import ChartContainer from '@/components/pages/Dashboard/charts/ChartContainer';

import { colorBySource } from '@/config';
import { CARBON_SUMMED, VALUE } from '@/constants';
import { fractionToPercentage, parseNumber, sumByKey } from '@/utils';

import legendOptions from '@/components/pages/Dashboard/charts/legend-options';

export default {
  components: { Chart, ChartContainer },

  props: { 
    selectedYear: {
      required: true,
    }, 
  },

  methods: {
    getSortedData() {
      const { ...data } = this.lastAvailableData; // eslint-disable-line no-unused-vars
      delete data.totals;
      return Object.keys(data)
        .map((key) => [key, data[key]])
        .sort((a,b) => parseInt(b[1]) - parseInt(a[1]))
        .filter(d => d[1]);
    },
  },

  computed: {
    data() {
      return this.$store.getters[`${CARBON_SUMMED}/${VALUE}`];
    },

    title() {
      return this.$t('titleEmissionsBySource', {
        lastAvailableYear: this.selectedYear,
      });
    },

    lastAvailableData() {
      // this data comes directly from the backend
      // contains emmission data grouped by year and by sector, summed over all the cities
      const {
        summed_results_past_data: pastData, // all data that is validated by the user
        summed_results_predictions: predictedData, // all data that are predictions by the system
      } = this.data;

      // If there is past and predicted data for the same year, use the sum of the data
      //    if we dont do this, we are not taking into account cities which have no validated data in this year, which would result in an incorrect visualisation
      // Otherwise use whichever data is present
      if(pastData[this.selectedYear] && predictedData[this.selectedYear]){
        return sumByKey(pastData[this.selectedYear],predictedData[this.selectedYear]);
      }else{
        return pastData[this.selectedYear] || predictedData[this.selectedYear];
      }
    },

    sortedData() {
      return this.getSortedData();
    },

    datasets() {
      /* eslint-disable */
      return [
        {
          data: this.getSortedData().map(([label, value]) => value),
          backgroundColor: this.getSortedData().map(([label, value]) => colorBySource[label]),
        },
      ];
      /* eslint-enable */
    },

    labels() {
      const totals = this.lastAvailableData?.totals;
      return this.sortedData.map(([label, value]) => `${this.$t(label)}: ${fractionToPercentage(value / totals)}`);
    },

    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },

    options() {
      return {
        ...legendOptions,
        maintainAspectRatio: false,

        title: {
          display: true,
          text: this.title,
        },

        tooltips: {
          callbacks: {
            label: ({ datasetIndex, index }, { datasets, labels }) => {
              const label = labels[index];
              const { data } = datasets[datasetIndex];
              const value = data[index];
              return `${label} (${this.$t('tons', {
                number: parseNumber(value)(this.$i18n.locale),
              })})`;
            },
          },
        },

        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 12,
          },
          reverse: true,
          onHover: e => {
            e.target.style.cursor = 'pointer';
          },
        },
      };
    },
  },
};
</script>