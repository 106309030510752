import { prop } from 'ramda';
import api from './index';

import {
    getCoBenefits,
    add_known_measure_co_benefits,
    delete_known_measure_co_benefits,
    edit_known_measure_co_benefits,
    add_known_measure_co_benefits_indicators,
    delete_known_measure_co_benefits_indicators,
    edit_known_measure_co_benefits_indicators,
    add_known_measure_co_benefits_examples,
    delete_known_measure_co_benefits_examples,
    edit_known_measure_co_benefits_examples,
} from '@/config/endpoints';

export function fetchCoBenefits (userGroup) {
    const parseApiResponse = prop('cobenefits');
    return api(userGroup)
        .get(getCoBenefits)
        .then(parseApiResponse);
}

export function addCoBenefit(known_measure_id, co_benefit_id, rating, userGroup) {
    return api(userGroup)
        .post(add_known_measure_co_benefits, {
            known_measure_id,
            co_benefit_id,
            rating,
        }, { emulateJSON: true });
}

export function editCoBenefit(known_measure_co_benefit_id, rating, userGroup) {
    return api(userGroup)
        .post(edit_known_measure_co_benefits, {
            known_measure_co_benefit_id,
            rating,
        }, { emulateJSON: true });
}

export function deleteCoBenefit(known_measure_co_benefit_id, userGroup) {
    return api(userGroup)
        .post(delete_known_measure_co_benefits, {
            known_measure_co_benefit_id,
        }, { emulateJSON: true });
}

export function addIndicator(indicator, userGroup) {
    return api(userGroup)
        .post(add_known_measure_co_benefits_indicators, indicator, { emulateJSON: true });
}

export function editIndicator(indicator, userGroup) {
    return api(userGroup)
        .post(edit_known_measure_co_benefits_indicators, indicator, { emulateJSON: true });
}

export function deleteIndicator(indicator_id, userGroup) {
    return api(userGroup)
        .post(delete_known_measure_co_benefits_indicators, {
            id: indicator_id,
        }, { emulateJSON: true });
}

export async function addExample(example, userGroup) {
    if(example.photo_raw){
        const photo_raw = await toBase64(example.photo_raw);
        example = {...example, photo_raw};
    }
    
    return api(userGroup)
        .post(add_known_measure_co_benefits_examples, example, { emulateJSON: true });
}

export async function editExample(example, userGroup) {
    if(example.photo_raw){
        const photo_raw = await toBase64(example.photo_raw);
        example = {...example, photo_raw};
    }
    
    return api(userGroup)
        .post(edit_known_measure_co_benefits_examples, example, { emulateJSON: true });
}

export function deleteExample(example_id, userGroup) {
    return api(userGroup)
        .post(delete_known_measure_co_benefits_examples, {
            id: example_id,
        }, { emulateJSON: true });
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});