import {compose, prop, toLower} from 'ramda';

import {
  Fraction,
  NumberWithWarningWhenZero,
  Progress,
} from '@/components/shared/table-cells';
import { getLastAvailableEmissionsPerPerson } from '@/utils/city';

export default (lastAvailableYear, $t) => [
  {
    key: 'name',
    label: $t('shared.name'),
    cellDataGetter: prop('name'),
    sortDataGetter: compose(toLower,prop('name')),
  },
  {
    key: 'emissionsPerPerson',
    label: $t('shared.emissions'),
    subLabel: $t('citiesPage.emissionsSubLabel', { year: lastAvailableYear }),
    cellDataGetter: compose(e => isNaN(e) ? 'N/A' : e, getLastAvailableEmissionsPerPerson(lastAvailableYear)),
    sortDataGetter: compose(e => isNaN(e)? Infinity:e ,getLastAvailableEmissionsPerPerson(lastAvailableYear)),
    cellRenderer: Fraction,
  },
  {
    key: 'progress',
    label: $t('shared.progress'),
    subLabel: $t('shared.progressSubLabel'),
    cellDataGetter: compose(Number, prop('progress_carbon_reduction')),
    cellRenderer: Progress,
  },
  {
    key: 'actions',
    label: $t('shared.actions'),
    subLabel: $t('shared.actionsSubLabel'),
    cellDataGetter: prop('count_actions'),
    cellRenderer: NumberWithWarningWhenZero,
  },
  {
    key: 'measures',
    label: $t('shared.measures'),
    subLabel: $t('citiesPage.measuresSubLabel'),
    cellDataGetter: prop('count_measures'),
    cellRenderer: NumberWithWarningWhenZero,
  },
];
