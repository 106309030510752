<template>
    <Card :isLoading="isLoading" :src="computedImageSource">
        <a class="icon measure-card-delete-icon" slot="image-content" v-if="!readonly"
            @click="$emit('delete', known_measure_id)">
            <i class="fas fa-trash" />
        </a>

        <h5 class="title is-5">{{ name }}</h5>
        <p>{{ description_short }}</p>
    </Card>
</template>

<script>
import knownMeasureMixin from '@/mixins/known-measure';

export default {
    mixins: [knownMeasureMixin],
    props: ['readonly'],
};
</script>
