<template>
<slide-transition>
    <Loading v-if="isLoading" />
    <div v-else class="actions-table">
        <div class="flex justify-end mb-4">
            <SearchField @change="filter = $event" />
        </div>
        <MultiSelectTable
            :config="config"
            :data="actions"
            :multiselect="true"
            :selection="selection"
            :filter="filter"
            :filterBy="'name'"
            :sortByKey="'name'"
            :sortDirection="'ASC'"
            :updateSort="() => {}"
            :isRowClickable="() => false"
            :showHeader="false">

            <h2 class="table--no-content" slot="empty-table">{{ $t("No published actions found") }}</h2>

        </MultiSelectTable>
    </div>
</slide-transition>
</template>

<script>
import { fetchKnownActionsList } from '@/api/known-action';
import getConfig from '@/config/known-actions';

import MultiSelectTable from '@/components/shared/form/MultiSelectTable';
import Loading from '@/components/shared/Loading';
import { SlideTransition } from '@/components/shared/transitions';

export default {
    props: ['selection'],
    components: { MultiSelectTable, Loading, SlideTransition },

    data: () => ({
        isLoading: true,
        actions: [],
        filter: '',
    }),

    computed: {
        config () {
            return getConfig(this.$t).slice(0, 2);
        },
    },
    
    created () {
        const selectedUserGroup = this.$store.getters.selectedUserGroup;
        fetchKnownActionsList(selectedUserGroup)
        .then(data => {
            this.actions = data.filter(ac => ac.available);
            this.isLoading = false;
        });
    },
};
</script>

<style lang="scss">
.actions-table {
    max-height: 400px;
    overflow-y: auto;
}
</style>
