<template>
  <PageContainer>
    <Loading v-if="isLoading" />

    <Error v-if="error" :error="error" />
  </PageContainer>
</template>

<script>
import api from '@/api';
import Vue from 'vue';

import { claimInvite } from '@/config/endpoints';

export default {
  data() {
    return {
      error: null,
      isLoading: true,
    };
  },

  created() {
    Vue.prototype.$auth.handleAuthentication().then(() => {
      const stringifiedRouteParams = sessionStorage.getItem(
        'claimInviteRouteParams',
      );

      const { id, secret, userGroup } = JSON.parse(stringifiedRouteParams);

      api()
        .post(
          claimInvite,
          {
            claimed_user_id: id,
            claimed_subdomain: userGroup,
            invite_secret: secret,
          },
          { emulateJSON: true },
        )
        .then(() => this.$router.replace('/'))
        .catch(error => {
          this.isLoading = false;
          this.error = error;
        });
    });
  },
};
</script>

