export const TIME_UNITS = { DAY : 'DAY', WEEK : 'WEEK',  MONTH : 'MONTH'  };
export const MILLISECONDS_IN_DAY = 3600*24*1000;

export const tsToIsoDate = ts => new Date(ts).toISOString().substring(0,10);

export const tsPlusWeek = (ts) => new Date(ts + 7* MILLISECONDS_IN_DAY);

export const nowMinusXmonths = x => {
    const d = new Date();
    d.setMonth(d.getMonth() - x);
    return d;
};

