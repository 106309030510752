export const userGroups = '/api/citygroups/accessible_groups';
export const actions = '/api/citygroups/actions/list';
export const activity = '/api/citygroups/activity/list';
export const activityOverTime = '/api/citygroups/activity/time'; 
export const carbonOverview = '/api/citygroups/carbon/overview';
export const carbonSummed = '/api/citygroups/carbon/summed';
export const getCityDetail = name =>
  `/api/citygroups/cities/${name}/measures/list`;
export const groupInfo = '/api/citygroups/info';
export const measures = '/api/citygroups/known_measures/list';
export const getMeasureDetail = id =>
  `/api/citygroups/known_measures/${id}/detail`;
export const geoJson = '/api/geo-json';
export const getUsers = '/api/citygroups/users/list';
export const inviteUsers = 'api/citygroups/users/invite';
export const resendUserInvitation = 'api/citygroups/users/resend_invitation';
export const claimInvite = 'api/citygroups/claim_invite';

// known measures
export const KNOWN_MEASURES = '/api/citygroups/my_library/known_measures';
export const known_measures = `${KNOWN_MEASURES}/list`;
export const get_known_measure = known_measure_id => 
  `${KNOWN_MEASURES}/${known_measure_id}/detail`;
export const update_known_measure = `${KNOWN_MEASURES}/edit`;
export const create_known_measure  = `${KNOWN_MEASURES}/add`;
export const delete_known_measure = `${KNOWN_MEASURES}/delete`;
export const upload_known_measure_photo = `${KNOWN_MEASURES}/uploadphoto`;
export const set_known_measure_co_benefits = `${KNOWN_MEASURES}/cobenefits`;
export const add_known_measure_co_benefits = `${KNOWN_MEASURES}/cobenefits/add`;
export const edit_known_measure_co_benefits = `${KNOWN_MEASURES}/cobenefits/edit`;
export const delete_known_measure_co_benefits = `${KNOWN_MEASURES}/cobenefits/delete`;
export const add_known_measure_co_benefits_indicators = `${KNOWN_MEASURES}/cobenefits/indicators/add`;
export const edit_known_measure_co_benefits_indicators = `${KNOWN_MEASURES}/cobenefits/indicators/edit`;
export const delete_known_measure_co_benefits_indicators = `${KNOWN_MEASURES}/cobenefits/indicators/delete`;
export const add_known_measure_co_benefits_examples = `${KNOWN_MEASURES}/cobenefits/examples/add`;
export const edit_known_measure_co_benefits_examples = `${KNOWN_MEASURES}/cobenefits/examples/edit`;
export const delete_known_measure_co_benefits_examples = `${KNOWN_MEASURES}/cobenefits/examples/delete`;
export const set_known_measure_risks = `${KNOWN_MEASURES}/risks`;
export const set_known_measure_suggested_to = `${KNOWN_MEASURES}/suggested_to`;
export const add_known_measure_derived_unit = `${KNOWN_MEASURES}/derived_units/add`;
export const edit_known_measure_derived_unit = `${KNOWN_MEASURES}/derived_units/edit`;
export const delete_known_measure_derived_unit = `${KNOWN_MEASURES}/derived_units/delete`;

// known actions
export const KNOWN_ACTIONS = '/api/citygroups/my_library/known_actions';
export const known_actions = `${KNOWN_ACTIONS}/list`;
export const get_known_action = known_action_id => 
  `${KNOWN_ACTIONS}/${known_action_id}/detail`;
export const update_known_action = `${KNOWN_ACTIONS}/edit`;
export const create_known_action = `${KNOWN_ACTIONS}/add`;
export const delete_known_action = `${KNOWN_ACTIONS}/delete`;
export const upload_known_action_photo = `${KNOWN_ACTIONS}/uploadphoto`;

// communication
export const send_mails       = '/api/citygroups/communication/sendEmailToCityOfficial';
export const send_sample_mail = '/api/citygroups/communication/sendSampleEmail';
export const city_officials   = '/api/citygroups/communication/city_officials/list';

// translations
export const TRANSLATIONS = '/api/citygroups/my_library/translations';
export const add_translation = `${TRANSLATIONS}/add`;
export const delete_translation = `${TRANSLATIONS}/delete`;
export const fetch_translations = ({ object_type, object_id }) => `${TRANSLATIONS}/object_types/${object_type}/${object_id}/list`;

// co benefits
export const getCoBenefits = '/api/citygroups/my_library/cobenefits/list';

// manage cities
export const search_cities = 'api/citygroups/cities/search';
export const invite_city = 'api/citygroups/cities/invite';
export const delete_city = 'api/citygroups/cities/remove';

// util
export const open_graph_scraper = '/api/open-graph-scraper';

// miti sectors
export const list_sectors = '/api/citygroups/sectors/list';