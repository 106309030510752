<template>
  <div class="relative flex justify-center" :style="{ height: '20px' }">
    <progress
      class="progress is-medium is-primary absolute"
      :style="{ left: 0 }"
      :value="value || 0" max="100"
      :title="(value / 100) | fractionToPercentage" />
    
    <span v-if="label" class="text-xs pl-4 pr-4" :style="{ whiteSpace: 'nowrap', zIndex: 1 }">
      {{ label }}
    </span>

    <div class="progress-overlay" v-if="value > 100" />
  </div>
</template>

<script>
import { fractionToPercentage } from '@/utils';

export default {
  props: ['label', 'value'],
  filters: { fractionToPercentage },
};
</script>

<style lang="scss">
.progress-overlay {
  background: repeating-linear-gradient(
    90deg,
    #fab500,
    #fab500 5px,
    white 0,
    white 8px
  );
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
}
</style>
