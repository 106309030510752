<template>
    <Tooltip class="icon" :tooltip="$t(value === 1 ? 'Published': 'Not published')">
        <i class="status-is-available fas fa-eye" v-if="value"/>
        <i class="status-not-available fas fa-eye-slash" v-else/>
    </Tooltip>
</template>

<script>
export default {
    props: ['value'],
};
</script>
