<template>
    <div class="select" :class="isFullWidth ? 'is-fullwidth': ''">
        <select
            v-model="selectedOption"
            :disabled="disabled"
        >
            <option v-if="staticOption" disabled selected value>{{ staticOption }}</option>
            <option v-for="option in options" 
                :key="option.value"
                :value="optionValue(option)">
                {{ labelGetter ? labelGetter(option) : option.value }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: [
        'value',
        'isFullWidth',
        'options',
        'labelGetter',
        'staticOption',
        'disabled',
        'returnFullObject',
    ],
    computed: {
        selectedOption: {
            get() {
                if (this.returnFullObject) return this.value;
                return this.value;
            },
            set(val) {
                if (this.returnFullObject) this.$emit('input', val);
                else this.$emit('input', val);
            },
        },
    },
    methods: {
        optionValue(option) {
            if (this.returnFullObject) return option;
            
            return option.value;
        },
    },
};
</script>
