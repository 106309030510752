import { prop } from 'ramda';

import { carbonOverview as endpoint } from '@/config/endpoints';
import { hasPastDataInYear, isValid } from '@/utils/city';
import { makeModuleAsync } from '@/utils';

const parseApiResponse = prop('cities');

const getFractionOfCitiesWithPastDataInYear = function(cities,year){
  return cities.filter(hasPastDataInYear(year)).length/cities.length;
};

const module = {
  getters: {
    // returns the most recent year for which at least 60% have validated data
    // if no such year exists it returns the year in which the most cities have validated data
    lastAvailableYear({ value: cities }) {
      if (cities == null) {
        return null;
      }

      if (cities.length == 0){
        return 0;
      }

      // Fraction of cities that need to have validated data in the returned year
      const TRESHOLD = 0.5;
      // This constant defines the maximum amount of years that we look back in time
      // For example if the most recent year wich passes the threshold is 2005, it would still be a bad representation of the current situation
      // thus we limit how far we look back in time
      const MAXS_YEARS_BEFORE_CURENT = 9; 

      const currentYear = new Date().getFullYear();

      // create an array containing all past years untill MAXS_YEARS_BEFORE_CURENT in descending order
      const lastYears = Array(MAXS_YEARS_BEFORE_CURENT)
                          .fill(currentYear)
                          .map((currentYear, index) => currentYear - index);
     
      // find the most recent year for which the treshold is passed
      let validatedYear = lastYears.find( year => getFractionOfCitiesWithPastDataInYear(cities,year)>TRESHOLD);

      // If no year passes the treshold return the year where most cities have validated data
      if(!validatedYear){
        validatedYear = lastYears.reduce( (max_year, year) => {
          if(getFractionOfCitiesWithPastDataInYear(cities,max_year) > getFractionOfCitiesWithPastDataInYear(cities,year)){ 
            return max_year;
          } else {
            return year; 
          }
        });
      }
      return validatedYear;
    },

    validCities({ value: cities = [] }, { lastAvailableYear }) {
      return cities.filter(isValid(lastAvailableYear));
    },

    cities({ value: cities }) {
      return cities;
    },
  },
};

export default makeModuleAsync({ endpoint, module, parseApiResponse });
