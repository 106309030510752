// based on https://auth0.com/docs/quickstart/spa/vuejs

import auth0 from 'auth0-js';

import router from '@/router';
import store from '@/store';

import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  CALLBACK_PATH,
  LOGIN_PATH,
} from '@/config';
import { UPDATE_AUTH } from '@/constants';

const callbackUri = `${window.location.origin}${CALLBACK_PATH}`;

export default class AuthService {
  auth0 = new auth0.WebAuth({
    clientID: AUTH0_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    responseType: 'token id_token',
    realm: 'Username-Password-Authentication',
  });

  handleAuthentication() {
    return new Promise(resolve => {
      this.auth0.parseHash((error, authResult) => {
        if (error) {
          return this.handleError(error);
        }

        if (authResult == null) {
          return router.replace(LOGIN_PATH);
        }

        this.setSession(authResult);

        resolve();
      });
    });
  }

  loginWithEmail = (
    credentials,
    redirectUri = callbackUri,
    callback = this.handleError,
  ) => {
    this.auth0.login(
      {
        ...credentials,
        redirectUri,
      },
      callback,
    );
  };

  loginWithGoogle = callback => {
    this.auth0.authorize(
      {
        connection: 'google-oauth2',
        callbackUri,
      },
      callback,
    );
  };

  logOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    router.replace(LOGIN_PATH);

    store.commit('groupInfo/RESET');
    store.commit(UPDATE_AUTH);
    window.Intercom('shutdown');
  };

  handleError = error => {
    store.commit(UPDATE_AUTH, error);
    router.replace(LOGIN_PATH);
  };

  resetPassword = (email = store.getters.getUserProfile, callback) => {
    this.auth0.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email,
      },
      callback,
    );
  };

  setSession = ({ accessToken, expiresIn, idToken }) => {
    const expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());

    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('expires_at', expiresAt);

    store.commit(UPDATE_AUTH);
  };

  signUp = (credentials, callback) => {
    this.auth0.signup(
      {
        ...credentials,
        connection: 'Username-Password-Authentication',
      },
      callback,
    );
  };
}
