<template>
    <div @click.stop class="checkbox-container multiselect" 
        :class="{ 'show-on-hover': (rows && selection.length == 0) || row }">
        <input type="checkbox" class="checkbox"
            :id="computedId"
            v-model="status"

            @change="toggle(row)" />
        <label :for="computedId" />
    </div>
</template>

<script>
export default {
    props: {
        rowId: String,
        selection: { type: Array, required: true },
        row: Object,
        rows: Array,
    },
    data () {
        return { status: false };
    },
    created () {
        if (this.row && this.selection.indexOf(this.row) > -1) {
            this.status = true;
        }
    },
    computed: {
        computedId () {
            return this.rows
                ? 'select_all_cbox'
                : `cbox_${this.row[this.rowId || 'id']}`;
        },
    },
    methods: {
        toggle (row) {
            if (this.rows) {
                const newSelection = this.status ? this.rows : [];
                this.selection.splice(0, this.selection.length, ...newSelection);
            }
            if (row) {
                if (this.status) {
                    this.selection.push(this.row);
                } else {
                    const pos = this.selection.indexOf(this.row);
                    this.selection.splice(pos, 1);
                }
            }
        },
    },
    watch: {
        selection (newSelection) {
            if (this.row) {
                this.status = newSelection.indexOf(this.row) > -1;
                return;
            }
            if (this.rows) {
                this.status = this.rows.length === newSelection.length;
            }
        },
    },
};
</script>