import { FETCH, USER_GROUPS } from '@/constants';

export default {
  data() {
    return {
      error: null,
    };
  },

  computed: {
    isLoading() {
      return (
        this.$store.getters['groupInfo/isFetching'] ||
        this.$store.getters['userGroups/isFetching']
      );
    },
  },

  created() {
    this.$store
      .dispatch(`${USER_GROUPS}/${FETCH}`)
      .then(() => this.$store.dispatch('selectDefaultUserGroup'))
      .catch(error => (this.error = error));
  },
};
