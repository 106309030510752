<template>
  <div class="box inline-block" :style="{ padding: '0.75rem' }">
    <table class="table is-narrow text-sm no-borders">
      <tbody>
        <tr v-for="(breakpoint, index) in config.breakpoints" :key="index">
          <td :style="{ verticalAlign: 'middle' }">
            <div :style="{width: '16px', height: '16px', backgroundColor: config.getColor(breakpoint + 1)}" />
          </td>

          <td class="text-center">
            {{ `${labels[index][1] === '' ? '&ge;' : ''}${labels[index][0]}` }}
          </td>
          <td><span v-if="labels[index].indexOf('') === -1">&mdash;</span></td>
          <td class="text-center">
            {{ `${labels[index][0] === '' ? '&le;' : ''}${labels[index][1]}` }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { compactInteger } from 'humanize-plus';

export default {
  props: ['config'],

  computed: {
    labels() {
      const { breakpoints } = this.config;

      return breakpoints.map(this.getLabels);
    },
  },

  methods: {
    getLabels(breakpoint, index) {
      const { breakpoints } = this.config;

      const min = this.stringifyBreakpoint(breakpoint);
      const max = this.stringifyBreakpoint(breakpoints[index + 1]);

      return [min, max];
    },

    stringifyBreakpoint(breakpoint) {
      if (breakpoint == null || Math.abs(breakpoint) === Infinity) {
        return '';
      }

      const { stringifyData, unit } = this.config;
      const value = compactInteger(breakpoint);

      if (!stringifyData) {
        return value;
      }

      const stringifiedData = stringifyData(value, this.$i18n.locale);

      return unit
        ? this.$t(unit, { number: stringifiedData })
        : stringifiedData;
    },
  },
};
</script>

