import { prop } from 'ramda';

export default function getConfig ($t) {
    return [
        {
            key: 'city',
            label: $t('municipality'),
            cellDataGetter: prop('city'),
        },
        {
            key: 'name',
            label: $t('name'),
            cellDataGetter: prop('name'),
        },
        {
            key: 'email',
            label: $t('email'),
            cellDataGetter: prop('email'),
        },
    ];
}