<template>
  <PageContainer :isLoading="isLoading" :error="error">
    <Dashboard />
  </PageContainer>
</template>

<script>
import Dashboard from './Dashboard';

import { fetchUser } from '@/mixins';

export default {
  mixins: [fetchUser],

  components: { Dashboard },
};
</script>
