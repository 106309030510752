<template>
    <div>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_example_source_link")}}</label>
            <input type="text"  class="input mb-4" :value="value.source_link" @input="updateSourceLink($event.target.value)">
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_example_photo")}}</label>
            <div
                    class="has-bg-image"
                    :style="{
                      width: '180px',
                      height: '130px',
                      backgroundImage: `url(${photo_src})`
                    }"
                    v-if="photo_src"
            >
                <button class="delete is-pulled-right" @click.stop="removePhoto"/>
            </div>
            <input type="file"  accept="image/*"  class="input mb-4" :value="value.photo_raw" @input="update('photo_raw', $event.target.files[0])" v-else/>
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_example_title")}}</label>
            <input type="text"  class="input mb-4" :value="value.title" @input="update('title', $event.target.value)"/>
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_example_description")}}</label>
            <textarea  class="textarea mb-4" :value="value.description" @input="update('description', $event.target.value)"/>
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_example_source_name")}}</label>
            <input type="text"  class="input" :value="value.source_name" @input="update('source_name', $event.target.value)">
        </Field>
    </div>
</template>
<script>
    import {fetchOpenGraphData} from '@/api/util';

    export default {
        props: {
            value: {
                type: Object,
                default: () => ({}),
            },
        },
        watch:{
            is_valid(){
                this.$emit('invalidate', this.is_valid);
            },
        },
        computed:{
            is_empty(){
                return !this.value.description && !this.value.source_name && !this.value.source_link && !this.value.title && !this.value.photo_url && !this.value.photo && !this.value.photo_raw;
            },
            is_valid(){
                return (this.value.description && this.value.source_name && this.value.source_link && this.value.title && (this.value.photo_url || this.value.photo || this.value.photo_raw)) ||
                    this.is_empty;
            },
            photo_src(){
                return this.value.photo || this.value.photo_url || (this.value.photo_raw && URL.createObjectURL(this.value.photo_raw));
            },
        },
        methods: {
            updateSourceLink(link){
                this.update('source_link',link);
                fetchOpenGraphData(link).then((result) => {
                    let v = {...this.value};
                    if(result.title){
                        v.title = result.title;
                    }
                    if(result.description){
                        v.description = result.description;
                    }
                    if(result.site_name){
                        v.source_name = result.site_name;
                    }
                    if(result.image && result.image.url){
                        v.photo_url = result.image.url;
                    }
                    this.$emit('input', v);
                });
            },
            removePhoto(){
                let v = {...this.value};
                v.photo = '';
                v.photo_url = '';
                v.photo_raw = '';
                this.$emit('input', v);
            },
            update(key, value) {
                this.$emit('input', { ...this.value, [key]: value });
            },
        },
    };
</script>