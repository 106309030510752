<template>
    <span>
        <i 
                class="fa-star" 
                :class="{fas: stars >= i, far: stars < i, 'has-text-grey': preview_value, 'icon-clickable': !disabled}" 
                v-for="i in max" 
                :key="i" 
                @click="!disabled && handleClick(i)"
                @mouseover="!disabled && mouseOver(i)"
                @mouseleave="!disabled && mouseLeave()"
                
        />
    </span>
</template>

<script>
    export default {
        data: () =>({
            preview_value: null,
        }),
        props: {
            value: Number,
            max: {
                type: Number,
                default: 3,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            handleClick(i){
                this.$emit('input',i);
            },
            mouseOver(i){
                this.preview_value = i;
            },
            mouseLeave(){
                this.preview_value = null;
            },
        },
        computed:{
            stars(){
                return this.preview_value || this.value;
            },
        },
        
    };
</script>