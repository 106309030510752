export default {
  legend: {
    onHover: e => {
      e.target.style.cursor = 'pointer';
    },
  },

  hover: {
    onHover: function(e) {
      const point = this.getElementAtEvent(e);
      e.target.style.cursor = point.length ? 'pointer' : 'default';
    },
  },
};
