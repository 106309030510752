export const CARBON_SUMMED = 'carbonSummed';
export const CITIES = 'cities';
export const ACTIONS = 'actions';
export const GEO_JSON = 'geoJson';
export const GROUP_INFO = 'groupInfo';
export const MEASURES = 'measures';
export const USER_GROUPS = 'userGroups';
export const USERS = 'users';
export const KNOWN_MEASURES = 'knownMeasures';
export const KNOWN_MEASURE = 'knownMeasure';
export const KNOWN_ACTIONS = 'knownActions';
export const KNOWN_ACTION = 'knownAction';

export const IS_FETCHING = 'isFetching';
export const HAS_FETCHED = 'hasFetched';
export const ERROR = 'error';
export const VALUE = 'value';

export const SELECT_MAP_VIEW = 'SELECT_MAP_VIEW';
export const SELECT_USER_GROUP = 'SELECT_USER_GROUP';
export const SHOW_MODAL = 'SHOW_MODAL';
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_SORT = 'UPDATE_SORT';

export const FETCH = 'FETCH';
export const RESET = 'RESET';
export const SELECT = 'SELECT';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
