<template>
<Modal @closeModal="closeModal">
    <div class="box">
        <div class="content">
            <h4>{{ $t('Change group') }}</h4>
            <hr />
            <div class="content">
                <div class="select">
                        <select @change="handleChangeAccount($event.target.value)">
                            <option v-for="{ name, subdomain } in availableUserGroups"
                                :key="subdomain"
                                :value="subdomain"
                                :selected="subdomain === selectedUserGroup">
                                {{ name }}
                            </option>
                        </select>
                    </div>
                <!-- <FieldVertical>
                    <label class="label" slot="label">{{ $t('Choose region') }}</label>
                    
                </FieldVertical> -->
            </div>
        </div>
    </div>
</Modal>
</template>

<script>
export default {
    computed: {
        availableUserGroups() {
            return this.$store.getters['userGroups/value'];
        },

        selectedUserGroup() {
            return this.$store.getters.selectedUserGroup;
        },
    },

    methods: {
        handleChangeAccount(userGroup) {
            this.$store.dispatch('selectUserGroup', userGroup);
            this.closeModal();
        },

        closeModal () {
            this.$emit('closeModal');
        },
    },
};
</script>