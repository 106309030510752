<template>
  <PageContainer  :isLoading="isLoading" :error="error">
    <MeasuresContainer />
  </PageContainer>
</template>

<script>
import MeasuresContainer from './MeasuresContainer';

import { fetchUser } from '@/mixins';

export default {
  mixins: [fetchUser],

  components: { MeasuresContainer },
};
</script>
