<template>
  <Loading />
</template>

<script>
import Vue from 'vue';

export default {
  created() {
    Vue.prototype.$auth
      .handleAuthentication()
      .then(() => this.$router.replace('/'));
  },
};
</script>

