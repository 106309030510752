<template>
  <TablePageContainer
    :config="config"
    :data="data"
    :isLoading="isLoading"
    :sortState="sortState"
    :title="title"
    :updateSort="updateSort"
    :onRowClick="handleOnRowClick" />
</template>

<script>
import TablePageContainer from '@/components/shared/TablePageContainer';

import getConfig from '@/config/cities';
import {
  CITIES,
  FETCH,
  GROUP_INFO,
  IS_FETCHING,
  UPDATE_SORT,
} from '@/constants';

export default {
  components: { TablePageContainer },

  created() {
    this.$store.dispatch(`${CITIES}/${FETCH}`);
  },

  computed: {
    config() {
      const lastAvailableYear = this.$store.getters[
        `${CITIES}/lastAvailableYear`
      ];

      return getConfig(lastAvailableYear, this.$t);
    },

    data() {
      return this.$store.getters[`${CITIES}/cities`];
    },

    title() {
      const groupName = this.$store.getters[`${GROUP_INFO}/name`];

      return this.$t('citiesPage.pageTitle', { groupName });
    },

    isLoading() {
      return this.$store.getters[`${CITIES}/${IS_FETCHING}`];
    },

    sortState() {
      return this.$store.getters[`${CITIES}/sortState`];
    },
  },

  methods: {
    handleOnRowClick({ name }) {
      this.$router.push({ name: 'cityDetail', params: { name } });
    },

    updateSort(key) {
      this.$store.commit(`${CITIES}/${UPDATE_SORT}`, key);
    },
  },
};
</script>

