<template>
<div>
  <Dropdown
    class="is-right"
    ref="periodDropdown"
    :preventSetInactive="true"
    :isActiveDefault="datePickerActive"
    >
    <template slot="trigger">
      <button class="button max-w-full">
        <div class="max-w-full truncate">
          {{ periodString() }}
        </div>
      </button>
    </template>
    
    <div class="flex flex-col justify-end is-right"  @click.stop>


      <div  class="flex  flex-col justify-between p-2">
    <div>
    <input type="radio" class="radio" id="1m" name="status"  value="1" @click="setPeriod">
        <label class="pl-2 cursor-pointer" for="1m">{{ $t('Last month') }}</label>
    </div>
    <div>
      <input type="radio" class="radio" id="3m" name="status"  value="3" @click="setPeriod">
      <label class="pl-2 cursor-pointer" for="3m">{{ $t('Last three months') }}</label>
    </div>
    <div>
        <input type="radio" class="radio" id="12m" name="status"   value="12" @click="setPeriod">
        <label class="pl-2 cursor-pointer" for="12m">{{ $t('Last year') }}</label>
    </div>
      </div>
      
      <div class="flex justify-between p-2" @click.stop>
        <label for="pickStart" class="mr-4">{{this.$t('From')}}</label>
        <Datepicker :value="start_" @onChange="setStart" />
      </div>
      
      <div class="flex justify-between p-2">
        <label for="pickStart" class="mr-4">{{this.$t('To')}}</label>
        <Datepicker :value="end_" @onChange="setEnd" />
      </div>
      
      <div class="p-2">
        <button class="button is-success is-rounded is-right" style="float:right"
                @click="handleConfirm()">{{this.$t('Apply')}}
        </button>
      </div>
    </div>
  </Dropdown>
</div>
</template>

<script>

import Dropdown from '@/components/shared/Dropdown';
import Datepicker from '@/components/shared/Datepicker';
import { nowMinusXmonths } from '@/utils/date';

export default {

    props: {
        'start': { default: nowMinusXmonths(1) },
        'end' : { default: new Date().getTime()},
    },

    components: { Dropdown, Datepicker},

    data() { return {
        test: new Date(),
        datePickerActive: false,
        start_ : this.start,
        end_ : this.end, 
    };
           },

    methods: {

        setPeriod(e) {
            this.start_ = nowMinusXmonths(e.target.value).getTime();
            this.end_ =  new Date().getTime();
            this.$emit('confirm', [this.start_,this.end_]);
            this.$refs.periodDropdown.setInactive();
        },
        
        setStart(payload) {
            this.start_ = Date.parse(payload[0]);
        },

        setEnd(payload) {
            this.end_ = Date.parse(payload[0]);
        },

        handleConfirm() {
            this.$refs.periodDropdown.setInactive();
            this.$emit('confirm', [this.start_,this.end_]);
        },

        periodString() {
            return this.$t('shared.Period') + ': ' +
                new Date(this.start_).toLocaleDateString() +
                ' - ' +
                new Date(this.end_).toLocaleDateString();
        },

        handleWindowClick(e) {
            // Parts of the flatpickr calendar are added dynamically under the body-root, escaping the stopPropagation directives
            // of its containers.  This is one of many somewhat hackish ways to get around that : if sth. else then the calender is
            // clicked, we hide it.
            const clickedOnCalendar = e.path.map(x => x.className).join (',').includes('flatpickr-');
            if (!clickedOnCalendar) {
                this.$refs.periodDropdown.setInactive();
            }
        },
    },

    mounted() {
        window.addEventListener('click', this.handleWindowClick);
    },

    beforeDestroy() {
        window.removeEventListener('click', this.handleWindowClick);
    },

}; 
</script>
