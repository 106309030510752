<template>
  <table :class="`table is-fullwidth is-hoverable ${onRowClick ? 'is-clickable' : ''}`">
    <thead v-if="showHeader !== undefined ? showHeader : true">
      <tr>
        <th
          v-for="({ label, key, subLabel, title }) in config"
          :key="key"
          :title="title || label">
          <div class="inline-flex items-center cursor-pointer text-sm" @click="updateSort(key)">

            <span class="uppercase" :class="headerClasses">{{ label }}</span>

            <svg width="18" height="18" viewBox="0 0 24 24">
              <path :d="sortDirection === 'DESC' ? 'M7 14l5-5 5 5z' : 'M7 10l5 5 5-5z'" v-if="sortByKey === key" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>

          <div class="text-sm has-text-grey-light font-normal" v-if="subLabel" :style="{ whiteSpace: 'normal' }">
            {{ subLabel }}
          </div>
        </th>
      </tr>
    </thead>

    <tbody v-if="sortedData.length > 0">
      <tr 
        v-for="row in sortedData"
        :key="row[rowId || 'id']"
        @click="handleOnClick(row)"
        :class="isRowClickable(row) ? 'clickable' : ''">
        <td v-for="{ key, cellDataGetter = () => null, cellRenderer = 'div', width = 'auto' } in config" :key="key" :style="{ width }">
          <component
            :is="cellRenderer"
            v-bind="typeof cellDataGetter(row) === 'object' ? cellDataGetter(row) : { value: cellDataGetter(row) }">
            {{ cellDataGetter(row) }} 
          </component>
        </td>
      </tr>
    </tbody>

    <tbody v-else>
      <tr>
        <td :colspan="config.length">
          <slot name="empty-table">
            <h2 class="table--no-content">{{ $t("No content available") }}</h2>
          </slot>
        </td>
        
      </tr>
    </tbody>
  </table>
</template>

<script>
 import { compose, contains, prop, reverse, sortBy, toLower } from 'ramda';
 import { findInArrayByPropEnforcing } from '@/utils';
 export default {
     props: {
         'config' : { default : [] },
         'data' : { default : [] },
         'filter' : { default : '' },
         'filterBy' : { default : false },
         'headerClasses' : { default : ''},
         'isRowClickable' : {
             type: Function, 
             default : () => false,
         },
         'onRowClick' :  { default : false },
         'rowId' : { default : ''}, 
         'sortByKey': { default : '' },
         'sortDirection' : { default : 'ASC' },
         'updateSort' : {default : null },
         'showHeader' : { default : true },
     },

     computed: {
         filteredData() {
             if (!this.filterBy) {
                 return this.data;
             }
             const isHit = compose(
                 contains(toLower(this.filter)),
                 toLower,
                 prop(this.filterBy),
             );
             return this.data.filter(isHit);
         },
         sortedData() {
             const { cellDataGetter, sortDataGetter } = findInArrayByPropEnforcing(
                 this.config,
                 'key',
                 this.sortByKey,
             );
             const sortedData = sortBy(
                 sortDataGetter || cellDataGetter,
                 this.filteredData,
             );
             return this.sortDirection === 'DESC' ? reverse(sortedData) : sortedData;
         },
     },
     methods: {
         handleOnClick(row) {
             if (this.onRowClick && this.isRowClickable(row)) {
                 this.onRowClick(row);
             }
         },
     },
 };
</script>

<style lang="scss" scoped>
.table.is-clickable tbody tr.clickable {
  cursor: pointer;
}
th {
  white-space: nowrap;
}
</style>
