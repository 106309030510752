<template>
  <PageContainer>
    <div class="section flex flex-col flex-grow justify-center items-center">
      <div class="notification is-primary" v-if="isSuccess">
        Registration successful.
      </div>

      <div class="content">
        <div class="card">
          <div class="card-content">
            <h3 class="font-normal">{{ $t("signUpDescription") }}</h3>

            <Error v-if="error" :error="error" />

            <Loading v-if="isLoading" />

            <form class="form" @submit="handleSubmit">
              <div class="field">
                <label class="label font-bold">{{ $t("Email address") }}</label>

                <div class="control">
                  <input
                    class="input"
                    type="email"
                    :placeholder="$t('Email address')"
                    v-model="formData.email"
                    required>
                </div>
              </div>

              <div class="field">
                <label class="label font-bold">{{ $t("Password") }}</label>

                <div class="control">
                  <input
                    class="input"
                    type="password"
                    :placeholder="$t('Password')"
                    v-model="formData.password"
                    required>
                </div>
              </div>

              <div class="field">
                <label class="label font-bold">{{ $t("verifyPassword") }}</label>

                <div class="control">
                  <input
                    class="input"
                    type="password"
                    :placeholder="$t('Password')"
                    v-model="verifyPassword"
                    required>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <button class="button is-primary w-full">{{ $t("signUp") }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
</template>

<script>
import Vue from 'vue';

import { CLAIM_INVITE_PATH } from '@/config';

const callbackUri = `${window.location.origin}${CLAIM_INVITE_PATH}`;

export default {
  data() {
    return {
      error: null,
      formData: {
        email: '',
        password: '',
      },
      isLoading: false,
      isSuccess: false,
      verifyPassword: '',
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      if (this.formData.password !== this.verifyPassword) {
        this.error = this.$t('passwordsNotEqual');
        return;
      }

      this.isLoading = true;

      Vue.prototype.$auth.signUp(this.formData, () => {
        this.isLoading = false;

        sessionStorage.setItem(
          'claimInviteRouteParams',
          JSON.stringify(this.$route.params),
        );

        return Vue.prototype.$auth.loginWithEmail(this.formData, callbackUri);
      });
    },
  },
};
</script>
