<template>
  <ChartContainer>
    <template slot="chart">
      <Chart
        :data="chartData"
        :hasSettings="true"
        :options="options"
        :perPerson="showDataPerPerson"
        type="bar" />
    </template>

    <template slot="modal-content">
      <div class="content">
        <h4>{{ title }}</h4>
      </div>

      <hr />

      <div class="field">
        <input type="checkbox" id="togglePerPerson" class="switch" v-model="showDataPerPerson" />
        <label for="togglePerPerson">{{ $t('dashboardPage.showDataPerPerson') }}</label>
      </div>
    </template>
  </ChartContainer>
</template>

<script>
import Chart from '@/components/pages/Dashboard/charts/Chart';
import ChartContainer from '@/components/pages/Dashboard/charts/ChartContainer';

import { CARBON_SUMMED, VALUE } from '@/constants';
import { ORANGE, MAIN_APP_COLOR, MAIN_APP_COLOR_LIGHT } from '@/config/colors';
import { parseNumber, stringifyNumber } from '@/utils';

import legendOptions from '@/components/pages/Dashboard/charts/legend-options';

export default {
  components: { Chart, ChartContainer },

  data() {
    return {
      showDataPerPerson: false,
    };
  },

  props: {
    fromYear: {
      required: true,
    },
    toYear: {
      required: true,
    },
  },

  methods: {
    getValues(data) {
      return this.labels.map( year => data[year] );
    },

    extractTotals(data) {
      return this.labels.map(year => data[year] !== undefined ? data[year]['totals'] : undefined);
    },
  },

  computed: {
    endYears(){
      const from = 2020;
      const to = 2050;
      
      return [...Array(to - from).keys()].map(i => i + from);
    },
  
    data() {
      return this.$store.getters[`${CARBON_SUMMED}/${VALUE}`];
    },

    population() {
      const { population } = this.data;

      return population;
    },

    datasets() {
      const {
        summed_results_ideal_plan: idealData,
        summed_results_past_data: pastData,
        summed_results_predictions: futureData,
      } = this.data;

      const populationValues = this.getValues(this.population);
      const divideByPopulation = (value, index) =>
        value / populationValues[index];

      const makeDataSetPerPerson = ({ data, ...dataset }) => ({
        ...dataset,
        data: data.map(divideByPopulation),
      });

      return [
        {
          label: this.$t('Planned'),
          data: this.getValues(idealData),
          type: 'line',
          backgroundColor: ORANGE,
          fill: false,
          pointRadius: 2,
          pointHoverBackgroundColor: ORANGE,
          borderWidth: 1.5,
          borderColor: ORANGE,
        },
        {
          label: this.$t('Verified'),
          data: this.extractTotals(pastData),
          backgroundColor: MAIN_APP_COLOR,
          borderWidth: 0,
        },
        {
          label: this.$t('Predicted'),
          backgroundColor: MAIN_APP_COLOR_LIGHT,
          data: [
            ...new Array(this.labels.length - this.extractTotals(futureData).length),
            ...this.extractTotals(futureData),
          ],
          borderWidth: 0,
        },
      ].map(this.showDataPerPerson ? makeDataSetPerPerson : (prop) => (prop));
    },

    labels() {
      const { summed_results_ideal_plan: idealData } = this.data;

      return Object.keys(idealData).filter(year => year <= this.toYear && year >= this.fromYear);
    },

    chartData () {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },

    title() {
      return this.$t('titleCarbonSummed', {
        baselineYear: this.fromYear,
      });
    },

    options() {
      const { summed_results_past_data: pastData } = this.data;
      const baseLineEmissions = pastData?.[this.fromYear]?.['totals'] / (this.showDataPerPerson ? this.population[this.fromYear] : 1);

      return {
        ...legendOptions,

        maintainAspectRatio: false,

        title: {
          display: true,
          text: this.title,
        },

        scales: {
          x: { stacked: true },
          y: {
              stacked: true,
              ticks: {
                callback: value =>
                  this.$t('tons', {
                    number: stringifyNumber(value, this.$i18n.locale),
                  }),
              },
            },
        },

        tooltips: {
          callbacks: {
            label: ({ datasetIndex, yLabel }, { datasets }) => {
              const { label } = datasets[datasetIndex];
              const changeInFraction =
                (yLabel - baseLineEmissions) / baseLineEmissions;
              const changeInPercent = changeInFraction.toLocaleString(
                this.$i18n.locale,
                { style: 'percent' },
              );
              const prefix = changeInFraction > 0 ? '+' : '';

              return `${label}: ${this.$t('tons', {
                number: parseNumber(yLabel)(this.$i18n.locale),
              })} (${prefix}${changeInPercent})`;
            },
          },
        },
      };
    },
  },
};
</script>
