import { send_sample_mail } from '@/config/endpoints';

const blacklist_feedbackOnRequest = [ 'intercom', send_sample_mail ];
const blacklist = ['intercom'];
const isNotInBlacklist = (url) => {
    for (let i = 0; i < blacklist.length; i++) {
        if (url.indexOf(blacklist[i]) !== -1) {
            return false;
        }
    }

    return true;
};

export function feedbackOnRequest (request, next) {
    if (request.method == 'POST') {
        request.headers.set('X-TLC', '1');
    }

    // On every request, add a timestamp to prevent caching
    request.url += (request.url.indexOf('?') > 0 ? '&' : '?') + `t=${new Date().getTime()}`;

    // On every response, check for expired tokens
    next((res) => {
        if (res.status === 400 && isNotInBlacklist(blacklist_feedbackOnRequest)) {
            // all 400-statuscodes should result in a toast with an error message. 
            window.alert(res.body.reason_phrase);
        }
        return res;
    });
}

export const disableOnPOST = (request, next) => {
    const requestUrl = request.url || '';
    // if post and not empty url and not in blacklist, disable buttons
    if (request.method === 'POST' && isNotInBlacklist(requestUrl) && !!requestUrl ) {
        document.body.classList.add('disable-buttons');
    } else {
        return next();
    }

    next((res) => {
        document.body.classList.remove('disable-buttons');
        return res;
    });
};