import {
    fetchKnownActionDetails,
    updateKnownAction,
} from '@/api/known-action';
import { fields } from '@/config/known-action-config';

export default {
    props: ['known_action_id'],
    data: () => ({
        isLoading: true,
        ...fields.reduce( (acc, field) => {
            acc[field] = '';
            return acc;
        }, {}),
    }),

    created () {
        this.actionable = 0;
        this.load();
    },

    computed: {
        groupName () {
            return this.$store.getters.selectedUserGroup;
        },

        computedImageSource () {
            return  this.photo 
                ? `https://ddr90r1arm2wz.cloudfront.net/1/known_actions/${this.photo}`
                : 'https://app.futureproofed.com/images/samples/action-sample.jpg';
        },

        related_known_measures () {
            const known_measure_ids = [];
            for (let i = 1; i <= 10; i++) {
                const key = `related_known_measures_${i}`;
                const related_known_measure = this[key];
                known_measure_ids.push({ 
                    key, 
                    related_known_measure,
                });
                
            }
            return known_measure_ids;
        },
    },

    methods: {
        load () {
            if (this.known_action_id) {
                fetchKnownActionDetails(this.known_action_id, this.groupName)
                    .then( data => {
                        Object.keys(data).forEach(field => this[field] = data[field]);
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
            }
        },

        updateKnownAction (params) {
            params.known_action_id = params.id;
            return updateKnownAction(params, this.groupName);
        },
    },
};