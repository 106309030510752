<template>
    <PageContainer :isLoading="isLoading" :error="error">
        <KnownMeasureForm :known_measure_id='id'/>
    </PageContainer>
</template>

<script>
import KnownMeasureForm from './KnownMeasureForm';

import { fetchUser } from '@/mixins';

export default {
  mixins: [fetchUser],
  props: ['id'],

  components: { KnownMeasureForm },
};
</script>
