<template>
<div>
    <div class="tags">
        <div class="tag is-medium cursor-pointer" v-for="{ id, name, rating, rel_id } in value" :key="id" @click="openCoBenefitEditor(rel_id)">
            {{$t('cobenefits.' + name.toLowerCase())}}
            <rating :value="rating" :disabled="true" v-if="rating" class="ml-4 mr-4"/>
            <button v-if="!readOnly" class="delete" @click.stop="removeCoBenefit(rel_id)" />
        </div>

        <div class="tag is-medium is-success" v-if="options.length && !readOnly" @click="showModal = true">
            <i class="fa fa-plus" />
        </div>
        

        <Modal v-if="showModal" @closeModal="closeModal">
            <div class="box">
                <div class="content">
                    <h4>{{ $t('Add co-benefit') }}</h4>
                    <hr />
                    <FieldVertical>
                        <label class="label" slot="label">{{ $t('Choose co-benefit') }}</label>
                        <Select
                                v-model="selected_benefit"
                                :options="options"
                                :labelGetter="o => o.label"
                                :staticOption="$t('Choose co-benefit')"
                                :disabled="rel_id"
                        />
                    </FieldVertical>
                    <br />
                    <FieldVertical v-if="selected_benefit !== null">
                        <label class="label" slot="label">{{ $t('cobenefits_rating') }}</label>
                        <rating v-model="rating"/>
                    </FieldVertical>
                    <br/>
                    <collapsable v-if="selected_benefit !== null" :start_open="!indicator_is_empty">
                        <label class="label ml-4 mb-4" slot="after-chevron">{{ $t('cobenefits_indicator') }} </label>
                        <span slot="after-chevron" class="help is-danger" v-if="!indicator_is_valid"> - {{$t("cobenefits_indicator_form_validation")}}</span>
                        <indicator-form slot="children" v-model="indicator" @invalidate="indicator_is_valid = $event"/>
                    </collapsable>
                    <collapsable v-if="selected_benefit !== null" :start_open="!example_is_empty">
                        <label class="label ml-4 mb-4" slot="after-chevron">{{ $t('cobenefits_example') }} </label>
                        <span slot="after-chevron" class="help is-danger" v-if="!example_is_valid"> - {{$t("cobenefits_indicator_form_validation")}}</span>
                        <example-form slot="children" v-model="example" @invalidate="example_is_valid = $event"/>
                    </collapsable>
                    <br />
                    <button class="button is-success is-rounded" :disabled="selected_benefit === null || !indicator_is_valid || !example_is_valid" @click="rel_id? editCoBenefit() : addCoBenefit()">{{ $t('Save') }}</button>
                </div>
            </div>
        </Modal>
    </div>
</div>
</template>

<script>
import { 
    fetchCoBenefits, 
    addCoBenefit, 
    deleteCoBenefit, 
    editCoBenefit,
    addIndicator,
    editIndicator,
    deleteIndicator,
    addExample,
    editExample,
    deleteExample, 
} from '@/api/co_benefits';
import Rating from '@/components/pages/KnownMeasureForm/Rating';
import IndicatorForm from '@/components/pages/KnownMeasureForm/IndicatorForm';
import Collapsable from '@/components/shared/Collapsable';
import ExampleForm from '@/components/pages/KnownMeasureForm/ExampleForm';

export default {
    components: {ExampleForm, IndicatorForm, Rating, Collapsable},
    props: ['value', 'readOnly', 'known_measure_id'],

    data: () => ({
        co_benefits: [],
        selected_benefit: null,
        isLoading: true,
        showModal: false,
        rating: 2,
        indicator: {},
        indicator_is_valid: true,
        rel_id: null,
        example: {},
        example_is_valid:true,
    }),

    created () {
        fetchCoBenefits(this.groupName)
        .then(data => {
            this.co_benefits = data;
            this.isLoading = false;
        });
    },

    computed: {
        groupName () {
            return this.$store.getters.selectedUserGroup;
        },

        options () {
            return this.co_benefits
                .filter(b1 => this.value.find(b2 => b1.id == b2.id) == null || b1.id === this.selected_benefit)
                .map(b => ({ value: b.id, label: this.$t('cobenefits.' + b.name.toLowerCase()) }));
        },
        indicator_is_empty(){
            return !this.indicator.name && !this.indicator.value && !this.indicator.description && !this.indicator.source_name && !this.indicator.source_link && !this.indicator.title;
        },
        example_is_empty(){
            return !this.example.description && !this.example.source_name && !this.example.source_link && !this.example.title && !this.example.photo_url && !this.example.photo && !this.example.photo_raw;
        },
    },

    methods: {
        async removeCoBenefit (rel_id) {
            await deleteCoBenefit(rel_id, this.groupName);
            this.update();
        },

        async addCoBenefit () {
            const benefit = this.co_benefits.find(b => b.id == this.selected_benefit);
            const result = await addCoBenefit(this.known_measure_id, benefit.id, this.rating, this.groupName);
            
            let promises = [];
            if(!this.indicator_is_empty){
                this.indicator = {...this.indicator, co_benefits_known_measures_id: result.id};
                promises.push(addIndicator(this.indicator, this.groupName));
            }
            if(!this.example_is_empty){
                this.example = {...this.example, co_benefits_known_measures_id: result.id};
                promises.push(addExample(this.example, this.groupName));
            }
            
            await Promise.all(promises);
            this.update();
        },

        async editCoBenefit () {
            let promises = [];
            
            promises.push(editCoBenefit(this.rel_id, this.rating, this.groupName));

            if(this.indicator_is_empty && this.indicator.id){
                promises.push(deleteIndicator(this.indicator.id, this.groupName));
            } else if(this.indicator.id){
                promises.push(editIndicator(this.indicator, this.groupName));
            } else if(!this.indicator_is_empty) {
                this.indicator = {...this.indicator, co_benefits_known_measures_id: this.rel_id};
                promises.push(addIndicator(this.indicator, this.groupName));
            }

            if(this.example_is_empty && this.example.id){
                promises.push(deleteExample(this.example.id, this.groupName));
            } else if(this.example.id){
                promises.push(editExample(this.example, this.groupName));
            } else if(!this.example_is_empty) {
                this.example = {...this.example, co_benefits_known_measures_id: this.rel_id};
                promises.push(addExample(this.example, this.groupName));
            }

            await Promise.all(promises);
            this.update();
        },
        update(){
            this.$emit('update');
            this.closeModal();
        },
        openCoBenefitEditor(rel_id){
            const co_benefit = this.value.find(benefit => benefit.rel_id === rel_id);
            this.rel_id = co_benefit.rel_id;
            this.selected_benefit = co_benefit.id;
            this.indicator = co_benefit.indicator || {};
            this.example = co_benefit.example || {};
            this.rating = co_benefit.rating || 2;
            this.showModal = true;
        },
        closeModal(){
            this.selected_benefit = null;
            this.indicator = {};
            this.example = {};
            this.rating = 2;
            this.rel_id = null;
            this.showModal = false;
        },
    },
};
</script>
