<template>
<slide-transition>
    <Loading v-if="isLoading" />
    <div v-else>
        <div class="flex justify-end mb-4">
            <SearchField @change="filter = $event" />
        </div>
        <div class="actions-table">
            <MultiSelectTable
                :rowId="'user_id'"
                :config="config"
                :data="cityOfficials"
                :multiselect="true"
                :selection="selection"
                :filter="filter"
                :filterBy="'name'"
                :sortByKey="sortByKey"
                :sortDirection="sortDirection"
                :updateSort="updateSort"
                :isRowClickable="() => false"
                :showHeader="true" />
        </div>
    </div>
</slide-transition>
</template>

<script>
import { SlideTransition } from '@/components/shared/transitions';
import MultiSelectTable from '@/components/shared/form/MultiSelectTable';
import { fetchCityOfficials } from '@/api/mails';
import getConfig from '@/config/city-officials';
import sortTableMixin from '@/mixins/sort-table';

export default {
    mixins: [ sortTableMixin ],
    components: { SlideTransition, MultiSelectTable },

    props: ['selection'],

    data: () => ({
        isLoading: true,
        cityOfficials: [],
        filter: '',
        sortByKey: 'city',
    }),

    computed: {
        config () {
            return getConfig(this.$t);
        },
    },

    created () {
        const selectedUserGroup = this.$store.getters.selectedUserGroup;
        fetchCityOfficials(selectedUserGroup)
        .then(data => {
            this.cityOfficials = data;
            this.isLoading = false;
        });
    },
};
</script>
