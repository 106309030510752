<template>
    <div class="field-body">
        <div 
            v-for="protocol in protocols"
            :key="protocol"
            >
            <div class="field has-addons">
                <div class="control">
                    <a class="button is-static">{{ $t("sector") }} ({{ protocol }}):</a>
                </div>
                <div class="control is-expanded">
                    <div class="select is-fullwidth sector-selector__select">
                        <select
                            v-model="selectedSectors[protocol]"
                            @change="triggerChange"
                        >
                            <option v-for="option in sectors.filter(x => x.protocol === protocol)" 
                                :key="option.value"
                                :value="option">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: false,
        },
        sectors: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            protocols: [],
            selectedSectors: {},
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // Find all the unique protocols mentioned in the sectors
            this.protocols = [...new Set(this.sectors.map(x => x.protocol))];
            // Set value when one is present, only when it is an array (because backend provides an array)
            if (this.value.length > 0) {
                this.value.forEach(val => {
                    const selected = this.sectors.find(x => x.id === val.sector_id);
                    this.selectedSectors[selected.protocol] = selected;
                });
                this.triggerChange();
            }
        },
        triggerChange() {
            this.$emit('input', Object.values(this.selectedSectors));
        },
    },
};
</script>
