<template>
  <PageContainer :isLoading="isLoading" :error="error">
    <FetchContainer :entities="entities">
      <ManageUsersContainer />
    </FetchContainer>
  </PageContainer>
</template>

<script>
import ManageUsersContainer from './ManageUsersContainer';

import { fetchUser } from '@/mixins';
import { USERS } from '@/constants';

export default {
  mixins: [fetchUser],

  components: { ManageUsersContainer },

  data() {
    return {
      entities: [USERS],
    };
  },
};
</script>
