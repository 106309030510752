<template>
  <FetchContainer class="box h-full" :entities="entities">
    <LatestYearBySource :selectedYear="selectedYear" />
  </FetchContainer>
</template>

<script>
import LatestYearBySource from './LatestYearBySource';
import FetchContainer from '@/components/shared/FetchContainer';

import { CARBON_SUMMED, CITIES } from '@/constants';

export default {
  components: { LatestYearBySource, FetchContainer },

  props: ['selectedYear'],

  data() {
    return {
      entities: [CARBON_SUMMED, CITIES],
    };
  },
};
</script>
