<template>
    <div>
        <input type="text" 
            class="input" 
            :class="!isValid ? 'is-danger': ''" 
            :placeholder="placeholder"
            
            :value="value"
            @input="handleInput" 
            @blur="handleInput"/>
        <p v-if="!isValid" class="help is-danger">{{ message }}</p>
    </div>
</template>

<script>
import validatedFieldMixin from '@/mixins/validated-field';
export default {
    mixins: [validatedFieldMixin],
};
</script>

