<template>
    <PageContainer :isLoading="isLoading" :error="error">
        <KnownActionDetail :known_action_id="id" />
    </PageContainer>
</template>

<script>
import KnownActionDetail from './KnownActionDetail';

import { fetchUser } from '@/mixins';

export default {
    mixins: [fetchUser],
    props: ['id'],

    components: { KnownActionDetail },
};
</script>
