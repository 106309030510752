<template>
<div class="cards-section">
    <div class="columns is-multiline">
        <!-- related measure cards -->
        <div v-for="{ related_known_measure, key } in related_measures_active" :key="related_known_measure"
            class="column is-one-third-tablet is-one-quarter-desktop">
            <RelatedMeasureCard 
                :known_measure_id="related_known_measure" 
                :readonly="readonly"
                @delete="deleteRelatedMeasure(key)" />
        </div>
        <!-- add new measure card -->
        <div v-if="!readonly" 
            class="column is-one-third-tablet is-one-quarter-desktop" >
            <a @click="showModal = true" v-if="related_measures_active.length < 10">
                <Card>
                    <span class="icon measure-card__add-icon" slot="image-content">
                        <i class="fas fa-plus fa-3x" />
                    </span>
                    <h5 class="title is-5">{{ $t('Add related Measure') }}</h5>
                    <p>{{ $t('Click here to add a related measure') }}</p>
                </Card>
            </a>
        </div>
    </div>

    <AddRelatedMeasureModal 
        v-if="showModal" 
        @closeModal="addRelatedMeasure" 
        :related_measures="related_measures_active" />
</div>
</template>

<script>

import AddRelatedMeasureModal from './AddRelatedMeasureModal';
import RelatedMeasureCard from './RelatedMeasureCard';

export default {
    components: { AddRelatedMeasureModal, RelatedMeasureCard },
    props: [
        'related_measures',
        'readonly',
    ],

    data: () => ({
        showModal: false,
    }),

    computed: {
        related_measures_active () {
            return this.related_measures.filter(ms => ms.related_known_measure);
        },

        related_measures_inactive () {
            return this.related_measures.filter(ms => !ms.related_known_measure);
        },
    },

    methods: {
        addRelatedMeasure (id) {
            this.showModal = false;
            const related_measure_key = this.related_measures_inactive[0].key;
            this.$emit('addRelatedMeasure', { related_measure_key, id });
        },

        deleteRelatedMeasure (id) {
            this.$emit('deleteRelatedMeasure', id);
        },
    },
};
</script>
