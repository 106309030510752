import { compose, prop } from 'ramda';

import { Progress } from '@/components/shared/table-cells';

import { toLocaleString } from '@/utils';

export default ($t, locale) => [
  {
    key: 'name',
    label: $t('shared.name'),
    cellDataGetter: prop('city_name'),
  },
  {
    key: 'progress',
    label: $t('shared.progress'),
    subLabel: $t('shared.progressSubLabel'),
    cellDataGetter: ({ progress, target, progress_unit }) => ({
      value: (Number(progress) / target) * 100,
      label: `${Number(progress).toLocaleString(
        locale,
      )}/${Number(target).toLocaleString(locale)} ${progress_unit}`,
    }),
    cellRenderer: Progress,
    sortDataGetter: ({ progress, target }) =>
      target ? (progress / target) * 100 : 0,
  },
  {
    key: 'reduction',
    label: $t('shared.reduction'),
    subLabel: $t('measuresPage.reductionSubLabel'),
    cellDataGetter: compose(
      value => value.toLocaleString(locale, { maximumFractionDigits: 0 }),
      prop('total_carbon_reduction'),
    ),
    sortDataGetter: prop('total_carbon_reduction'),
  },
  {
    key: 'savings',
    label: $t('cityDetailsPage.savings'),
    subLabel: $t('cityDetailsPage.savingsSubLabel'),
    cellDataGetter: m => {
      return Number(m.total_yearly_savings).toLocaleString(locale, {
        style: 'currency',
        currency: m.currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    sortDataGetter: compose(
      prop('total_yearly_savings'),
    ),
  },
];
