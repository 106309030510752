<template>
    <PageContainer :isLoading="isLoading" :error="error">
        <KnownActionForm :known_action_id="id" />
    </PageContainer>
</template>

<script>
import KnownActionForm from './KnownActionForm';

import { fetchUser } from '@/mixins';

export default {
    mixins: [fetchUser],
    props: ['id'],

    components: { KnownActionForm },
};
</script>
