import { compose, prop, toLower } from 'ramda';
import { ActionButton } from '../components/shared/table-cells';
import api from '@/api';
import { resendUserInvitation as endpoint } from '@/config/endpoints';

const YesOrNo = {
  functional: true,
  render: (h, { props: { value } }) => (
    <i class={`icon ${value ? 'check' : 'close'}-icon`} />
  ),
};

export default $t => [
  {
    key: 'email',
    label: $t('shared.email'),
    cellDataGetter: prop('email'),
    sortDataGetter: compose(
      toLower,
      prop('email'),
    ),
  },
  {
    key: 'isAdmin',
    label: $t('manageUsersPage.isAdmin'),
    cellDataGetter: prop('admin'),
    cellRenderer: YesOrNo,
  },
  {
    key: 'acceptedInvite',
    label: $t('manageUsersPage.acceptedInvite'),
    cellDataGetter: prop('invite_accepted'),
    cellRenderer: YesOrNo,
  },
  {
    key: 'resendInvitation',
    label: $t('manageUsersPage.resendInvitation'),
    cellDataGetter: user => ({
      data: user,
      show: !user.invite_accepted,
      title: $t('manageUsersPage.resendInvitation'),
      onClick: ({ data, $store }, onComplete) => {
        const email = data.email;
        const { selectedUserGroup } = $store.getters;
        
        api(selectedUserGroup)
          .post(endpoint, { email }, { emulateJSON: true })
          .then(() => {
            onComplete();
          });
      },
    }),
    cellRenderer: ActionButton,
  },
];
