<template>
  <Error v-if="error" :error="error" />

  <div v-else>
    <TablePageContainer
      :config="config"
      :data="measures"
      :isLoading="isLoading"
      :isRowClickable="isRowClickable"
      :title="title"
      :onRowClick="handleOnRowClick" />
  </div>
</template>

<script>
import { prop } from 'ramda';

import api from '@/api';
import TablePageContainer from '@/components/shared/TablePageContainer';
import getConfig from '@/config/city-detail';
import { getCityDetail } from '@/config/endpoints';

export default {
  components: { TablePageContainer },

  data() {
    return {
      error: null,
      isLoading: true,
      measures: null,
      name: null,
      currency: null,
    };
  },

  computed: {
    config() {
      return getConfig(this.$t, this.$i18n.locale, this.currency);
    },

    title() {
      return this.$t('cityDetailsPage.title', { name: this.name });
    },
  },

  created() {
    this.fetchCityDetails();
  },

  methods: {
    fetchCityDetails() {
      // todo: store in store?
      const selectedUserGroup = this.$store.getters.selectedUserGroup;

      const name = this.$route.params.name;
      const city = this.$store.getters.getCityByName(name);

      if (!city) {
        this.error = this.$t('errorMessages.unknownCity', {
          city: name,
          group: this.$store.getters['groupInfo/name'],
        });
        return;
      }

      this.name = city.name;
      this.currency = city.currency;

      const endpoint = getCityDetail(name);
      const parseApiResponse = prop('measures');

      api(selectedUserGroup)
        .get(endpoint)
        .then(parseApiResponse)
        .then(measures => {
          this.isLoading = false;
          this.measures = measures;
        })
        .catch(error => {
          this.isLoading = false;
          this.error = error;
        });
    },

    handleOnRowClick({ known_measure_id: id }) {
      this.$router.push({ name: 'measureDetail', params: { id } });
    },

    isRowClickable: ({ known_measure_id: id }) => id !== 0,
  },
};
</script>

