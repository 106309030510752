<template>
    <div>
        <div @click="toggle" class="flex  cursor-pointer" :class="classes">
            <slot name="before-chevron"/>
            <div class="w-4 text-center">
                <i v-if="$slots.children && !isOpen" class="fas fa-chevron-right" />
                <i v-if="$slots.children && isOpen" class="fas fa-chevron-down" />
            </div>
            <slot name="after-chevron"/>
        </div>
        <div v-show="isOpen">
            <slot name="children"/>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            classes: [Array, String],
            start_open: {type: Boolean, default:false},
        },
        data(){
            return {
                isOpen: this.start_open,
            };
        },
        methods:{
            open(){
                this.isOpen = true;
            },
            close(){
                this.isOpen = false;
            },
            toggle(){
                this.isOpen = !this.isOpen;
            },
        },
    };
</script> 