export const fields = [
    'id',
    'name',
    'description_short',
    'description_long',
    'description_public',
    'sector',
    'sectors',
    'adaptation_sector',
    'scope',
    'category', 
    'progress_unit',
    'kpi_target_year_1', 
    'kpi_total_reference_percentage', 
    'kpi_total_reference',
    'kwh_reduction', 
    'renewable_energy_production_kwh',
    'emission_factor_fuel',
    'co2_reduction',
    'carbon_reduction',
    'initial_investment',
    'investor_name',
    'yearly_savings',
    'saver_name',
    'x_factor_implementation',
    'available',
    'related_city_group_id',
    'photo',
    'in_sia',
];

export const languages = [
    {
        value: 'en',
        label: 'English',
    },
    {
        value: 'fr',
        label: 'French',
    },
    {
        value: 'nl',
        label: 'Dutch',
    },
    {
        value: 'es',
        label: 'Spanish',
    },
    {
        value: 'sr',
        label: 'Serbian',
    },
    {
        value: 'mk',
        label: 'Macedonian',
    },
    { 
        value: 'da', 
        label: 'languages.da',
    },
    { 
        value: 'de', 
        label: 'languages.de',
    },
    { 
        value: 'no', 
        label: 'languages.no',
    },
    { 
        value: 'sv', 
        label: 'languages.sv',
    },

];

export const scopes = [
    {
        value: '',
        label: ' - ',
    },
    {
        value: 'scope1_2',
        label: 'Scope 1 & 2',
    },
    {
        value: 'scope3',
        label: 'Scope 3',
    },
];

export const categories = [
    { value: '' },
    { value: 'Renewables' },
    { value: 'Energy efficiency' },
    { value: 'Reduction' },
];

export const kpi_total_references = [
    { value: '' },
    { value: 'number of vehicles' },
    { value: 'number of tertiary buildings' },
    { value: 'number of new registered vehicles' },
    { value: 'number of MWh total' },
    { value: 'number of MWh industry' },
    { value: 'number of MWh tertiary' },
    { value: 'number of MWh households' },
    { value: 'number of MWh government light' },
    { value: 'number of MWh government fleet' },
    { value: 'number of MWh government buildings' },
    { value: 'number of MWh agriculture' },
    { value: 'number of kms passenger vehicles' },
    { value: 'number of kms municipal fleet' },
    { value: 'number of km2 city' },
    { value: 'number of inhabitants' },
    { value: 'number of industry buildings' },
    { value: 'number of households' },
];

export const investor_saver_names = [
    { value: '' },
    { value: 'City' },
    { value: 'Individuals' },
    { value: 'Business' },
    { value: 'Agriculture' },
    { value: 'Industry' },
    { value: 'Other private party' },
    { value: 'Other public party' },
    { value: 'Unknown' },
];

export const sectors = {
    mitigation: [
        { value: '' },
        { value: 'Households' },
        { value: 'Industry' },
        { value: 'Agriculture' },
        { value: 'Business' },
        { value: 'Government' },
        { value: 'Municipal fleet' },
        { value: 'Public Lighting' },
        { value: 'Public transport' },
        { value: 'Private transport' },
        { value: 'Local heat/cold production' },
        { value: 'Local electricity production' },
        { value: 'Water' },
        { value: 'Food' },
        { value: 'Waste' },
        { value: 'Other' },
        { value: 'Consumption' },
    ],
    adaptation: [
        { value: '' },
        { value: 'Buildings' },
        { value: 'Transport' },
        { value: 'Energy' },
        { value: 'Water' },
        { value: 'Waste' },
        { value: 'Land use planning' },
        { value: 'Agriculture and forestry' },
        { value: 'Environment and biodiversity' },
        { value: 'Health' },
        { value: 'Civil protection and emergency' },
        { value: 'Tourism' },
        { value: 'Other' },
     ],
};

export const adaptation_risks = [
    { value: 'extreme heat' },
    { value: 'extreme cold' },
    { value: 'extreme precipitation' },
    { value: 'floods' },
    { value: 'sea level rise' },
    { value: 'droughts' },
    { value: 'storms' },
    { value: 'landslides' },
    { value: 'forest fires' },
    { value: 'other' },
];

export const city_classifications = [
    { value: 'small_municipality' },
    { value: 'medium_municipality' },
    { value: 'large_municipality' },
];