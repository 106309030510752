<template>
<div class="known-actions-page">
    <!-- title and button -->
    <div class="field flex justify-between flex-wrap">
        <h3 class="title">
            <a @click="goBack" class="back-button fas fa-arrow-left" :title="$t('Back')" />
            {{ title }}
        </h3>
        <button v-if="mode === 'update'" class="button is-rounded is-success" @click="updateAction">
            {{ $t("Update") }}
        </button>
        <button v-else class="button is-rounded is-success" @click="createKnownAction">
            {{ $t("Save") }}
        </button>
    </div>

    <Loading v-if="isLoading" />
    <div v-else class="box" @input="isDirty = true">
        <Field>
            <figure class="action-img" :style="{ backgroundImage: `url(${computedImageSource})` }" slot="label" />

            <label class="label is-hidden-tablet">{{ $t("Name") }}</label>
            <div class="field has-addons mt-8-tablet">
                <div class="control is-hidden-mobile">
                    <a class="button is-static">{{ $t("Name") }}:</a>
                </div>
                <div class="control is-expanded">
                    <ValidatedInput 
                        :required="true"
                        :placeholder="$t('Click here to set a title')" 
                        v-model="name" 
                        @invalidate="setValid" />
                </div>
            </div>
            
            <div class="field-body mt-4">
                <!-- mitigation sector -->
                <label class="label is-hidden-tablet">{{ $t("sector") }}</label>
                <div class="field has-addons">
                    <div class="control is-hidden-mobile">
                        <a class="button is-static">{{ $t("sector") }}:</a>
                    </div>
                    <div class="control is-expanded">
                        <Select v-model="related_mitigation_sector" :options="sectors.mitigation" :isFullWidth="true" 
                            :labelGetter="translateOption" />
                    </div>
                </div>
                <!-- adaptation sector -->
                <label class="label mt-4 is-hidden-tablet">{{ $t("adaptation_sector") }}</label>
                <div class="field has-addons">
                    <div class="control is-hidden-mobile">
                        <a class="button is-static">{{ $t("adaptation_sector") }}:</a>
                    </div>
                    <div class="control is-expanded">
                        <Select v-model="related_adaptation_sector" :options="sectors.adaptation" :isFullWidth="true" 
                            :labelGetter="translateOption"/>
                    </div>
                </div>
            </div>

        </Field>
        <hr />
        <!-- <br /> -->
        
        <!-- description  -->
        <Field>
            <label class="label" slot="label">{{ $t("Description") }}</label>
            <ValidatedTextArea 
                :required="true"
                :placeholder="$t('Write a description here')"
                v-model="description"
                @invalidate="setValid"
            />
        </Field>

        <Field>
            <label class="label" slot="label">{{ $t("type") }}</label>
            <Select v-model="type" :options="types" :isFullWidth="true" 
                :labelGetter="translateOption" />
        </Field>

        <Field>
            <label class="label" slot="label">
                <Tooltip :isWide="true" :tooltip="$t('actionable-tooltip')">{{ $t("actionable") }} <span class="fas fa-question-circle"/></Tooltip>
            </label>
            <Select v-model="actionable" :options="yesNo" :isFullWidth="true"
                :labelGetter="translateOption" />
        </Field>

        <Field>
            <label class="label" slot="label">{{ $t("internal_notes") }}</label>
            <textarea class="textarea" 
                :placeholder="$t('Write extra information about this action here')"
                v-model="internal_notes"
            />
        </Field>
    </div>

    <h1 class="title">{{ $t('Related Measures') }}</h1>
    <RelatedMeasures 
        :related_measures="related_known_measures"
        @addRelatedMeasure="addRelatedMeasure"
        @deleteRelatedMeasure="deleteRelatedMeasure" />

    <div class="field flex justify-end">
        <button v-if="mode === 'update'" class="button is-rounded is-success" @click="updateAction">
            {{ $t("Update") }}
        </button>
        <button v-else class="button is-rounded is-success" @click="createKnownAction">
            {{ $t("Save") }}
        </button>
    </div>
</div>
</template>

<script>
import { pick } from 'ramda';
import { 
    fields,
    types,
} from '@/config/known-action-config';
import { sectors } from '@/config/known-measure-config';
import { createKnownAction } from '@/api/known-action';
import knownActionMixin from '@/mixins/known-action';
import RelatedMeasures from './RelatedMeasures';

export default {
    mixins: [knownActionMixin],
    components: {  RelatedMeasures },
    data: () => ({
        sectors,
        types,
        yesNo: [{ value: 0, label: 'No' }, { value: 1, label: 'Yes' }],

        isValid: true,
        isDirty: false,
    }),

    computed: {
        mode () {
            return this.id !== '' ? 'update': 'new';
        },

        title () {
            return this.mode === 'update'
                ? this.$t('knownActionsFormPage.pageTitle--update')
                : this.$t('knownActionsFormPage.pageTitle--create');
        },
    },

    methods: {
        createKnownAction () {
            const params = pick(fields, this);
            createKnownAction(params, this.groupName)
                .then( result => this.$router.push({
                    name: 'knownActionDetail',
                    params: { id: result.id },
                }));
        },

        updateAction () {
            if (this.isValid) {
                const params = pick(fields, this);
                this.updateKnownAction(params)
                    .then(() => {
                        this.$router.push({
                            name: 'knownActionDetail',
                            params: { id: this.id },
                        });
                    });
            }
        },

        translateOption (option) {
            return option.label
                ? option.label === ''
                    ? ' - '
                    : this.$t(option.label)
                : option.value === ''
                    ? ' - '
                    : this.$t(option.value);
        },

        setValid (isValid) {
            this.isValid = isValid;
        },

        goBack () {
            if (this.isDirty) {
                const confirmed = window.confirm(this.$t('Unsaved changes will be lost, are you sure you want to leave this page?'));
                if (confirmed) {
                    this.$router.go(-1);
                }
            } else {
                this.$router.go(-1);
            }
        },

        deleteRelatedMeasure (related_measure_key) {
            this.isDirty = true;
            this[related_measure_key] = '';
        },

        addRelatedMeasure ({related_measure_key, id}) {
            this.isDirty = true;
            this[related_measure_key] = id;
        },
    },
};
</script>
