<template>
    <PageContainer :isLoading="isLoading" :error="error">
        <KnownMeasureTranslations :known_measure_id="id"/>
    </PageContainer>
</template>

<script>
import KnownMeasureTranslations from './KnownMeasureTranslations';

import { fetchUser } from '@/mixins';

export default {
    props: ['id'],
    mixins: [fetchUser],

    components: { KnownMeasureTranslations },
};
</script>
