import { prop } from 'ramda';
import { makeModuleAsync } from '@/utils';
import { userGroups as endpoint } from '@/config/endpoints';

const parseApiResponse = prop('groups_accessible');

export default makeModuleAsync({
  endpoint,
  parseApiResponse,
  requiresUserGroup: false,
});
