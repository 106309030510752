import { addIndex, compose, curry, find, map, propEq } from 'ramda';
import { compactInteger } from 'humanize-plus';

export { default as makeAsyncActionTypes } from './make-async-action-types';
export { default as makeModuleAsync } from './make-module-async';

export const addPlusToPositive = val => (val > 0 ? `+${val}` : val);

export const isProduction = process.env.NODE_ENV === 'production';

export const mapIndexed = addIndex(map);

export const toLocaleString = (locale, options = {}) => number =>
  number.toLocaleString(locale, options);

export const fractionToPercentage = (fraction, locale = 'nl') =>
  fraction.toLocaleString(locale, { style: 'percent' });

export const findInArrayByProp = curry((array = [], prop, value) =>
  find(propEq(prop, value), array),
);

export const findInArrayByPropEnforcing = curry((array, prop, value) => {
  const found = findInArrayByProp(array, prop, value) ?? {};

  // if (found == null) {
  //   throw new Error(`Item with { ${prop}: ${value} } not found in array`);
  // }

  return found;
});

export const reverseDirection = direction =>
  direction === 'ASC' ? 'DESC' : 'ASC';

const M = Math.pow(10, 6);
const K = Math.pow(10, 3);

const getCompactIntegerRepresentation = value => {
  if (value > M) {
    return value / M;
  }

  if (value > K) {
    return value / K;
  }

  return value;
};

const getPrecision = a => {
  if (!isFinite(a)) return 0;
  let e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
};

const getScaleLabelDecimals = compose(
  getPrecision,
  getCompactIntegerRepresentation,
  Math.abs,
);

export const stringifyNumber = (value, locale = 'nl') =>
  Math.abs(value) < 10
    ? value.toLocaleString(locale, {
        maximumFractionDigits: 2,
      })
    : compactInteger(value, getScaleLabelDecimals(value));

export const parseNumber = value => locale =>
  value.toLocaleString(locale, {
    maximumFractionDigits: 2,
  });

// does inline partitioning on a shallow copy, so we don't destroy the arr parameter
// example usage :  partion(3,[1,2,3,4,5,6,7,8])=> [[1,2,3],[4,5,6],[7,8]]
export const partition  =  (n, arr) =>  {
    const inlinePartition = (n2,a) =>  a.length ? [a.splice(0, n2)].concat(inlinePartition(n2,a)) : [];
    return  inlinePartition(n,arr.slice());
};

/**
 * Function
 * takes two objects with the same keys and sumable values
 * returns a new object with the same keys corespondig to the sum of the values of the input object for each key
 */
export const sumByKey = (a,b) => Object.keys(a).reduce( 
  (result,key) => {
    result[key] = a[key]+b[key];
    return result;
  },{});