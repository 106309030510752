export const fields = [
    'id',
    'name',
    'description',
    'type',
    'related_mitigation_sector',
    'related_adaptation_sector',
    'date_last_updated',
    'available',
    'results_unit',
    'image',
    'photo',
    'photo_thumb',
    'internal_notes',
    'actionable',
    'recommended',
    'related_known_measures_1',
    'related_known_measures_2',
    'related_known_measures_3',
    'related_known_measures_4',
    'related_known_measures_5',
    'related_known_measures_6',
    'related_known_measures_7',
    'related_known_measures_8',
    'related_known_measures_9',
    'related_known_measures_10',
    'related_city_group_id',
];

export const types = [
    { value: ''},
    { value: 'Advice'},
	{ value: 'Awareness & sensibilisation'},
	{ value: 'Certification & labelling' },
	{ value: 'Communication'},  
	{ value: 'Events'},  
	{ value: 'Group purchase' },
	{ value: 'Investments'},
	{ value: 'Pricing & taxes' },
	{ value: 'Premiums & subsidies' },
	{ value: 'Regulation & standards' },
	{ value: 'Partnerships & collaborations'},
	{ value: 'Participation'},
	{ value: 'Public procurement'},
	{ value: 'Other'},
];

