import { allPass, any, both, isNil, prop, uniqBy } from 'ramda';
import { latLng, latLngBounds } from 'leaflet';
import { makeModuleAsync } from '@/utils';
import { actions as endpoint } from '@/config/endpoints';

const module = {
  getters: {
    sectors: ({ value = {} }) => value.sectors || [],

    types: ({ value = {} }) => value.types || [],

    uniqueActions: ({ value = {} }) => uniqBy(prop('id'), value.actions || []),

    actionsWithinGroupBounds(state, getters, rootState, rootGetters) {
      if (state.value == null) {
        return;
      }

      const {
        value: { actions },
      } = state;

      const {
        lat_topleft,
        lon_topleft,
        lat_bottomright,
        lon_bottomright,
      } = rootGetters['groupInfo/group'];

      if (
        any(isNil, [lat_topleft, lon_topleft, lat_bottomright, lon_bottomright])
      ) {
        return actions;
      }

      const bounds = latLngBounds(
        latLng([lat_topleft, lon_topleft]),
        latLng([lat_bottomright, lon_bottomright]),
      );

      const isWithinBounds = ({ location_lat, location_lon }) =>
        bounds.contains(latLng([location_lat, location_lon]));

      const hasLocation = both(prop('location_lat'), prop('location_lon'));

      return actions.filter(allPass([hasLocation, isWithinBounds]));
    },
  },
};

export default makeModuleAsync({ endpoint, module });
