/* global L */

export default (function (L) {
  L.Control.CustomButton = L.Control.extend({
    options: {
      class: 'custom',
      position: 'topright',
      title: '',
      onClick: () => console.log('onClick'), // eslint-disable-line
    },

    onAdd: function(map) {
      var container = L.DomUtil.create('div', `leaflet-control-${this.options.class} leaflet-bar leaflet-control`);

      this.link = L.DomUtil.create('a', `leaflet-control-${this.options.class}-button leaflet-bar-part`, container);
      this.link.href = '#';
      this.link.title = this.options.title;
      this._map = map;

      L.DomEvent.on(this.link, 'click', this._handleOnClick, this);

      return container;
    },

    _handleOnClick: function(e) {
      L.DomEvent.stopPropagation(e);
      L.DomEvent.preventDefault(e);

      this.options.onClick();
    }
  });

  L.control.addCustomButton = function (options) {
    return new L.Control.CustomButton(options);
  };
}(L));
