<template>
  <div :style="{ minHeight: '230px', minWidth: '180px'}">
    <canvas ref="chart" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { add } from 'ramda';

import { BASELINE_YEAR, colorBySource, sources } from '@/config';
import { parseNumber, stringifyNumber } from '@/utils';
import { getEmissionsByYearBySource } from '@/utils/city';

export default {
  props: ['city', 'lastAvailableYear', 'locale', '$t'],

  mounted() {
    setTimeout(this.initChart, 0);
  },

  methods: {
    initChart() {
      const ctx = this.$refs.chart;

      if (ctx == null) {
        return;
      }

      const years = [
        ...Array(this.lastAvailableYear - BASELINE_YEAR + 1).keys(),
      ].map(add(BASELINE_YEAR));

      const datasets = sources.map(source => ({
        backgroundColor: colorBySource[source],
        borderWidth: 0, // todo: move to global settings
        data: years.map(year =>
          getEmissionsByYearBySource(year)(source)(this.city),
        ),
        label: this.$t(source),
      }));

      const labels = years;

      const title = this.$t('titleCityEmissionsByYearBySource', {
        city: this.city.name,
      });

      this.chart = new Chart(ctx, {
        type: 'bar',

        data: {
          labels,
          datasets,
        },

        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: title,
          },

          tooltips: {
            callbacks: {
              label: ({ datasetIndex, yLabel }, { datasets }) => {
                const { label } = datasets[datasetIndex];

                return `${label}: ${this.$t('tons', {
                  number: parseNumber(yLabel)(this.locale),
                })}`;
              },
            },
          },

          scales: {
            x: {
                stacked: true,
                ticks: {
                  autoSkip: false,
                },
              },
            y: {
                stacked: true,
                ticks: {
                  callback: value =>
                    this.$t('tons', {
                      number: stringifyNumber(value, this.locale),
                    }),
                },
              },
          },
        },
      });
    },
  },
};
</script>

