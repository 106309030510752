<template>
    <div class="h-full relative">
    <canvas ref="chartCanvas"  :style="{width: '50pc'}"/>
    </div>
</template>

<script>
    
import Chart from 'chart.js/auto';
import { tsPlusWeek, TIME_UNITS, MILLISECONDS_IN_DAY } from '@/utils/date'; 

export default {
    name: 'ActivityChart', 
    props: [ 'chartData'  , 'shouldUpdate', 'title', 'timeUnit'],
    components: { Chart },
    data() {
        return {

            options: {
                scales: {
                    x: {
                        labels: this.chartData.labels.map(l => l.toLocaleDateString()),
                    },
                },

                plugins: {
                    title: {
                        display: true,
                        text : this.title,
                    },

                    legend : {
                        display: false,
                    },

                    tooltip: {
                        callbacks: {
                            title: (tt) => {
                                const t = tt[0];
                                const index = t.dataIndex;
                                const ts = this.chartData.labels[index].getTime();
                                const tsDateString =  new Date(ts).toLocaleDateString();
                                if (this.timeUnit==TIME_UNITS.WEEK) {
                                    const ts = this.chartData.labels[index].getTime();
                                    const tsNextWeek = tsPlusWeek(ts);
                                    return this.$t('Week from to',
                                                { from : tsDateString ,
                                                    to : new Date(tsNextWeek-MILLISECONDS_IN_DAY).toLocaleDateString(),
                                                });
                                }
                                return tsDateString;
                            },
                        },
                    },
                },

            },
        };
    },


    watch: {
        chartData (newData) {
            if (!this.shouldUpdate) {
                return;
            }
             this.chart.data = newData;
            this.chart.update();
        },
    },

    mounted() {

        const ctx = this.$refs.chartCanvas;
        ctx.height = '230px';

        this.chart = new Chart(ctx,{
            type : 'bar',
            data : this.chartData,
            options : this.options,
        });
    },
};

</script>
