<template>
    <div>
        <div class="field flex justify-between flex-wrap">
            <h3 class="title">
                <a @click="goBack"
                    class="back-button fas fa-arrow-left"
                    :title="$t('Back')"
                />
                {{ $t("KnownMeasuresTranslationsPage.pageTitle", { name }) }}
            </h3>
            <div>
                <Select :options="languages" v-model="lang_selector" :labelGetter="item => $t(item.label)" @input="onChangeLang" />
                <button class="button is-rounded is-success" @click="updateTranslations" :disabled="isLoading">
                    {{ $t("Save") }}
                </button>
            </div>
        </div>
        <Loading v-if="isLoading" />
        <translations-form 
                v-else
                :lang="language" 
                :object="known_measure" 
                :form-config="measure_config" 
                object_type="known_measure"
                :groupName="groupName"
                @setDirty="measure_is_dirty = $event"
                ref="known_measure_translations"
        />
        <h4 v-if="co_benefits.some(c => c.indicator)" class="title">{{$t("cobenefits_indicator_translations_title")}}</h4>
        <div v-for="(co_benefit, index) in co_benefits" :key="co_benefit.id" class="mb-4">
            <template v-if="co_benefit.indicator">
                <label class="label mb-4">{{$t('cobenefits.' + co_benefit.name.toLowerCase())}}</label>

                <translations-form
                        :lang="language"
                        :object="co_benefit.indicator"
                        :form-config="indicator_config($t)"
                        object_type="co_benefits_indicator"
                        :groupName="groupName"
                        @setDirty="$set(indicator_is_dirty,index,$event)"
                        ref="indicator_translations"
                />
            </template>
        </div>
        <h4 v-if="co_benefits.some(c => c.example)" class="title">{{$t("cobenefits_example_translations_title")}}</h4>
        <div v-for="(co_benefit, index) in co_benefits" :key="`example_${co_benefit.id}`" class="mb-4">
            <template v-if="co_benefit.example">
                <label class="label mb-4">{{$t('cobenefits.' + co_benefit.name.toLowerCase())}}</label>

                <translations-form
                        :lang="language"
                        :object="co_benefit.example"
                        :form-config="example_config($t)"
                        object_type="co_benefits_example"
                        :groupName="groupName"
                        @setDirty="$set(example_is_dirty,index,$event)"
                        ref="example_translations"
                />
            </template>
        </div>

        <h4 v-if="derived_units" class="title">{{$t("derived_units")}}</h4>
        <div v-for="(unit, index) in derived_units" :key="`unit_${unit.id}`" class="mb-4">
            <template>
                <translations-form
                        :lang="language"
                        :object="unit"
                        :form-config="derived_unit_config($t)"
                        object_type="derived_unit"
                        :groupName="groupName"
                        @setDirty="$set(unit_is_dirty,index,$event)"
                        ref="unit_translations"
                />
            </template>
        </div>

    </div>
</template>

<script>
import { 
    languages,
} from '@/config/known-measure-config';
import knownMeasureMixin from '@/mixins/known-measure';
import {config, example_config, indicator_config, derived_unit_config} from './formConfig';
import Toast from '@/components/shared/Toast';
import TranslationsForm from '@/components/pages/KnownMeasureTranslations/TranslationsForm';

export default {
    mixins: [knownMeasureMixin],
    components: {TranslationsForm, Toast },
    data () {
        return {
            measure_config: config(this.$t),
            indicator_config: indicator_config,
            example_config: example_config,
            derived_unit_config: derived_unit_config,
            lang_selector: 'en',
            language: 'en',
            languages,
            measure_is_dirty: false,
            indicator_is_dirty: [],
            example_is_dirty: [],
            unit_is_dirty: [],
        };
    },

    computed:{
        known_measure(){
            return this.measure_config.reduce( (a, c) => ({...a, [c.field]: this[c.field]}),{id: this.known_measure_id});
        },
        isDirty(){
            return (
                this.measure_is_dirty 
                || this.indicator_is_dirty.some(v => v === true) 
                || this.example_is_dirty.some(v => v === true)
                || this.unit_is_dirty.some(v => v === true)
            );
        },
    },

    methods: {
        goBack () {
            if (this.isDirty) {
                const confirmed = window.confirm(this.$t('Unsaved changes will be lost, are you sure you want to leave this page?'));
                if (confirmed) {
                    this.$router.go(-1);
                }
            } else {
                this.$router.go(-1);
            }
        },

        onChangeLang () {
            if (this.isDirty && !window.confirm(this.$t('Unsaved changes will be lost, are you sure you want to leave this page?'))) {
                this.lang_selector = this.language; // reset language in selector to current language
            } else {
                this.language = this.lang_selector; // update current language to language in selector
            }
        },

        updateTranslations(){
            this.$refs.known_measure_translations.updateTranslations();
            if(this.$refs.indicator_translations){
                this.$refs.indicator_translations.forEach( it => it.updateTranslations());
            }
            if(this.$refs.example_translations){
                this.$refs.example_translations.forEach( et => et.updateTranslations());
            } 
            if(this.$refs.unit_translations){
                this.$refs.unit_translations.forEach( ut => ut.updateTranslations());
            }
        },
    },
};
</script>
