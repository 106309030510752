<template>
    <div class="tags">
        <template  v-for="tag in value">
            <div class="tag is-medium" :key="tag" v-if="tag">
                {{ $t(translation_prefix + tag) }}
                <button v-if="!readOnly" class="delete" @click="removeTag(tag)"></button>
            </div>
        </template>

        <div class="tag is-medium is-success" v-if="!readOnly && options.length" @click="showModal = true">
            <i class="fa fa-plus"></i>
        </div>

        <Modal v-if="showModal" @closeModal="showModal = false">
            <div class="box">
                <div class="content">
                    <h4>{{ $t(translation_prefix + 'add') }}</h4>
                    <hr />
                    <FieldVertical>
                        <label class="label" slot="label">{{ $t(translation_prefix + 'choose') }}</label>
                        <Select
                                v-model="selected_tag"
                                :options="options"
                                :labelGetter="o => $t(translation_prefix + o.value)"
                                :staticOption="$t(translation_prefix + 'choose')" />
                    </FieldVertical>
                    <br>
                    <button class="button is-success is-rounded" :disabled="selected_tag === null" @click="addTag">{{ $t(translation_prefix + 'add') }}</button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>

    export default {
        props: {
            value: Array,
            readOnly: Boolean,
            possible_tags: Array,
            translation_prefix: {type:String, default:''},
        },
        data: () => ({
            selected_tag: null,
            showModal: false,
        }),

        computed: {
            options () {
                return this.possible_tags.filter(r => this.value.indexOf(r.value) < 0);
            },
        },

        methods: {
            removeTag (tag) {
                this.$emit('input', this.value.filter(t => t !== tag));
            },

            addTag() {
                this.$emit('input', this.value.concat([this.selected_tag]));
                this.selected_tag = null;
                this.showModal = false;
            },
        },
    };
</script>