<template>
<Modal @closeModal="closeModal">
    <div class="box">
        
    <slide-transition>
        <!-- step 1 -->
        <div v-if="currentStep === 'step1'" key="step1">
            <div class="content">
                <h4>{{ $t("Preview") }}</h4>
            </div>
            <hr />
            <MailPreview :transactional_email_data="transactional_email_data" />
            <hr />
            <div class="content flex justify-end">
                <button class="button is-rounded" @click="currentStep = 'step2-test'">{{ $t("Next") }}</button>
            </div>
        </div>

        <!-- step 2: sample email -->
        <div v-if="currentStep === 'step2-test'" key="step2-test">
            <div class="content">
                <h4>{{ $t("Send test email") }}</h4>
            </div>
            <hr />
            <FieldVertical>
                <label class="label" slot="label">{{ $t("Send test mail to:") }}</label>
                <input class="input" type="text" v-model="sampleEmail" />
                <p v-if="error" class="help is-danger">{{ error }}</p>
            </FieldVertical>
            <br />
            <p>
                {{ $t("Send mail and verify that everything is in order before sending it to your municipalities.") }}
                {{ $t("Or")}}:  <a @click="currentStep = 'step2-municipalities'">{{ $t("skip this step") }}</a>.
            </p>
            <p></p>
            <hr />
            <div class="content flex justify-between">
                <button class="button is-rounded" @click="currentStep = 'step1'">{{ $t("Back") }}</button>
                <button class="button is-rounded is-success" @click="sendSampleMail">{{ sampleEmailMessage }}</button>
            </div>
        </div>

        <!-- step 2: send to municipalities -->
        <div v-if="currentStep === 'step2-municipalities'" key="step2-municipalities">
            <div class="content">
                <h4>{{ $t("Choose recipients") }}</h4>
            </div>
            <hr />
            <CityOfficialsTable :selection="cityOfficials" />
            <hr />
            <div class="content flex justify-between">
                <button class="button is-rounded" @click="currentStep = 'step2-test'">{{ $t("Back") }}</button>
                <button class="button is-rounded is-success" @click="sendMailsInBulk" :disabled="!cityOfficials.length">{{ $t("Send mail") }}</button>
            </div>
        </div>

        <!-- step3 -->

        <div v-if="currentStep === 'step3'" key="step3">
            <div class="content">
                <h4>{{ $t("Sending mails...") }}</h4>
            </div>
            <hr />

            <Progress :max="cityOfficials.length" :value="mailsSent" />

            <hr />
            <div class="content flex justify-end">
                <button class="button is-rounded is-danger no-disable" @click="cancelled = true">{{ $t("Cancel") }}</button>
            </div>
            
        </div>

        <!-- step 4 -->
        <div v-if="currentStep === 'step4'" key="step4">
            <div class="content">
                <h4>{{ $t("Log") }}</h4>
            </div>
            <hr />
            <MailsLog :logs="errorLogs" />
            <hr />
            <div class="content flex justify-end">
                <button class="button is-rounded" @click="closeModal">{{ $t("Close") }}</button>
            </div>
            
        </div>
    </slide-transition>
        
    </div>
</Modal>
</template>

<script>
import { SlideTransition } from '@/components/shared/transitions';
import Progress from '@/components/shared/Progress';
import CityOfficialsTable from './CityOfficialsTable';
import MailPreview from './MailPreview';
import MailsLog from './MailsLog';
import { sendMail, sendSampleMail } from '@/api/mails';
import BulkProcess from '@/utils/BulkProcess';

export default {
    components: { SlideTransition, CityOfficialsTable, Progress, MailPreview, MailsLog },

    props: ['transactional_email_data'],

    data () {
        return {
            currentStep: 'step1',
            sampleEmail: '',
            cityOfficials: [],

            isProcessing: false,
            mailsSent: 0,
            cancelled: false,
            sampleEmailMessage: this.$t('Send mail'),
            error: null,
            errorLogs: [],
        };
    },

    created () {
        this.sampleEmail = this.transactional_email_data.fromEmail;
    },

    computed: {
        groupName () {
            return this.$store.getters.selectedUserGroup;
        },
        locale () {
            return this.$i18n.locale;
        },
    },

    methods: {
        sendSampleMail () {
            this.sampleEmailMessage = this.$t('Sending...');
            this.error = null;
            const params = {
                email: this.sampleEmail,
                transactional_email_data: JSON.stringify(this.transactional_email_data),
                locale: this.locale,
            };

            sendSampleMail(params, this.groupName)
            .then(() => {
                this.sampleEmailMessage = this.$t('Message sent!');
                setTimeout(() => this.currentStep = 'step2-municipalities', 1200);
            })
            .catch( res => {
                this.sampleEmailMessage = this.$t('Send mail');
                this.error = res.body.reason_phrase;
            });
        },

        sendMail (user) {
            const params = {
                user_id: user.user_id,
                transactional_email_data: JSON.stringify(this.transactional_email_data),
                locale: this.locale,
            };
            return sendMail(params, this.groupName);
        },

        sendMailsInBulk () {
            this.currentStep = 'step3';
            this.isProcessing = true;

            BulkProcess({
                delay: 700,
                list: this.cityOfficials,
                action: this.sendMail,
                progress: (curr, i) =>this.mailsSent = i,
                error: (err, curr) => {
                    curr.error = err.body.reason_phrase;
                    this.errorLogs.push(curr);
                },
                complete: (i) => {
                    this.mailsSent = i;
                    this.isProcessing = false;
                    setTimeout(() => this.currentStep = 'step4', 500);
                },
                isCancelled: () => this.cancelled,
                cancel: () => {
                    this.isProcessing = false;
                    this.currentStep = 'step4';
                }, 
            });
        },

        closeModal() {
            if (!this.isProcessing) {
                this.$emit('closeModal');
            }
        },
    },
};
</script>
