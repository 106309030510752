<template>
  <a class="delete" @click="deleteCity"></a>
</template>

<script>
import { deleteCity } from '@/api/manage-cities';
import {
    GROUP_INFO,
    FETCH,
    RESET,
} from '@/constants';
export default {
    props:{
        value: String, //city
    },
    methods:{
        deleteCity(){
            if (confirm(this.$t('MANAGE_CITIES_PAGE.REMOVE_WARNING'))) {
                const { selectedUserGroup } = this.$store.getters;
                deleteCity(selectedUserGroup, this.value).then(() => {
                    //re fetch the group info
                    this.$store.commit(`${GROUP_INFO}/${RESET}`);
                    this.$store.dispatch(`${GROUP_INFO}/${FETCH}`);
                });
            } else {
                // Do nothing!
            }
        },
    },
};
</script>