import api from './index';

import {
    search_cities,
    invite_city,
    delete_city,
} from '@/config/endpoints';

export function searchCities (selectedUserGroup,keyword) {
    return api(selectedUserGroup)
        .get(search_cities+ '?city_name=' +keyword, { emulateJSON: true });
}

export function inviteCity( selectedUserGroup, name ) {
    return api(selectedUserGroup)
        .post(invite_city,{city_name: name}, { emulateJSON: true });
}

export function deleteCity( selectedUserGroup, name ) {
    return api(selectedUserGroup)
        .post(delete_city,{city_name: name}, { emulateJSON: true });
}