<template>
    <transition 
        name="slide"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter">
        <slot />
    </transition>
</template>

<script>
export default {
    data: () => ({
        prevHeight: 0,
    }),
    methods: {
        beforeLeave($el) { // current el
            this.prevHeight = getComputedStyle($el).height;
        },
        enter ($el) { // new el
            const toHeight = getComputedStyle($el).height;
            // set height of new el to height previous el
            $el.style.height = this.prevHeight;
            // set actual height again
            // done in timeout to trigger transition effect
            setTimeout(() => {
                $el.style.height = toHeight;   
            });
        },
        afterEnter ($el) {
            // make sure height is dynamic again
            $el.style.height = 'initial';
            this.$emit('afterEnter');
        },
    },
};
</script>  