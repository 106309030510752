import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {
  ActivityPage,
  ActionsPage,
  CallbackPage,
  CitiesPage,
  CityDetailPage,
  ClaimInvite,
  DashboardPage,
  LoginPage,
  ManageUsersPage,
  ManageCitiesPage,
  MapPage,
  MeasuresPage,
  MeasureDetailPage,
  RegisterPage,
  KnownMeasuresListPage,
  KnownMeasureFormPage,
  KnownMeasureDetailPage,
  KnownMeasureTranslationsPage,
  KnownActionsListPage,
  MailsPage,
  KnownActionFormPage,
  KnownActionDetailPage,
  KnownActionTranslationsPage,
} from '@/components/pages';

import { CALLBACK_PATH, LOGIN_PATH } from '@/config';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: DashboardPage,
      alias: '/dashboard',
    },
    {
      path: '/actions',
      component: ActionsPage,
    },
      {
      path: '/activity',
      component: ActivityPage,
    },
    {
      path: '/manage-users',
      component: ManageUsersPage,
    },
    {
      path: '/manage-cities',
      component: ManageCitiesPage,
    },
    {
      path: '/map',
      component: MapPage,
    },
    {
      path: '/measures',
      component: MeasuresPage,
    },
    {
      name: 'measureDetail',
      path: '/measures/:id',
      component: MeasureDetailPage,
    },
    {
      path: '/municipalities',
      component: CitiesPage,
    },
    {
      name: 'cityDetail',
      path: '/municipalities/:name',
      component: CityDetailPage,
    },
    {
      name: 'knownMeasuresList',
      path: '/own-plan/known-measures-list',
      component: KnownMeasuresListPage,
    },
    {
      name: 'knownMeasureDetail',
      path: '/own-plan/known-measure-detail/:id',
      component: KnownMeasureDetailPage,
      props: true,
    },
    {
      name: 'knownMeasureForm',
      path: '/own-plan/known-measure-form/:id?',
      component: KnownMeasureFormPage,
      props: true,
    },
    {
      name: 'knownMeasureTranslations',
      path: '/own-plan/known-measure-translations/:id',
      component: KnownMeasureTranslationsPage,
      props: true,
    },
    {
      name: 'knownActionsList',
      path: '/own-plan/known-actions-list',
      component: KnownActionsListPage,
    },
    {
      name: 'mails',
      path: '/own-plan/mails',
      component: MailsPage,
    },
    {
      name: 'knownActionForm',
      path: '/own-plan/known-action-form/:id?',
      component: KnownActionFormPage,
      props: true,
    },
    {
      name: 'knownActionDetail',
      path: '/own-plan/known-action-detail/:id',
      component: KnownActionDetailPage,
      props: true,
    },
    {
      name: 'knownActionTranslations',
      path: '/own-plan/known-action-translations/:id',
      component: KnownActionTranslationsPage,
      props: true,
    },
    {
      path: '/user/emailbasedlogin/:userGroup/:id/:secret',
      component: RegisterPage,
      meta: {
        requiresLoggedIn: false,
      },
    },
    {
      path: '/claim-invite',
      component: ClaimInvite,
      meta: {
        requiresLoggedIn: false,
      },
    },
    {
      path: CALLBACK_PATH,
      component: CallbackPage,
      meta: {
        requiresLoggedIn: false,
      },
    },
    {
      path: LOGIN_PATH,
      component: LoginPage,
      meta: {
        requiresLoggedIn: false,
      },
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach(
  ({ meta }, from, next) =>
    !store.getters.isAuthenticated && meta.requiresLoggedIn !== false
      ? next(LOGIN_PATH)
      : next(),
);

router.afterEach(() => {
  Vue.prototype.$intercom.update();
});

export default router;
