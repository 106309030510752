import { isNil } from 'ramda';

export default {
  beforeUpdate: chart => {
    const { data, id } = chart;
    const { datasets, labels } = data;
    const visibleDatasets = datasets.filter(
      ({ _meta }) => isNil(_meta) || _meta[id].hidden !== true,
    );
    const sums = visibleDatasets.reduce(
      (acc, { data }) => data.map((val, index) => val + (acc[index] || 0)),
      [],
    );

    // Sort sums and get index map created by the sort
    const SortedSumIndexMap = sums.map((val, ind) => {return {ind, val}})
      .sort((a, b) => {return a.val > b.val ? 1 : a.val == b.val ? 0 : -1 })
      .map((obj) => obj.ind);

    // function
    // @input array
    // @output permutated version of the array using SortedSumIndexMap as fixed permutation map.
    // permutateToSortedSumOrder(sums) would return sums in sorted order
    const permutateToSortedSumOrder = array =>  {
      var permutation = [];
      for(var i = 0; i < array.length; i++) {
        permutation.push(array[SortedSumIndexMap[i]]);
      }
      return permutation;
    };

    // sort labels by sum of dataset values
    chart.data.labels = permutateToSortedSumOrder(labels);

    // for each dataset sort the datasets by sum of datasetvalues
    datasets.forEach((dataset, index) => {
      chart.data.datasets[index].data = permutateToSortedSumOrder(dataset.data);
      // background colors are dependent on the data, so the should always keep the same order as the data and thus we need to apply the same permutation
      chart.data.datasets[index].backgroundColor = permutateToSortedSumOrder(dataset.backgroundColor);
    });
  },
};
