<template>
  <form @submit="handleSubmit">
    <h4 class="title is-4">{{ $t('manageCitiesPage.inviteCities') }}</h4>
    <h5 class="subtitle">{{ $t('manageCitiesPage.inviteCitiesSubtitle') }}</h5>

    <div class="field">
      <input 
        class="input"
        :class="{'is-success':isVallidCity, 'is-danger':!isVallidCity}" 
        type="text"
        v-model="citySearch" 
        autocomplete="off"
        autocorrect="off"
        :placeholder="$t('manageCitiesPage.INVITE_INPUT_PLACEHOLDER')" 
      />
    </div>
    <div class="list is-hoverable">
      <a 
        class="list-item"
        :class="{'is-active':equalsSearchedCity(city)}"
        v-for="city in cityList.slice(0,5)" 
        :key="city.id" 
        @click="handleSelectCity(city)"
      >
        {{city.name}}
      </a>
    </div>
    <div class="field flex justify-end">
      <button type="submit" class="button is-primary" :disabled="!isVallidCity">
        {{ $t('manageCitiesPage.invite') }}
      </button>
    </div>
  </form>
</template>

<script>
import { searchCities, inviteCity } from '@/api/manage-cities';
import {debounce} from '@/utils/debounce';

export default {
  data() {
    return {
      citySearch: '',
      cityList: [],
    };
  },

  computed:{
    isVallidCity(){
      return this.cityList.map(c => c.name.toLowerCase()).includes(this.citySearch.toLowerCase());
    },
  },

  methods: {

    equalsSearchedCity(city){
      return this.citySearch.toLowerCase() == city.name.toLowerCase();
    },

    handleSelectCity(city){
      this.citySearch = city.name;
    },

    handleSubmit(e) {
      e.preventDefault();

      const { selectedUserGroup } = this.$store.getters;

      inviteCity(selectedUserGroup, this.citySearch).then(
        res => this.$emit('invite',{name:this.citySearch, link:res.link})
      );
    },

    searchCity: debounce(function (keyword) {

      const { selectedUserGroup } = this.$store.getters;
      searchCities(selectedUserGroup,keyword).then(res => {
          this.cityList = res;
      });
    }, 200),
  },
  watch: {
    citySearch () {
      if(this.citySearch){
        this.searchCity(this.citySearch);
      }
    },
  },
};
</script>
