import groupInfo from './group-info';
import pages from './pages';
import actions from './actions';

import { SELECT_USER_GROUP } from '@/constants';

export default {
  state: {
    selectedUserGroup: localStorage.getItem('selectedUserGroup'),
  },

  mutations: {
    [SELECT_USER_GROUP]: (state, payload) =>
      (state.selectedUserGroup = payload),
  },

  getters: {
    selectedUserGroup: ({ selectedUserGroup }) => selectedUserGroup,
  },

  actions,

  modules: {
    groupInfo,
    pages,
  },
};
