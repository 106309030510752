import tinycolor from 'tinycolor2';

const lightenColor = function(color, percent = 20){
  return tinycolor(color).lighten(percent).toHexString();
};

export const BLUE = '#7AC5C0';
export const DARK_BLUE = '#0088A2';
export const DARK_GREEN = '#50a129';
export const GREEN = '#D6D904';
export const LIGHT_ORANGE = '#fab502';
export const ORANGE = '#e85513';
export const RED = '#950c25';
export const MAIN_APP_COLOR = '#FAB500';
export const MAIN_APP_COLOR_LIGHT = lightenColor(MAIN_APP_COLOR, 35);

export const fermobColors = [
  '#E8540E',
  '#FAB301',
  '#D6D904',
  '#849176',
  '#DD306F',
  '#593867',
  '#46494E',
  '#7AC5C0',
  '#0088A2',
  '#5F3A31',
  '#3B5B46',
  '#ECDED1',
];

export const fermobColorsLight = fermobColors.map(c => lightenColor(c));

export const greenScale = [
  'rgb(248, 248, 217)',
  'rgb(232, 232, 139)',
  'rgb(214, 216, 10)',
];

export const brownScale = [
  'rgb(232, 226, 222)',
  'rgb(208, 197, 189)',
  'rgb(179, 161, 146)',
];

export const greenToRed = [DARK_GREEN, GREEN, BLUE, LIGHT_ORANGE, ORANGE, RED];

export const noMatchColor = 'rgb(200, 200, 200)';
