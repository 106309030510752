<template>
  <div class="App">
    <FutureHeader />

    <router-view />
  </div>
</template>

<script>
import FutureHeader from '@/components/header/Header';

const FP_NAME = 'FutureproofedCities';

export default {
  name: 'App',

  components: { FutureHeader },

  mounted() {
    this.$intercom.boot();
  },

  metaInfo() {
    const groupName = this.$store.getters['groupInfo/name'];

    return {
      title: groupName ? `${groupName} | ${FP_NAME}` : FP_NAME,
    };
  },
};
</script>

<style lang="scss">
@import '../scss/style';
</style>

