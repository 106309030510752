var render = function render(_c,_vm){return _c('div',{staticClass:"has-bg-image",style:({
    width: '55px',
    height: '55px',
    borderRadius: '50%',
    backgroundImage: `url(${_vm.props.value})`,
    backgroundColor: 'whitesmoke'
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }