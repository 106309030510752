<template>
  <form @submit="handleSubmit">
    <h4 class="title is-4">{{ $t('manageUsersPage.inviteUsers') }}</h4>
    <h5 class="subtitle">{{ $t('manageUsersPage.inviteUsersSubtitle') }}</h5>

    <Error v-if="error" :error="error" />

    <div class="notification is-primary" v-if="isSuccess">
      {{ $t('manageUsersPage.successMessage', { emails: emails.join(', ') }) }}
    </div>

    <div v-if="!error && !isSuccess">
      <Loading v-if="isLoading" />

      <div class="field" v-for="(email, index) in emails" :key="index">
        <div :class="`control ${emails.length > 1 ? 'has-icons-right' : ''}`">
          <input
            type="email"
            class="input"
            :placeholder="$t('shared.emailPlaceholder')"
            v-model="emails[index]"
            required />

            <span
              v-if="emails.length > 1"
              class="icon is-small is-right close-icon"
              @click="removeInputField(index)" />
        </div>
      </div>

      <div class="field flex justify-end">
        <button class="button mr-4" @click="addInputField">
          <i class="icon add-icon" />
          <span>{{ $t('manageUsersPage.addInputField') }}</span>
        </button>

        <button type="submit" class="button is-primary">
          {{ $t('manageUsersPage.invite') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import api from '@/api';

import { inviteUsers as endpoint } from '@/config/endpoints';

export default {
  data() {
    return {
      emails: [''],
      error: null,
      isLoading: false,
      isSuccess: false,
    };
  },

  methods: {
    addInputField(e) {
      e.preventDefault();

      this.emails.push('');
    },

    handleSubmit(e) {
      e.preventDefault();

      this.isLoading = true;

      const emails = this.emails.join();

      const locale = this.locale;

      const { selectedUserGroup } = this.$store.getters;

      api(selectedUserGroup)
        .post(endpoint, { emails, locale }, { emulateJSON: true })
        .then(() => {
          this.isLoading = false;
          this.isSuccess = true;
        })
        .catch(({ body: { reason_phrase } }) => {
          this.isLoading = false;
          this.error = reason_phrase;
        });
    },

    removeInputField(index) {
      this.emails.splice(index, 1);
    },
  },

  computed: {
    locale () {
      return this.$i18n.locale;
    },
  },
};
</script>
