<template>
  <FetchContainer class="box h-full" :entities="entities">
    <CarbonSummed :from-year="fromYear" :to-year="toYear" />
  </FetchContainer>
</template>

<script>
import CarbonSummed from './CarbonSummed';

import { CARBON_SUMMED } from '@/constants';

export default {
  components: { CarbonSummed },

  data() {
    return {
      entities: [CARBON_SUMMED],
    };
  },

  props: {
    fromYear: {
      required: true,
    },
    toYear: {
      required: true,
    },
  },
};
</script>
