<template>
  <div class="h-full relative">
    <div  :style="{height : '35px', width : '150px'}">
      <canvas ref="chartCanvas"/>
    </div>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';
import { range } from 'ramda';
 
export default {
    props: [ 'value'],
    components: { Chart },
    name: 'RowChart',

    computed:  {
        dataArray() { return JSON.parse(this.value); },
    },

    mounted() {

        const ctx = this.$refs.chartCanvas;
        ctx.height = '35px';

        this.chart = new Chart(ctx,{
            type : 'bar',
            options : {
                plugins: {
                    legend : { display: false },
                    tooltip: { enabled : false },
                },

                scales : {
                    x : {
                        ticks : {  display: false },
                        grid : { display : false },
                        
                    },
                    y : {
                        ticks : {  display: false },

                        grid : { 
                            display : false,
                            drawBorder: false,
                        },
                    },
                },
            },

            data : {
                labels : range(1,this.dataArray.length),
                datasets  : [
                    {  data : this.dataArray,
                       backgroundColor: '#e1a300',
                    },
                ],
            },
        });
    },
};

</script>
