import { prop } from 'ramda';

import {
    ActionImage,
    AvailableStatus,
} from '@/components/shared/table-cells';

export default function getConfig($t) {
    return [
        {
            key: 'image',
            label: '',
            cellDataGetter: ({ photo_thumb }) => photo_thumb
            ? `https://ddr90r1arm2wz.cloudfront.net/1/known_actions/${photo_thumb}`
            : 'https://app.futureproofed.com/images/samples/thumbs/action-sample.jpg?v=2',
            cellRenderer: ActionImage,
            width: '50px',
        },
        {
            key: 'name',
            label: $t('shared.name'),
            cellDataGetter: action => action.name,
        },
        {
            key: 'sector',
            label: $t('sector'),
            cellDataGetter: a => $t(a.related_mitigation_sector) || '',
        },
        {
            key: 'adaptation_sector',
            label: $t('adaptation_sector'),
            cellDataGetter:  a => $t(a.related_adaptation_sector) || '',
        },
        {
            key: 'available',
            label: $t('Published'),
            cellDataGetter: prop('available'),
            cellRenderer: AvailableStatus,
            width: '40px',
        },
    ];
}