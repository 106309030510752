<template>
    <header class="header">
        <div class="flex justify-between items-center pr-4" :style="{ flex: '0 0 60px' }">
            <div class="flex">
                <div class="flex items-center justify-center bg-primary" :style="{ width: '60px', height: '60px' }">         
                    <!-- First letter of name or...-->
                    <span class="is-size-4 font-bold text-black hide-on-mobile">
                        {{ firstLetterOfName }}
                    </span>

                    <!-- ...mobile menu -->
                    <Dropdown class="is-left" v-if="isAuthenticated" :style="{ height: '24px' }">
                        <span slot="trigger" class="is-mobile icon menu-icon" />
                        
                        <template v-for="navItem in navItems">
                            <a class="dropdown-item" :key="navItem.id" @click="setActiveNav(navItem)">
                                <span>{{ $t(navItem.label) }}</span>
                            </a>

                            <router-link v-for="{ to, label } in navItem.subnav" :key="to"
                                class="dropdown-item" 
                                :to="to">
                                <span class="pl-4">{{ $t( label) }}</span>
                            </router-link>
                        </template>
                    </Dropdown>
                </div>

                <!-- futureproofed logo + group name -->
                <div class="flex flex-col justify-center pl-4 pr-4">
                    <div class="is-size-6">Futureproofed •••</div>

                    <div>{{ name || '...' }}</div>
                </div>

                <!-- navigation links desktop -->
                <ul class="flex nav" v-if="isAuthenticated">
                    <li v-for="navItem in navItems" :key="navItem.id"
                        class="flex flex-col justify-center" >
                        <a class="flex flex-grow items-center pl-4 pr-4"
                            :class="{ 'is-active': activeNav === navItem.id }"
                            @click="setActiveNav(navItem)">
                            <span>{{ $t(navItem.label) }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <AccountDropdown @changeRegion="showPopup"/>
            <RegionSwitcherPopup v-if="show_popup" @closeModal="closePopup" />

        </div>
        <div class="flex subheader" :style="{ flex: '0 0 45px'}">
            <ul class="flex nav" v-if="isAuthenticated">
                <li class="flex flex-col justify-center" v-for="{ to, label } in computedSubnav" :key="to">
                    <router-link :to="to" class="flex flex-grow items-center pl-4 pr-4">
                        <span>{{ $t(label) }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import AccountDropdown from './AccountDropdown';
import RegionSwitcherPopup from './RegionSwitcherPopup';

import { navItems } from '@/config';
import { GROUP_INFO } from '@/constants';

export default {
    components: { AccountDropdown, RegionSwitcherPopup },

    data: () => ({
        navItems,
        activeNav: '',
        show_popup: false,
    }),

    created () {
        let found = this.navItems[0];
        for (let i = 0; i < this.navItems.length; i++) {
            const subCategory = this.navItems[i].subCategory;
            if (subCategory && this.$route.path.indexOf(subCategory) > -1) {
                found = this.navItems[i];
            }
        }

        this.activeNav = found.id;
    },

    computed: {
        name() {
            return this.$store.getters[`${GROUP_INFO}/name`];
        },

        firstLetterOfName() {
            return this.name ? this.name.slice(0, 1) : null;
        },

        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },

        computedSubnav() {
            const found = this.navItems.find(item => item.id === this.activeNav);
            return found.subnav;
        },
    },

    methods: {
        setActiveNav (nav) {
            this.activeNav = nav.id;
            this.$router.push(nav.subnav[0].to);
        },

        showPopup() {
            this.show_popup = true;
        },

        closePopup() {
            this.show_popup = false;
        },
    },
};
</script>