import { prop } from 'ramda';

import {
  Fraction,
  Image,
  MeasureName,
  NumberWithWarningWhenZero,
  Progress,
} from '@/components/shared/table-cells';

import { getMeasureName } from '@/utils/MeasureUtils';

export default ($t, locale) => [
  {
    key: 'image',
    label: '',
    cellDataGetter: (measure) => {
      if (measure.photo_thumb) {
        return measure.photo_thumb;
      } else if (measure.known_measure_photo_thumb) {
        return measure.known_measure_photo_thumb;
      } else {
        return `https://app.futureproofed.com/images/measures/thumbs/${measure.known_measure_id}.jpg?v=2`;
      }
    },
    cellRenderer: Image,
  },
  {
    key: 'name',
    label: $t('shared.name'),
    cellDataGetter: (ms) => ({
      name: getMeasureName(ms, locale),
      sector_name: $t(ms.sector_name),
    }),
    cellRenderer: MeasureName,
    sortDataGetter: prop('name'),
  },
  {
    key: 'numberOfCities',
    label: $t('shared.cities'),
    subLabel: $t('measuresPage.citiesSubLabel'),
    cellDataGetter: prop('count_cities'),
  },
  {
    key: 'progress',
    label: $t('shared.progress'),
    subLabel: $t('shared.progressSubLabel'),
    cellDataGetter: ({ progress, target }) =>
      target ? (progress / target) * 100 : 0,
    cellRenderer: Progress,
  },
  {
    key: 'reductionPerPerson',
    label: $t('shared.reduction'),
    subLabel: $t('measuresPage.reductionPerPersonSubLabel'),
    cellDataGetter: ({ population, total_carbon_reduction }) =>
      total_carbon_reduction / population,
    cellRenderer: Fraction,
  },
  {
    key: 'numberOfActions',
    label: $t('shared.actions'),
    subLabel: $t('shared.actionsSubLabel'),
    cellDataGetter: prop('related_actions_count'),
    cellRenderer: NumberWithWarningWhenZero,
  },
];
