<template>
  <div class="flex box" :style="{color: 'red'}">
    {{ message }}
  </div>
</template>

<script>
import { isProduction } from '@/utils';

export default {
  props: ['error'],

  computed: {
    message() {
      if (typeof this.error === 'string') {
        return this.error;
      }

      if (
        typeof this.error === 'object' &&
        (this.error.message || this.error.description)
      ) {
        return this.error.message || this.error.description;
      }

      return this.$t('errorMessages.default');
    },
  },

  created() {
    if (!isProduction) {
      console.error(this.error); // eslint-disable-line
    }
  },
};
</script>

