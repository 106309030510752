<template>
  <table :class="`table is-fullwidth is-hoverable ${onRowClick ? 'is-clickable' : ''}`">
    <thead v-if="showHeader !== undefined ? showHeader : true">
      <tr>
        <th v-if="selection" style="width: 40px;">
          <Multiselect :rows="data" :selection="selection" />
        </th>
        <th
          v-for="({ label, key, subLabel, title }) in config"
          :key="key"
          :title="title || label">
          <div class="inline-flex items-center cursor-pointer text-sm" @click="updateSort(key)">
            <span class="uppercase">{{ label }}</span>

            <svg width="18" height="18" viewBox="0 0 24 24">
              <path :d="sortDirection === 'DESC' ? 'M7 14l5-5 5 5z' : 'M7 10l5 5 5-5z'" v-if="sortByKey === key" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>

          <div class="text-sm has-text-grey-light font-normal" v-if="subLabel" :style="{ whiteSpace: 'normal' }">
            {{ subLabel }}
          </div>
        </th>
      </tr>
    </thead>

    <tbody v-if="sortedData.length > 0">
      <tr 
        v-for="row in sortedData"
        :key="row[rowId || 'id']"
        @click="handleOnClick(row)"
        :class="isRowClickable(row) ? 'clickable' : ''">
        <td v-if="selection" style="width: 40px; vertical-align: middle;">
          <Multiselect :row="row" :selection="selection" :rowId="rowId" />
        </td>
        <td v-for="{ key, cellDataGetter = () => null, cellRenderer = 'div', width = 'auto' } in config" :key="key" :style="{ width }">
          <component
            :is="cellRenderer"
            v-bind="typeof cellDataGetter(row) === 'object' ? cellDataGetter(row) : { value: cellDataGetter(row) }">
            {{ cellDataGetter(row) }}
          </component>
        </td>
      </tr>
    </tbody>

    <tbody v-else>
      <tr>
        <td :colspan="selection ? config.length + 1: config.length">
          <slot name="empty-table">
            <h2 class="table--no-content">{{ $t("No results") }}</h2>
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { compose, contains, prop, reverse, sortBy, toLower } from 'ramda';

import { findInArrayByPropEnforcing } from '@/utils';
import Multiselect from './Multiselect';

export default {
  components: { Multiselect },
  props: [
    'config',
    'data',
    'filter',
    'filterBy',
    'isRowClickable',
    'onRowClick',
    'rowId',
    'sortByKey',
    'sortDirection',
    'updateSort',
    'selection',
    'showHeader',
  ],

  computed: {
    filteredData() { 
      const isHit = compose(
        contains(toLower(this.filter)),
        toLower,
        prop(this.filterBy),
      );

      return this.data.filter(isHit);
    },

    sortedData() {
      const { cellDataGetter, sortDataGetter } = findInArrayByPropEnforcing(
        this.config,
        'key',
        this.sortByKey,
      );

      const sortedData = sortBy(
        sortDataGetter || cellDataGetter,
        this.filteredData,
      );

      return this.sortDirection === 'DESC' ? reverse(sortedData) : sortedData;
    },
  },

  methods: {
    selectRow(row) {
      const pos = this.selection.indexOf(row);
      if (pos > -1) {
        this.selection.splice(pos, 1);
      } else {
        this.selection.push(row);
      }
    },
    
    handleOnClick(row) {
      if (this.isRowClickable && this.onRowClick) {
        if (this.selection && this.selection.length > 0) {
          this.selectRow(row);
        } else {
          this.onRowClick(row);
        }
      } else if (this.selection) {
        this.selectRow(row);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table.is-clickable tbody tr.clickable {
  cursor: pointer;
}

th {
  white-space: nowrap;
}
</style>


