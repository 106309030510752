import { isNil, not } from 'ramda';

export const filters = [
  {
    name: 'name',
    predicate: value => ({ name }) =>
      name.toLowerCase().indexOf(value.toLowerCase()) > -1,
    initialValue: '',
  },
  {
    name: 'type',
    predicate: value => ({ type }) => isNil(value) || type === value,
    initialValue: null,
  },
  {
    name: 'sector',
    predicate: value => ({ campaign_name }) =>
      isNil(value) || campaign_name === value,
    initialValue: null,
  },
  {
    name: 'city',
    predicate: value => ({ city_name }) =>
      isNil(value) || city_name === value,
    initialValue: null,
  },
  {
    name: 'actionable',
    predicate: value => ({ actionable }) =>
      not(value) || !!actionable === value,
    initialValue: false,
  },
  {
    name: 'onlyPublic',
    predicate: value => ({ privacy }) =>
      value === true ? privacy === 'public' : true,
    initialValue: false,
  },
  {
    name: 'onlyPrivate',
    predicate: value => ({ privacy }) =>
      value === true ? privacy === 'private' : true,
    initialValue: false,
  },
];
