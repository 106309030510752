<template>
  <div>
    <div class="field flex justify-between flex-wrap">
      <h3 class="title">{{ title }}</h3>

      <div class="flex">
        <slot name="button" />
        
        <SearchField @change="filter = $event" />
      </div>
    </div>

    <div class="box relative" :style="{ overflowX: 'auto' }">
      <Loading v-if="isLoading" />

      <Table
        v-else
        :data="data || []"
        :config="config"
        :filter="filter"
        :filterBy="filterBy || 'name'"
        :isRowClickable="isRowClickable || (() => true)"
        :onRowClick="onRowClick"
        :sortByKey="computedSortState.sortByKey"
        :sortDirection="computedSortState.sortDirection"
        :updateSort="updateSort || ownUpdateSort" />
    </div>
  </div>
</template>

<script>
import { compose, head, prop } from 'ramda';
import Loading from '@/components/shared/Loading';
import SearchField from '@/components/shared/SearchField';
import Table from '@/components/shared/Table';
import { reverseDirection } from '@/utils';
export default {
  components: { Loading, SearchField, Table },
  props: [
    'config',
    'data',
    'filterBy',
    'isLoading',
    'isRowClickable',
    'onRowClick',
    'sortState',
    'title',
    'updateSort',
  ],
  data() {
    return {
      filter: '',
      ownSortState: {
        sortByKey: compose(prop('key'), head)(this.config),
        sortDirection: 'ASC',
      },
    };
  },
  computed: {
    computedSortState() {
      return this.sortState || this.ownSortState;
    },
  },
  methods: {
    ownUpdateSort(newSortByKey) {
      const { sortByKey, sortDirection } = this.ownSortState;
      this.ownSortState.sortDirection =
        newSortByKey === sortByKey
          ? reverseDirection(sortDirection)
          : sortDirection;
      this.ownSortState.sortByKey = newSortByKey;
    },
  },
};
</script>
