<template>
  <ChartContainer>
    <template slot="chart">
      <Chart
        :data="chartData"
        :hasSettings="true"
        :options="options"
        :plugins="plugins"
        :perPerson="showDataPerPerson"
        type="bar" />
    </template>

    <ModalContent
      slot="modal-content"
      :cities="cities"
      :citiesToHide="citiesToHide"
      :perPerson="showDataPerPerson"
      :title="title"
      @newCitiesToHide="citiesToHide = $event"
      @togglePerPerson="showDataPerPerson = $event" />
  </ChartContainer>
</template>

<script>
import Chart from '@/components/pages/Dashboard/charts/Chart';
import ChartContainer from '@/components/pages/Dashboard/charts/ChartContainer';
import ModalContent from '@/components/pages/Dashboard/charts/ModalContent';

import {
  getEmissionsByYearBySource,
  getEmissionsByYearByCity,
  populationByYear,
  hasPastDataInYear,
} from '@/utils/v2/city';

import { parseNumber, stringifyNumber } from '@/utils';
import sortStackedBarChart from '@/utils/sort-stacked-bar-chart';
import { colorBySource, colorBySourceLight, sources } from '@/config';
import { CITIES } from '@/constants';

import legendOptions from '@/components/pages/Dashboard/charts/legend-options';

export default {
  data() {
    return {
      showDataPerPerson: false,
      citiesToHide: [],
    };
  },

  props: { 
    selectedYear: {
      required: true,
    }, 
  },

  components: { Chart, ChartContainer, ModalContent },

  methods:{
    collorByCityBySource(source,city){

      if(hasPastDataInYear(this.selectedYear, city)){
        return colorBySource[source];
      } else {
        return colorBySourceLight[source];
      }
    },

    sumEmmissionInventory(obj){
      return Object.values(obj).reduce((a = 0, b = 0) => a + b);
    },
  },

  computed: {
    cities() {
      return this.$store.getters[`${CITIES}/cities`];
    },

    citiesToDisplay() {
      return this.cities.filter(
        ({ name }) => this.citiesToHide.indexOf(name) === -1,
      );
    },

    data() {
      const getDataForCity = source => city => {
        const emissions = getEmissionsByYearBySource(this.selectedYear, city, source);
        const population = populationByYear(this.selectedYear, city);

        return this.showDataPerPerson ? emissions / population : emissions;
     };

      return this.citiesToDisplay.map(city => ({
        label: city.name,
        data: sources.reduce(
          (acc, source) => ({
            ...acc,
            [source]: getDataForCity(source)(city),
          }),
          {},
        ),
        // creates a mapping that maps each source to its background color
        backgroundColor: sources.reduce(
          (acc, source) => ({
            ...acc,
            [source]: this.collorByCityBySource(source,city),
          }),
          {},
        ),
      }));
    },

    labels() {
      return this.data.map(prop => prop['label']);
    },

    datasets() {
      return sources.map(source => {
        return {
          borderWidth: 0,
          data: this.data.map(prop => prop.data[source]),
          label: this.$t(source),
          backgroundColor: this.data.map(prop => prop.backgroundColor[source]),
        };
      });
    },

    chartData () {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },

    title() {
      return this.$t('titleEmissionsBySourceByCity', {
        lastAvailableYear: this.selectedYear,
      });
    },

    plugins() {
      return [sortStackedBarChart];
    },

    options() {
      return {
        ...legendOptions,
        title: {
          display: true,
          text: this.title,
        },

        tooltips: {
          bodySpacing: 8,
          callbacks: {
            beforeBody: ([{ xLabel }]) => {
              const city = this.cities.filter(city => city.name === xLabel)[0];
              
              const totalEmissions = getEmissionsByYearByCity(this.selectedYear, city);

              const validated = hasPastDataInYear(this.selectedYear, city);

              return [
                this.$t(validated?'Verified':'Predicted'),
                `${this.$t('shared.total')}: ${this.$t('tons', {
                  number: parseNumber(totalEmissions)(this.$i18n.locale),
                })}`,
              ];
            },

            label: ({ datasetIndex, yLabel }, { datasets }) => {
              const { label } = datasets[datasetIndex];

              return [
                `${label}: ${this.$t('tons', {
                  number: parseNumber(yLabel)(this.$i18n.locale),
                })}`,
              ];
            },
          },
        },

        scales: {
          x: {
            stacked: true,
            ticks: {
              autoSkip: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
            callback: value =>
              this.$t('tons', {
                number: stringifyNumber(value, this.$i18n.locale),
              }),
            },
          },
        },
      };
    },
  },
};
</script>
