/**
 * Processes a `list` of `action`s in series
 * @param  {Array}      list     Array of elements to loop through
 * @param  {Function}   action   Function to execute, should return a Promise
 *
 * Optional:
 * @param  {Number}     index    The index we will start from. Default 0
 * @param  {[Number]}   delay    Amount of ms to wait between each action. Default 1000
 * @param  {[Function]} progress Sends current item and index before action starts
 * @param  {[Function]} error    Logs an error and the current item
 * @param  {[Function]} complete Executed at the end of the loop
 * @param  {[Function]} isCancelled Request whether execution should be stopped. must return Boolean value
 * @param  {[Function]} cancel   Executed when cancelling execution
 */
export default function bulkProcess ({ list, index, delay, progress, action, error, complete, isCancelled = () => false, cancel }) {
    if (!delay) delay = 1000;
    if (!index) index = 0;
  
    function doNext () {
        const current = list[index];
            if (current === undefined) {
                if (complete) complete();
                return;
            }
    
        if (progress) progress(current, index);
        action(current)
            .then(() => {
                goNext();
            })
            .catch((err) => {
                if (error) error(err, current);
                goNext();
            });
    }
  
    function goNext () {
        index++;
        if (index >= list.length) {
            if (complete) complete(index);
            return;
        }

        if (isCancelled()) {
            if (cancel) cancel();
            return;
        }
    
        window.setTimeout(() => {
            doNext();
        }, delay);
    }
    // start!
    doNext();
}