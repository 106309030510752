<template>
  <transition name="modal-transition">
    <div class="modal is-active">
      <div class="modal-background" @click.self="handleWindowClick" />
      
      
      <div class="modal-content" ref="container">
        <slot />
      </div>

      <button class="modal-close is-large" aria-label="close" @click="$emit('closeModal')" />
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    handleWindowClick(e) {
      const { container } = this.$refs;
      const { top, left, bottom, right } = container.getBoundingClientRect();
      const { clientX, clientY } = e;

      if (
        left < clientX &&
        clientX < right &&
        top < clientY &&
        clientY < bottom
      ) {
        return;
      }

      this.$emit('closeModal');
    },
  },
};
</script>


<style lang="scss">
.modal {
  transition: opacity 0.3s ease;
}

.modal-content {
  transition: all 0.3s ease;
}

.modal-transition-enter {
  opacity: 0;
}

.modal-transition-leave-active {
  opacity: 0;
}

.modal-transition-enter .modal-content,
.modal-transition-leave-active .modal-content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>


