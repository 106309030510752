<template>
  <div>
    <TablePageContainer
      :data="users"
      :config="config"
      filterBy="email"
      :title="$t('manageUsers')">
      <template slot="button">
        <button class="button is-primary mr-4" @click="showModal = true">
          <i class="icon add-icon-white" />
          <span>{{ $t('manageUsersPage.inviteUsers') }}</span>
        </button>
      </template>
    </TablePageContainer>

    <Modal v-if="showModal" @closeModal="showModal = false">
      <div class="box">
        <InviteUsers />
      </div>
    </Modal>
  </div>
</template>

<script>
import InviteUsers from './InviteUsers';
import TablePageContainer from '@/components/shared/TablePageContainer';

import getConfig from '@/config/users';

export default {
  components: { InviteUsers, TablePageContainer },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    config() {
      return getConfig(this.$t);
    },

    users() {
      return this.$store.getters['users/value'];
    },
  },
};
</script>
