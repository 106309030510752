<template>
  <div class="h-full relative" :style="{ minHeight: '230px', minWidth: '180px'}">
    <a
      v-if="$slots['modal-content']"
      class="has-icon absolute"
      :style="{ top: 0, right: 0 }"
      @click="showModal = true">
      <i class="icon settings-icon" />
    </a>

    <slot name="chart" />

    <Modal v-if="showModal" @closeModal="showModal = false">
      <div class="box">
        <slot name="modal-content" />
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

