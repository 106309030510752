import { prop } from 'ramda';
import api from './index';

import {
    add_translation,
    fetch_translations,
    delete_translation,
} from '@/config/endpoints';

export function addTranslation (params, userGroup) {
    return api(userGroup)
        .post(add_translation, params, { emulateJSON: true });
}

export function fetchTranslations (params, userGroup) {
    const endpoint = fetch_translations(params);
    const parseApiResponse = prop('translations');
    return api(userGroup)
        .get(endpoint)
        .then(parseApiResponse);
}

export function fetchMeasureTranslations (object_id, userGroup) {
    const params = {
        object_id,
        object_type: 'known_measure',
    };
    return fetchTranslations(params, userGroup);
}

export function deleteTranslation (params, userGroup) {
    return api(userGroup)
        .post(delete_translation, params, { emulateJSON: true });
}