import actions from './fp-actions';
import carbonSummed from './carbon-summed';
import cities from './cities';
import geoJson from './geo-json';
import measures from './measures';
import userGroups from './user-groups';
import users from './users';

export default {
  modules: {
    actions,
    carbonSummed,
    cities,
    geoJson,
    measures,
    userGroups,
    users,
  },
};
