<template>
    <div>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_indicator_name")}}</label>
            <input type="text" class="input mb-4" :value="value.name" @input="update('name', $event.target.value)"/>
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_indicator_value")}}</label>
            <input type="text"  class="input mb-4" :value="value.value" @input="update('value', $event.target.value)"/>
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_indicator_title")}}</label>
            <input type="text"  class="input mb-4" :value="value.title" @input="update('title', $event.target.value)"/>
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_indicator_description")}}</label>
            <textarea  class="textarea mb-4" :value="value.description" @input="update('description', $event.target.value)"/>
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_indicator_source_name")}}</label>
            <input type="text"  class="input mb-4" :value="value.source_name" @input="update('source_name', $event.target.value)">
        </Field>
        <Field>
            <label class="label" slot="label">{{$t("cobenefits_indicator_source_link")}}</label>
            <input type="text"  class="input" :value="value.source_link" @input="update('source_link', $event.target.value)">
        </Field>
    </div>
</template>
<script>
    export default {
        props: {
            value: {
                type: Object,
                default: () => ({}),
            },
        },
        watch:{
            is_valid(){
                this.$emit('invalidate', this.is_valid);
            },
        },
        computed:{
            is_empty(){
                return !this.value.name && !this.value.value && !this.value.description && !this.value.source_name && !this.value.source_link && !this.value.title;
            },
            is_valid(){
                return (this.value.name && this.value.value && this.value.description && this.value.source_name && this.value.source_link && this.value.title) ||
                    this.is_empty;
            },
        },
        methods: {
            update(key, value) {
                this.$emit('input', { ...this.value, [key]: value });
            },
        },
    };
</script>