<template>
  <div :style="{ minHeight: '230px', minWidth: '180px'}">
    <canvas ref="chart" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { compose, last, map, prop, reverse, sortBy, toPairs } from 'ramda';

import { colorBySource } from '@/config';
import { fractionToPercentage, parseNumber, stringifyNumber } from '@/utils';
import { getMergedDataFromYearGetterFunction } from '@/utils/city';

export default {
  props: ['city', 'lastAvailableYear', 'locale', '$t'],

  mounted() {
    setTimeout(this.initChart, 0);
  },

  methods: {
    initChart() {
      const ctx = this.$refs.chart;

      if (ctx == null) {
        return;
      }

      // eslint-disable-next-line no-unused-vars
      const { totals, year, ...data } = getMergedDataFromYearGetterFunction(
        this.lastAvailableYear,
      )(this.city);

      const dataPairToDataSet = ([label, value]) => ({
        backgroundColor: colorBySource[label],
        borderWidth: 0, // todo: move to global settings
        data: [value],
        label: `${this.$t(label)}: ${fractionToPercentage(
          value / totals.tonnes_co2_emitted,
        )}`,
      });

      const datasets = compose(
        map(dataPairToDataSet),
        reverse,
        sortBy(last),
        toPairs,
        map(prop('tonnes_co2_emitted')),
      )(data);

      const title = this.$t('titleCityTotalEmissionsBySource', {
        city: this.city.name,
        year: this.lastAvailableYear,
      });

      this.chart = new Chart(ctx, {
        type: 'bar',

        data: {
          datasets,
        },

        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: title,
          },

          tooltips: {
            callbacks: {
              label: ({ datasetIndex, yLabel }, { datasets }) => {
                const { label } = datasets[datasetIndex];

                return `${label}: ${this.$t('tons', {
                  number: parseNumber(yLabel)(this.locale),
                })}`;
              },
            },
          },

          scales: {
            x: {
                display: false,
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                  callback: value =>
                    this.$t('tons', {
                      number: stringifyNumber(value, this.locale),
                    }),
                },
            },
          },

          legend: {
            position: 'right',
            labels: {
              boxWidth: 12,
            },
            reverse: true,
          },
        },
      });
    },
  },
};
</script>

