import { pick } from 'ramda';
import { UPDATE_SORT } from '@/constants';
import { reverseDirection } from '@/utils';

export default {
  namespaced: true,

  state: {
    sortDirection: 'ASC',
    sortByKey: 'name',
  },

  getters: {
    sortState: pick(['sortByKey', 'sortDirection']),
  },

  mutations: {
    [UPDATE_SORT](state, payload) {
      state.sortDirection =
        payload === state.sortByKey
          ? reverseDirection(state.sortDirection)
          : state.sortDirection;
      state.sortByKey = payload;
    },
  },
};
