<template>
<div class="card">
    <Loading v-if="isLoading" />
    <template v-else>
        <div class="card-image">
            <figure class="image is-5by3" :style="{ backgroundImage: `url(${src})` }">
                <slot name="image-content" />
            </figure>
        </div>
        <div class="card-content">
            <div class="content">
                <slot />
            </div>
        </div>
    </template>
</div>
</template>

<script>
export default {
    props: ['isLoading', 'src'],
};
</script>
