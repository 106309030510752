<template>
    <div>
    <Tooltip class="icon" :tooltip="$t(value ? 'In Sia': 'Not in Sia')">
        <i class="status-is-available fas fa-link" v-if="value"/>
        <i class="status-not-available fas fa-unlink" v-else/>
    </Tooltip>
    </div>
</template>

<script>
export default {
    props: ['value'],
};
</script>
